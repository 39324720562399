import React, {useEffect, useState} from "react";
import Carousel from "./Carousel";
import Tune from "./Tune";
import Cards from "../components/Cards";
import about from '../images/about-us.png';
import why from '../images/why-us.png';
import dummyclient from '../images/dummyclient.png';
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams, useNavigate } from 'react-router-dom';
import Overlay from "../components/Overlay";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";

import RazorpayBadge from "../components/RazorpayBadge";

const Home = () => {
  const pagination = {
    clickable: true,
  };
  const [sliderdata, setsliderdata] = useState([]);
  const [homepagesliderdata, sethomepagesliderdata] = useState([]);
  const [testimonialdata, settestimonialdata] = useState([]);
  const [blogsdata, setblogsdata] = useState([]);
  const [showloding, setshowloding] = useState(false);

  const navigate = useNavigate();

  const fetchdashboarddata = async() => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
          .get(apiUrl + "getdashboarddata", headers)
          .then((res) => {
            // console.log('getdashboarddata res >>', res.data)
            if(res?.data?.status == 'success') {
              if(res?.data?.sliderdata?.length > 0) {
                setsliderdata(res?.data?.sliderdata);
                sethomepagesliderdata(res?.data?.homepageproductsdata);
              } else {
                setsliderdata([]);
              }
              // homepage slider data
              if(res?.data?.homepageproductsdata?.length > 0) {
                sethomepagesliderdata(res?.data?.homepageproductsdata);
              } else {
                sethomepagesliderdata([]);
              }

              //testimonials
              if(res?.data?.testimonialdata?.length > 0) {
                settestimonialdata(res?.data?.testimonialdata);
              } else {
                settestimonialdata([]);
              }

              //blogs
              if(res?.data?.blogsdata?.length > 0) {
                setblogsdata(res?.data?.blogsdata);
              } else {
                setblogsdata([]);
              }
            } else {
              setsliderdata([]);
              sethomepagesliderdata([]);
              settestimonialdata([]);
            }
            
          })
          .catch((err) => {
            alert(err.message);
          });
    setshowloding(false);
    
  }

  const handlenavigation = (data) => {
    console.log('handlenavigation data >>', data)
    // navigate('/productdetails', {state: {data: data}});
    navigate('/entertainment/'+data?.slug)
  }

  useEffect(() => {
    fetchdashboarddata();
  }, [])
  return (
    <>
      <Overlay showloader={showloding} />
      <Carousel data={sliderdata} />
      {/* <Tune data={homepagesliderdata} /> */}
      {/* <section className="mt-5 mb-5">
      <center>
        <RazorpayBadge />
    </center>
      </section> */}
      <section className="bgclor pd50 text-white relative">
        <div className="container">
          <h3 className="uppercase text-white">New Release</h3>
          <div className="heading-border left-heading-border"></div>
          <div>
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              freeMode={true}
              loop={true}
              navigation={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[FreeMode, Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 2, // For mobile screens
                },
                768: {
                  slidesPerView: 2, // For tablets
                },
                1024: {
                  slidesPerView: 4, // For larger screens
                },
              }}
            >
              {homepagesliderdata?.length > 0
                ? homepagesliderdata?.map((item, index) => {
                    if (item?.Type == "New Release") {
                      var img = null;
                      if (item?.image1) {
                        img = item?.image1;
                      } else if (item?.image2) {
                        img = item?.image2;
                      } else if (item?.image3) {
                        img = item?.image3;
                      } else if (item?.image4) {
                        img = item?.image4;
                      } else {
                        img = null;
                      }
                      // console.log('img >>', img)
                      return (
                        <SwiperSlide
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handlenavigation(item);
                          }}
                        >
                          <div>
                            <img src={assetsUrl + "products/" + img} />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })
                : null}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="bgclor pd50 text-white relative">
        <div className="container">
          <h3 className="uppercase text-white">Pre-Book</h3>
          <div className="heading-border left-heading-border"></div>
          <div>
            <Swiper
              slidesPerView={4}
              spaceBetween={20}
              freeMode={true}
              loop={true}
              navigation={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[FreeMode, Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 2, // For mobile screens
                },
                768: {
                  slidesPerView: 2, // For tablets
                },
                1024: {
                  slidesPerView: 4, // For larger screens
                },
              }}
            >
              {homepagesliderdata?.length > 0
                ? homepagesliderdata?.map((item, index) => {
                    if (item?.Type == "Upcoming") {
                      var img = null;
                      if (item?.image1) {
                        img = item?.image1;
                      } else if (item?.image2) {
                        img = item?.image2;
                      } else if (item?.image3) {
                        img = item?.image3;
                      } else if (item?.image4) {
                        img = item?.image4;
                      } else {
                        img = null;
                      }
                      // console.log('img >>', img)
                      return (
                        <SwiperSlide
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handlenavigation(item);
                          }}
                        >
                          <div>
                            <img src={assetsUrl + "products/" + img} />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })
                : null}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="bgclor pd50 text-white relative">
        <div className="container">
          <h3 className="uppercase text-white">Exclusive</h3>
          <div className="heading-border left-heading-border"></div>
          <div>
            <Swiper
              slidesPerView={4}
              spaceBetween={20}
              freeMode={true}
              loop={true}
              navigation={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[FreeMode, Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 2, // For mobile screens
                },
                768: {
                  slidesPerView: 2, // For tablets
                },
                1024: {
                  slidesPerView: 4, // For larger screens
                },
              }}
            >
              {homepagesliderdata?.length > 0
                ? homepagesliderdata?.map((item, index) => {
                    if (item?.Type == "Exclusive") {
                      var img = null;
                      if (item?.image1) {
                        img = item?.image1;
                      } else if (item?.image2) {
                        img = item?.image2;
                      } else if (item?.image3) {
                        img = item?.image3;
                      } else if (item?.image4) {
                        img = item?.image4;
                      } else {
                        img = null;
                      }
                      // console.log('img >>', img)
                      return (
                        <SwiperSlide
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handlenavigation(item);
                          }}
                        >
                          <div>
                            <img src={assetsUrl + "products/" + img} />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })
                : null}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="bgclor pd50 text-white relative">
        <div className="container">
          <h3 className="uppercase text-white">Best Sellers</h3>
          <div className="heading-border left-heading-border"></div>
          <div>
            <Swiper
              slidesPerView={4}
              spaceBetween={20}
              freeMode={true}
              loop={true}
              navigation={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[FreeMode, Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 2, // For mobile screens
                },
                768: {
                  slidesPerView: 2, // For tablets
                },
                1024: {
                  slidesPerView: 4, // For larger screens
                },
              }}
            >
              {homepagesliderdata?.length > 0
                ? homepagesliderdata?.map((item, index) => {
                    if (item?.Type == "Imports") {
                      var img = null;
                      if (item?.image1) {
                        img = item?.image1;
                      } else if (item?.image2) {
                        img = item?.image2;
                      } else if (item?.image3) {
                        img = item?.image3;
                      } else if (item?.image4) {
                        img = item?.image4;
                      } else {
                        img = null;
                      }
                      // console.log('img >>', img)
                      return (
                        <SwiperSlide
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handlenavigation(item);
                          }}
                        >
                          <div>
                            <img src={assetsUrl + "products/" + img} />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  })
                : null}
            </Swiper>
          </div>
        </div>
      </section>
      
      <Cards data={blogsdata} />

      <section className="bgclor pd50">
        <div className="container">
          <h3 className="uppercase text-white text-center">About Us</h3>
          <div className="heading-border"></div>

          <div className="row dflex align-items">
            <div className="col-md-12">
              <div className=" text-white">
                <p className="weight600">
                  Indian music has a huge category in the list. Vinyl record is
                  one of the best experiences you can have with music. With the
                  world, the Indian music market went digital with music but
                  quality music having the same essence of the first time it was
                  recorded is what a vinyl record provides. The demand for Vinyl
                  records and CD's is rising with each day. The retro feels and
                  physically owning a record that you can have for decades. The
                  demand-supply equation is lopsided in favour of demand. The
                  real fans and connoisseurs are feeling the pinch. Indian Vinyl
                  is what a true music fan will own in their lifetime. It’s time
                  you own your Vinyl record combined with impeccable sound
                  quality, designed aesthetically only for you.
                </p>
                <p className="weight600">
                  As a true provider for music fans, we understand this hunger
                  at Blisstainment - a brand owned by BASKING SHARK PRIVATE
                  LIMITED.
                </p>
              </div>
            </div>
            {/* <div className="col-md-4">
              <img src={about} className="w100" />
            </div> */}
          </div>
        </div>
      </section>
      <section className=" bgclor pd50 text-white relative">
        <div className="container">
          <h3 className="uppercase text-center text-white">WHY BUY FROM US</h3>
          <div className="heading-border"></div>
          <div className="row dflex align-items">
            {/* <div className="col-md-4">
              <div className="pdr60 text-white">
                <img src={why} className="w100" />
              </div>
            </div> */}
            <div className="col-md-12">
              <p className="weight600">
                Blisstainment was created for the love of music that is imbibed
                in us. A combined experience in the physical music and movie
                distribution industry over four decades, we offer you the ease
                of doing business in India and having the ultimate access to
                music lovers across the breadth and width of the Indian
                subcontinent and the world over. Over the years as the rage for Vinyl records have increased
                immensely we have fulfilled the demands of various music lovers
                looking for their favourite music, artist or preferred format.
              </p>
              {/* <p className="weight600">
                Over the years as the rage for Vinyl records have increased
                immensely we have fulfilled the demands of various music lovers
                looking for their favourite music, artist or preferred format.
              </p> */}
            </div>
          </div>
        </div>
        {/* <div className="home_btn_div">
           <Link to='contact-us' className="home_btn">Contact</Link>
           
        </div> */}
      </section>

      <section className=" bgclor pd50 text-black relative">
        <div className="container">
          <h3 className="uppercase text-center text-white">
            CLIENT TESTIMONIAL
          </h3>
          <div className="heading-border"></div>

          <div className="row dflex align-items">
            <Swiper
              slidesPerView={2}
              spaceBetween={20}
              // freeMode={true}
              loop={true}
              navigation={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation]}
              className="mySwiper1"
            >
              {testimonialdata?.length > 0
                ? testimonialdata?.map((item, index) => {
                  console.log('testimonialdata item >>', item);
                    var image = null;
                    if (item?.image) {
                      image = item?.image;
                    } else {
                      image = "pp.png";
                    }
                    return (
                      <SwiperSlide className="testislider" key={index}>
                        <div className="col-md-12">
                          <div className="clientdiv">
                            <div className="dflex align-items">
                              <div className="clientPic text-center">
                                <img
                                  src={assetsUrl + "sliders/" + image}
                                  className="testimonialimage"
                                />
                                <p className="clrred fs22 mt-3">{item?.name}</p>
                                <p className="clrgrey fs18">
                                  {item?.location ? item?.location : item?.location1 ? item?.location1 : ""}
                                </p>
                              </div>

                              <div className="clientText ml30">
                                <p className="fs16">{item?.content}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })
                : null}
            </Swiper>

            {/* <div className="col-md-6">
             <div className="clientdiv">
                <div className="dflex align-items">
                  <div className="clientPic text-center">
                    <img src={kausik} />
                    <p className="clrred fs22 mt-3">Kaushik Gopinath</p>
                    <p className="clrgrey fs18">Happy customer</p>
                  </div>

                  <div className="clientText ml30">
                   <p className="fs16">As a customer of Basking Shark, the only thing I can say is that you are dealing with two young entrepreneurs who are trying to redefine customer satisfaction. I have been buying CDs and LPs from them for the past 3 years and it has been a very satisfying experience. 
                    There personal engagement adds value to this experience. I wish them every success in all their endeavors.</p>
                  </div>
                </div>
             </div>
          </div>

          <div className="col-md-6">
        
          <div className="clientdiv">
                <div className="dflex align-items">
                  <div className="clientPic text-center">
                    <img src={dummyclient} />
                    <p className="clrred fs22 mt-3">Varun Shah</p>
                    <p className="clrgrey fs18">Happy customer</p>
                  </div>

                  <div className="clientText ml30">
                   <p className="fs16">Shopping experience with Blisstainment was a 
                    Bliss! Fantastic collection great prices and super customer friendly certainly recommended!</p>
                  </div>
                </div>
             </div>


          </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
