import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { Link } from "react-router-dom";
import Overlay from '../components/Overlay';
import Spinner from "react-activity/dist/Spinner";

const Terms = () => {

    return (
        <section class="terms-section">
        <div class="container">
          <div class="terms-page">
            <h2>TERMS OF USE :</h2>
            <p>Blisstainment is wholly owned by Basking Shark Private Limited.<br /> 
            The use of this website is <a href="/">www.blisstainment.com</a> is subject to following terms:</p>
            <ol>
                <li>The content and all other information of this website is for your general information and use only. It is
                subject to change without prior notice.</li>
                <li>The use of any information or materials on this website by you is entirely at your own risk for which
                we shall not be liable.</li>
                <li>By purchasing products and or services from the site, you agree to be bound by and accept our terms of use.</li>
                <li>We reserve the right to refuse service to anyone i.e. orders are not binding upon Basking Shark until
                 accepted by Basking Shark. Acceptance confirmation of any orders or services will be notified via
                 email or shipping details by Basking Shark.</li>
                 <li>We have tried to provide accurate information on this website however neither we nor any third
                    party provide any warranty or guarantee as to the accuracy of the information and materials found or
                    offered on this website. Basking Shark expressly excludes any liabilities arising out of inaccuracies or
                     errors on this site to the fullest extent permitted by law.</li>
                     <li>Unauthorised use of this website is prohibited and may lead to criminal offence.</li>
            </ol>
            <h2>TERMS OF USE :</h2>
            <p>The information provided by you on this website ( email ids, phone numbers etc.) are all stored with
    us and will not be shared/rent or sell to anyone. We may use this information to reach out to you for all your
    queries regarding our services/products which you may have enquired while providing your information. We
    may also use your information to update you about our offers, products, services or any change in this policy.
    You may opt out of any future contacts by simply emailing us or through a call.</p>
    
    <p>All your sensitive data like bank details or credit card details which you may have used during
    transaction on this website are encrypted and transmitted to us in a secure way. All these datas are protected
    both online and offline with us.</p>
    
    <p>Our privacy policy may change from time to time and all the updates will be posted on this page.</p>
    
    <h2>Shipping and Payment :</h2>
    
    <p>All orders once confirmed by us will be shipped within 2 days and will be delivered within 5-6 days.
    Delivery might be delayed for addresses that are in remote locations. We have a tie up with some of the best
    courier service provider, rest assured that all your orders will reach you safe and sound. We handle
    International delivery as well , please get in touch for more at <a href="mailto:info@blisstainment.com"> info@blisstainment.com</a></p>
    <p>We accept all types of payments like visa, master card, American express, UPI, Wallet , paypal etc.
    Currently we are not accepting Cash on Delivery.</p>
    
    <h2>Cancel and Return Policy:</h2>
    <p>All products sold on this website are genuine and original. However, any manufacturing defects or
    damaged during shipping are fully refundable. All you have to do is inform us via email about the issue within 2
    days of receiving the product and return the damaged product to us. Once we receive the product and done
    with physical inspection, refunds will be initiated as per bank rules.</p>
    
          </div>
        </div>
        <br /><br />
    </section>
    );
}

export default Terms