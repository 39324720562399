import React, {useEffect, useState, useMemo, useRef} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from 'moment';
import Spinner from "react-activity/dist/Spinner";
import Select from 'react-select';
import "react-activity/dist/Spinner.css";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const HomepageProducts = () => {
  const generateRandomString = () => {
    const array = new Uint8Array(16); // 16 bytes
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
}
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [selectedid, setselectedid] = useState(0);
    const [categoriesdropdowndata, setcategoriesdropdowndata] = useState([]);

    const today = new Date().toISOString().split('T')[0];

    const [productslistdata, setproductslistdata] = useState([]);
    const [selectedproducts, setselectedproducts] = useState([]);
    const [type, settype] = useState("");


    const [name, setname] = useState("");

    const selectedidRef = useRef(0);
    selectedidRef.current = selectedid;



    const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const submithandle = async () => {
      setshowloding(true);
      var data = {
        products: selectedproducts,
        home_type: type,
    };
    console.log('data >>', data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewhomepageproduct", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setselectedproducts([]);
            settype("");
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  };


    const clickhandle = () =>  {
        // console.log('Mission Launched')
      }


      const deletecourse = async (id) => {
        console.log('id >>', id);
        const result = window.confirm("Are you sure you want to delete this product");
        if (result) {
          var data = JSON.stringify({
            id: id,
          });
          const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userdata?.token,
            },
          };
          let resp = await axios
            .post(apiUrl + "deletehomepageproduct", data, headers)
            .then((res) => {
              if (res.data.status == "success") {
                fetchallcourses();
              } else {
              }
            })
            .catch((err) => {
              alert(err.message);
            });
        } else {
          // User clicked Cancel or closed the dialog
          // console.log("User clicked Cancel");
        }
      };

      const defaultColDef = {
        flex: 1,
        resizable: true,
        sortable: true 
      };


    const fetchallcourses = async () => {
        setshowloding(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .get(apiUrl + "getallhomepageproducts", headers)
          .then((res) => {
            if (res.data.status == "success") {
              // console.log('getallcategories >>', res.data);
              var prevdata = res.data.data;
              if(prevdata?.length > 0) {
                var columnsarr = [];
                var hidecolumns = ['id', 'created_at', 'updated_at', 'sort', 'image', 'category_id'];
                var hidefilters = [];
                var columns = Object.keys(prevdata[0]);
                // console.log('columns  >>>', columns );
                for(var i = 0; i < columns?.length;i++) {
                  if(!hidecolumns.includes(columns[i])) {
                    var obj = {};
                    obj.field = columns[i];
                    if (!hidefilters.includes(columns[i])) {
                        obj.filter = true;
                        obj.filterParams = {
                          filterOptions: ["contains"],
                          maxNumConditions: 1,
                        };
                      }
                    console.log('obj >>', obj)
                    columnsarr.push(obj);
                  }
                }

                //Image
                if(columns.includes('image')) {
                  var imageobj = 
              {
                field: "Image",
                headerName: "Image",
                cellRenderer: (props) => {
                  // console.log('image props >>', props);
                  return (
                    <Link
                    to={assetsUrl+'sub-categories/'+props?.data?.image}
                    target="_blank"
                    style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </Link>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(imageobj);
                }

                //created_at
                if(columns.includes('created_at')) {
                  var craetddatobj = 
              {
                field: "Date",
                headerName: "Date",
                cellRenderer: (props) => {
                  // console.log('date props >>', props);
                  return (
                         <div>
                          <p className="created_at_value">{moment(props?.data?.created_at).format('DD MMMM YYYY HH:mm')}</p>
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(craetddatobj);
                }
              
                //edit
              // var editobj = 
              // {
              //   field: "edit",
              //   headerName: "Edit",
              //   cellRenderer: (props) => {
                  
              //     return (
              //       <div
              //             onClick={() => {
              //               editlocation(props?.data?.id, props?.data?.name, props?.data?.type);
              //             }}
              //             style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
              //           >
              //             <FontAwesomeIcon
              //               icon={faPenToSquare}
              //               className="sidebaricons"
              //               style={{ color: "blue" }}
              //             />
              //           </div>
              //     )
              //   },
              //   cellRendererParams: {
              //     onClick: clickhandle,
              //   },
              // }
              // columnsarr.push(editobj);
                //delete
                var deleteobj = 
                {
                  field: "delete",
                  headerName: "Delete",
                  cellRenderer: (props) => {
                    
                    return (
                      <div
                            onClick={() => {
                              deletecourse(props?.data?.id);
                              // console.log('props >>', props?.data);
                            }}
                            style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="sidebaricons"
                              style={{ color: "red" }}
                            />
                          </div>
                    )
                  },
                  cellRendererParams: {
                    onClick: clickhandle,
                  },
                }
                columnsarr.push(deleteobj);
                setColDefs(columnsarr);
                setRowData(prevdata);
              }
              
              // setcoursesdata(res.data.data);
            } else {
              // alert(res.data.message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
          setshowloding(false);
      };

    const fetchallproducts = async() => {
        const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userdata?.token,
            },
          };
          let resp = await axios
            .get(apiUrl + "getallproducts", headers)
            .then((res) => {
              if (res.data.status == "success") {
                console.log("getallproducts >>", res.data);
          if (res?.data?.data?.length > 0) {
            var prevdata = res?.data?.data;
            var newarr = [];
            for (var i = 0; i < prevdata?.length; i++) {
              var obj = {};
              obj["label"] = prevdata[i]?.name;
              obj["value"] = prevdata[i]?.id;
              
              newarr.push(obj);
            }
            setproductslistdata(newarr);
          }
                // setcoursesdata(res.data.data);
              } else {
                // alert(res.data.message);
              }
            })
            .catch((err) => {
              alert(err.message);
            });
    }
    useEffect(() => {
        if (userdata && Object.keys(userdata).length > 0) {
          //   fetchdata();
          fetchallcourses();
          fetchallproducts();
        } else {
          navigate("/newadminlogin");
        }
      }, []);
    return (
      <div className="adminmainpagelayout">
        <div className="admin-categorymaindiv">
          <p className="admin-page-title">View All Homepage Products</p>
          <div
            className="category-add-cat-btn"
            onClick={() => {
              setselectedproducts([]);
              settype("")
              setshowmodal(true);
            }}
          >
            <p>Add Product</p>
          </div>
        </div>

        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: 500, marginTop: 15 }}
        >
          <AgGridReact
            // modules={AllModules}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            rowSelection={"multiple"}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={paginationPageSizeSelector}
            // onGridReady={onGridReady}
          />
        </div>

        {/*Add modal */}
        <Modal
          show={showmodal}
          onHide={() => setshowmodal(false)}
          size="md"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
              <div className="">
                <label className="admin-label">Select Product</label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  value={selectedproducts}
                  onChange={setselectedproducts}
                  options={productslistdata}
                />
              </div>
              <div className="mt-3">
                <div className="">
                  <label className="admin-label">Select Type</label>
                  <select className="form-control admininput" value={type} onChange={(t) => settype(t.target.value)}>
                    <option value={''} selected disabled>Type</option>
                    <option value={'Exclusive'}>Exclusive</option>
                    <option value={'New Release'}>New Release</option>
                    <option value={'Upcoming'}>Upcoming</option>
                    <option value={'Imports'}>Imports</option>
                  </select>
                 
                </div>
              </div>

              <div
                className="webadmin-modalbtn"
                onClick={() => {
                  submithandle();
                }}
              >
                <p>
                  {showloding ? (
                    <Spinner size={15} className="spinnerCenter" />
                  ) : (
                    "Submit"
                  )}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
}

export default HomepageProducts