import React, {useEffect, useState, useMemo, useRef} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from 'moment';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const ProductFilters = () => {
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [selectedid, setselectedid] = useState(0);
    const [categoriesdropdowndata, setcategoriesdropdowndata] = useState([]);

    const [name, setname] = useState("");
    const [type, settype] = useState("Movie");

    const selectedidRef = useRef(0);
    selectedidRef.current = selectedid;



    const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const submithandle = async () => {
    
  console.log('submithandle >>', data);
    if (name && type ) {
      setshowloding(true);
      var data = {
        name: name,
        type: type,
    };
    console.log('data >>', data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewfilters", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setname("");
            settype("Movie");
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const edithandle = async () => {
    if (name && type) {
        setshowloding(true);
        var data = {
          id: selectedidRef.current,
          name: name,
          type: type,
      };
      console.log('submithandle >>', data);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "editnewfilters", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              seteditshowmodal(false);
              setname("");
              settype("Movie");
              setselectedid(0);
              fetchallcourses();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
    };

    const clickhandle = () =>  {
        // console.log('Mission Launched')
      }

      const editlocation = async(id, name, type) => {
        console.log('id >>', id);
        setselectedid(id);
        setname(name);
        settype(type);
        seteditshowmodal(true);
      }

      const deletecourse = async (id, title) => {
        console.log('id >>', id);
        const result = window.confirm("Are you sure you want to delete");
        if (result) {
          var data = JSON.stringify({
            id: id,
          });
          const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userdata?.token,
            },
          };
          let resp = await axios
            .post(apiUrl + "deletefilters", data, headers)
            .then((res) => {
              if (res.data.status == "success") {
                fetchallcourses();
              } else {
              }
            })
            .catch((err) => {
              alert(err.message);
            });
        } else {
          // User clicked Cancel or closed the dialog
          // console.log("User clicked Cancel");
        }
      };

      const defaultColDef = {
        flex: 1,
      };


    const fetchallcourses = async () => {
        setshowloding(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .get(apiUrl + "getallfilters", headers)
          .then((res) => {
            if (res.data.status == "success") {
              // console.log('getallcategories >>', res.data);
              var prevdata = res.data.data;
              if(prevdata?.length > 0) {
                var columnsarr = [];
                var hidecolumns = ['id', 'created_at', 'updated_at', 'sort', 'image', 'category_id'];
                var columns = Object.keys(prevdata[0]);
                // console.log('columns  >>>', columns );
                for(var i = 0; i < columns?.length;i++) {
                  if(!hidecolumns.includes(columns[i])) {
                    var obj = {};
                    obj.field = columns[i];
                    console.log('obj >>', obj)
                    columnsarr.push(obj);
                  }
                }

                //Image
                if(columns.includes('image')) {
                  var imageobj = 
              {
                field: "Image",
                headerName: "Image",
                cellRenderer: (props) => {
                  // console.log('image props >>', props);
                  return (
                    <Link
                    to={assetsUrl+'sub-categories/'+props?.data?.image}
                    target="_blank"
                    style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </Link>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(imageobj);
                }

                //created_at
                if(columns.includes('created_at')) {
                  var craetddatobj = 
              {
                field: "Date",
                headerName: "Date",
                cellRenderer: (props) => {
                  // console.log('date props >>', props);
                  return (
                         <div>
                          <p className="created_at_value">{moment(props?.data?.created_at).format('DD MMMM YYYY HH:mm')}</p>
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(craetddatobj);
                }
              
                //edit
              var editobj = 
              {
                field: "edit",
                headerName: "Edit",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data?.id, props?.data?.name, props?.data?.type);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
                //delete
                var deleteobj = 
                {
                  field: "delete",
                  headerName: "Delete",
                  cellRenderer: (props) => {
                    
                    return (
                      <div
                            onClick={() => {
                              deletecourse(props?.data?.id, props?.data?.name);
                              // console.log('props >>', props?.data);
                            }}
                            style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="sidebaricons"
                              style={{ color: "red" }}
                            />
                          </div>
                    )
                  },
                  cellRendererParams: {
                    onClick: clickhandle,
                  },
                }
                columnsarr.push(deleteobj);
                setColDefs(columnsarr);
                setRowData(prevdata);
              }
              
              // setcoursesdata(res.data.data);
            } else {
              // alert(res.data.message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
          setshowloding(false);
      };
    useEffect(() => {
        if (userdata && Object.keys(userdata).length > 0) {
          //   fetchdata();
          fetchallcourses();
        } else {
          navigate("/newadminlogin");
        }
      }, []);
    return (
        <div className="adminmainpagelayout">
          <div className="admin-categorymaindiv">
            <p className="admin-page-title">View All Genres</p>
            <div className="category-add-cat-btn" onClick={() => {
                  setname("")
                  settype("")
                  setshowmodal(true);
                }}><p>Add Genre</p></div>
          </div>
            

            <div
      className={
        "ag-theme-quartz"
      }
      style={{ width: "100%", height: 500, marginTop: 15 }}
    >
      <AgGridReact
      // modules={AllModules}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        suppressRowClickSelection={true}
        groupSelectsChildren={true}
        rowSelection={"multiple"}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={paginationPageSizeSelector}
        // onGridReady={onGridReady}
      />
    </div>

    {/*Add modal */}
    <Modal
              show={showmodal}
              onHide={() => setshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 19}}>Add Genre</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                  <div className="">
                    <label className="admin-label">Name</label>
                    <input
                      value={name}
                      onChange={(t) => {
                        setname(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Format Name"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="admin-label">Type</label>
                    <select className="form-control admininput" value={type} onChange={(t) => {settype(t.target.value)}}>
                      <option selected value={'Movie'}>Movie</option>
                      <option value={'Music'}>Music</option>
                      {/* <option value={'Health Care'}>Health Care</option> */}
                    </select>
                  </div>
                  
                  <div
                    className="webadmin-modalbtn"
                    onClick={() => {
                      submithandle();
                    }}
                  >
                    <p>
                    {
                      showloding ?
                      <Spinner size={15} className='spinnerCenter' /> : 'Submit'
                    }
                    </p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

    
     {/*Edit modal */}
    <Modal
              show={editshowmodal}
              onHide={() => seteditshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 19}}>Edit Genre</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                <div className="">
                    <label className="admin-label">Name</label>
                    <input
                      value={name}
                      onChange={(t) => {
                        setname(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Format Name"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="admin-label">Type</label>
                    <select className="form-control admininput" value={type} onChange={(t) => {settype(t.target.value)}}>
                      <option selected value={'Movie'}>Movie</option>
                      <option value={'Music'}>Music</option>
                      {/* <option value={'Health Care'}>Health Care</option> */}
                    </select>
                  </div>
                  
                  <div
                    className="webadmin-modalbtn"
                    onClick={() => {
                      edithandle();
                    }}
                  >
                    <p>
                    {
                      showloding ?
                      <Spinner size={15} className='spinnerCenter' /> : 'Submit'
                    }
                    </p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>


        </div>
    )
}

export default ProductFilters