import React, { useState, useRef } from "react";
import admintelogo from '../../images/AdminLTELogo.png';
import avatar4 from '../../images/avatar04.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTrash, faGauge, faGift, faPercent, faTruck, faTable, faTag, faAlignJustify, faHandshake, faTableList, faFilter,
    faRectangleAd, faCartShopping, faCartFlatbed, faCartPlus , faDisplay, faComment, faUserGroup, faRankingStar,
    faAngleDown, faAngleUp, faBlog
 } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AdminSidebar = ({name}) => {
    let navigate = useNavigate();
    const navigatefunction = (link) => {
        navigate('/admin/'+link);
    }
    const navlinks = [
        {icon: faGauge, title: 'Dashboard', link: 'dashboard', sublinks: [], isshow: false},
        {icon: faTable, title: 'Category', link: 'category', sublinks: [], isshow: false},
        {icon: faTableList, title: 'Sub Category', link: 'sub-category', sublinks: [], isshow: false},
        {icon: faGift, title: 'Product', link: '', sublinks: [
          {title: 'All Products', link: 'product'},
          {title: 'Homepage', link: 'homepage-products'},
        ], isshow: false},
        // {icon: faPercent, title: 'Discounts', link: 'discounts'},
        {icon: faTruck, title: 'Shipping Charges', link: 'shipping-charges', sublinks: [], isshow: false},
        {icon: faTag, title: 'Offers', link: 'offers', sublinks: [], isshow: false},
        {icon: faAlignJustify, title: 'Formats', link: 'formats', sublinks: [], isshow: false},
        {icon: faHandshake, title: 'Deals', link: 'deals', sublinks: [], isshow: false},
        {icon: faFilter, title: 'Genre', link: 'genre', sublinks: [], isshow: false},
        {icon: faRectangleAd, title: 'Promotions', link: 'promotions', sublinks: [], isshow: false},
        // {icon: faCartShopping, title: 'Orders', link: 'orders'},
        {icon: faCartFlatbed, title: 'All Orders', link: 'all-orders', sublinks: [], isshow: false},
        // {icon: faCartPlus, title: 'Generated Orders', link: 'generated-orders'},
        {icon: faDisplay, title: 'Add Sliders', link: 'sliders', sublinks: [], isshow: false},
        {icon: faComment, title: 'Add Testimonials', link: 'testimonials', sublinks: [], isshow: false},
        {icon: faUserGroup, title: 'Users', link: 'users', sublinks: [], isshow: false},
        {icon: faRankingStar, title: 'SEO', link: 'seo', sublinks: [], isshow: false},
        {icon: faComment, title: 'User Feedback', link: 'userFeedbackList', sublinks: [], isshow: false},
        {icon: faBlog, title: 'Blogs', link: 'blogs', sublinks: [], isshow: false}
    ]

    const [forcestateupdate, setforcestateupdate] = useState(false);
    const [navlinksstate, setnavlinksstate] = useState(navlinks);
    const navlinksstateRef = useRef([]);
    navlinksstateRef.current = navlinksstate;


    const hanglesubdropdownshow = (index) => {
      var prevdata = navlinksstateRef.current;
      prevdata[index]['isshow'] = !prevdata[index]['isshow'];
      setnavlinksstate(prevdata);
      setforcestateupdate(!forcestateupdate);
    }

    return (
      <div className="sidebar-main webadmin-sidebarmain">
        <div className="sidebar-topdiv">
          <img src={admintelogo} className="admintelogo" />
          <p className="sidebartitle-head">Blisstainment</p>
        </div>
        <div className="sidebar-topdiv">
          <img src={avatar4} className="avatar4logo" />
          <p className="sidebartitle-adminname">{name}</p>
        </div>
        <div className="sidebar-mainnavlist">
          {navlinksstateRef.current?.map((item, index) => {
            return (
              <div className="" key={index}>
                <div
                className="sidebar-singlenavbox"
                onClick={() => {
                  item?.sublinks?.length ?
                  hanglesubdropdownshow(index) :
                  navigatefunction(item?.link)
                }}
              >
                <FontAwesomeIcon
                  icon={item?.icon}
                  className="sidebarnavicons"
                  style={{ color: "white" }}
                />
                <p className="sidebarnavlink">{item?.title}</p>
                {
                  item?.sublinks?.length > 0 ?
                  <FontAwesomeIcon
                  icon={item?.isshow ? faAngleUp : faAngleDown}
                  className="sidebarnavicons sidebartoggleicon"
                  style={{ color: "white" }}
                /> : null
                }
              </div>
                {
                  item?.sublinks?.length > 0 ?
                  item?.isshow ?
                  item.sublinks?.map((sitem, sindex) => {
                    return (
                      <div
                className="sidebar-singlenavbox"
                style={{backgroundColor: '#ccc'}}
                onClick={() => {
                  navigatefunction(sitem?.link)
                }}
                key={sindex}
              > 
              <p className="sidebarnavlink" style={{marginLeft: 25, color: 'rgba(76,81,90,1)'}}>{sitem?.title}</p>
              </div>
                    )
                  })
                  : null
                  : null
                }
              </div>
              
            );
          })}
        </div>
      </div>
    );
};

export default AdminSidebar