import React, {useEffect, useState, useMemo, useRef} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from 'moment';
import Spinner from "react-activity/dist/Spinner";
import Select from 'react-select';
import "react-activity/dist/Spinner.css";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const PageSeo = () => {
  const generateRandomString = () => {
    const array = new Uint8Array(16); // 16 bytes
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
}
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [selectedid, setselectedid] = useState(0);
    const [categoriesdropdowndata, setcategoriesdropdowndata] = useState([]);

    const today = new Date().toISOString().split('T')[0];

    const [productslistdata, setproductslistdata] = useState([]);
    const [selectedproducts, setselectedproducts] = useState([]);
    const [enddate, setenddate] = useState("");
    const [discountpercent, setdiscountpercent] = useState("");
    const [discounturl, setdiscounturl] = useState(generateRandomString());

    const [pageurl, setpageurl] = useState("");
    const [pagetitle, setpagetitle] = useState("");
    const [description, setdescription] = useState("");
    const [keyword, setkeyword] = useState("");
    const [canonical, setcanonical] = useState("");
    const [metatag, setmetatag] = useState("");
    const [pagescript, setpagescript] = useState("");


    const [name, setname] = useState("");
    const [type, settype] = useState("Movie");

    const selectedidRef = useRef(0);
    selectedidRef.current = selectedid;



    const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const submithandle = async () => {
      setshowloding(true);
      var data = {
        page_url: pageurl,
        page_title: pagetitle,
        description: description,
        keyword: keyword,
        canonical: canonical,
        meta_tag: metatag,
        page_script: pagescript
    };
    console.log('data >>', data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewpageseo", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setpageurl("");
            setpagetitle("");
            setdescription("");
            setkeyword("");
            setcanonical("");
            setmetatag("");
            setpagescript("");
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  };

  const edithandle = async () => {
        setshowloding(true);
        var data = {
          id: selectedidRef.current,
          page_url: pageurl,
          page_title: pagetitle,
          description: description,
          keyword: keyword,
          canonical: canonical,
          meta_tag: metatag,
          page_script: pagescript
      };
      console.log('submithandle >>', data);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "updatepageseo", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              seteditshowmodal(false);
              setpageurl("");
              setpagetitle("");
              setdescription("");
              setkeyword("");
              setcanonical("");
              setmetatag("");
              setpagescript("");
              setselectedid(0);
              fetchallcourses();
              setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      
    };

    const clickhandle = () =>  {
        // console.log('Mission Launched')
      }

      const editlocation = async(obj) => {
        console.log('obj >>', obj);
        setselectedid(obj?.id);
        setpagetitle(obj?.page_title);
        setkeyword(obj?.keyword);
        setdescription(obj?.description);
        setpageurl(obj?.page_url);
        setcanonical(obj?.canonical);
        setmetatag(obj?.meta_tag);
        setpagescript(obj?.page_script);
        seteditshowmodal(true);
      }

      const deletecourse = async (id) => {
        console.log('id >>', id);
        const result = window.confirm("Are you sure you want to delete this config");
        if (result) {
          var data = JSON.stringify({
            id: id,
          });
          const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userdata?.token,
            },
          };
          let resp = await axios
            .post(apiUrl + "deletepageseo", data, headers)
            .then((res) => {
              if (res.data.status == "success") {
                fetchallcourses();
              } else {
              }
            })
            .catch((err) => {
              alert(err.message);
            });
        } else {
          // User clicked Cancel or closed the dialog
          // console.log("User clicked Cancel");
        }
      };

      const defaultColDef = {
        // flex: 1,
        resizable: true,
        sortable: true 
      };


    const fetchallcourses = async () => {
        setshowloding(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .get(apiUrl + "getallpageseo", headers)
          .then((res) => {
            if (res.data.status == "success") {
              // console.log('getallcategories >>', res.data);
              var prevdata = res.data.data;
              if(prevdata?.length > 0) {
                var columnsarr = [];
                var hidecolumns = ['id', 'created_at', 'updated_at', 'sort', 'image', 'category_id'];
                var hidefilters = [];
                var columns = Object.keys(prevdata[0]);
                // console.log('columns  >>>', columns );
                for(var i = 0; i < columns?.length;i++) {
                  if(!hidecolumns.includes(columns[i])) {
                    var obj = {};
                    obj.field = columns[i];
                    if (!hidefilters.includes(columns[i])) {
                        obj.filter = true;
                        obj.filterParams = {
                          filterOptions: ["contains"],
                          maxNumConditions: 1,
                        };
                      }
                    console.log('obj >>', obj)
                    columnsarr.push(obj);
                  }
                }

                //Image
                if(columns.includes('image')) {
                  var imageobj = 
              {
                field: "Image",
                headerName: "Image",
                cellRenderer: (props) => {
                  // console.log('image props >>', props);
                  return (
                    <Link
                    to={assetsUrl+'sub-categories/'+props?.data?.image}
                    target="_blank"
                    style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </Link>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(imageobj);
                }

                //created_at
                if(columns.includes('created_at')) {
                  var craetddatobj = 
              {
                field: "Date",
                headerName: "Date",
                cellRenderer: (props) => {
                  // console.log('date props >>', props);
                  return (
                         <div>
                          <p className="created_at_value">{moment(props?.data?.created_at).format('DD MMMM YYYY HH:mm')}</p>
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(craetddatobj);
                }
              
                //edit
              var editobj = 
              {
                field: "edit",
                headerName: "Edit",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
                //delete
                var deleteobj = 
                {
                  field: "delete",
                  headerName: "Delete",
                  cellRenderer: (props) => {
                    
                    return (
                      <div
                            onClick={() => {
                              deletecourse(props?.data?.id);
                              // console.log('props >>', props?.data);
                            }}
                            style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="sidebaricons"
                              style={{ color: "red" }}
                            />
                          </div>
                    )
                  },
                  cellRendererParams: {
                    onClick: clickhandle,
                  },
                }
                columnsarr.push(deleteobj);
                setColDefs(columnsarr);
                setRowData(prevdata);
              } else {
                setColDefs([]);
                setRowData([]);
              }
              
              // setcoursesdata(res.data.data);
            } else {
              // alert(res.data.message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
          setshowloding(false);
      };

    useEffect(() => {
        if (userdata && Object.keys(userdata).length > 0) {
          //   fetchdata();
          fetchallcourses();
        } else {
          navigate("/newadminlogin");
        }
      }, []);
    return (
      <div className="adminmainpagelayout">
        <div className="admin-categorymaindiv">
          <p className="admin-page-title">View All SEO Configs</p>
          <div
            className="category-add-cat-btn"
            onClick={() => {
                setpageurl("");
                setpagetitle("");
                setdescription("");
                setkeyword("");
                setcanonical("");
                setmetatag("");
                setpagescript("");
                setselectedid(0);
              setshowmodal(true);
            }}
          >
            <p>Add</p>
          </div>
        </div>

        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: 500, marginTop: 15 }}
        >
          <AgGridReact
            // modules={AllModules}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            rowSelection={"multiple"}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={paginationPageSizeSelector}
            // onGridReady={onGridReady}
          />
        </div>

        {/*Add modal */}
        <Modal
          show={showmodal}
          onHide={() => setshowmodal(false)}
          size="md"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>Add</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <label className="admin-label">Page Title</label>
                    <input
                      value={pagetitle}
                      onChange={(t) => {
                        setpagetitle(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">keyword</label>
                    <input
                      value={keyword}
                      onChange={(t) => {
                        setkeyword(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Description</label>
                    <textarea
                      value={description}
                      onChange={(t) => {
                        setdescription(t.target.value);
                      }}
                      type="text"
                      style={{height: 80}}
                      className="form-control admininput"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Page Url</label>
                    <input
                      value={pageurl}
                      onChange={(t) => {
                        setpageurl(t.target.value);
                      }}
                      type="url"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Canonical</label>
                    <input
                      value={canonical}
                      onChange={(t) => {
                        setcanonical(t.target.value);
                      }}
                      type="url"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Meta Tag</label>
                    <input
                      value={metatag}
                      onChange={(t) => {
                        setmetatag(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Script</label>
                    <textarea
                      value={pagescript}
                      onChange={(t) => {
                        setpagescript(t.target.value);
                      }}
                      type="text"
                      style={{height: 80}}
                      className="form-control admininput"
                    ></textarea>
                  </div>
                </div>
                
              </div>

              <div
                className="webadmin-modalbtn"
                onClick={() => {
                  submithandle();
                }}
              >
                <p>
                  {showloding ? (
                    <Spinner size={15} className="spinnerCenter" />
                  ) : (
                    "Submit"
                  )}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*Edit modal */}
        <Modal
          show={editshowmodal}
          onHide={() => seteditshowmodal(false)}
          size="md"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
            <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <label className="admin-label">Page Title</label>
                    <input
                      value={pagetitle}
                      onChange={(t) => {
                        setpagetitle(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">keyword</label>
                    <input
                      value={keyword}
                      onChange={(t) => {
                        setkeyword(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Description</label>
                    <textarea
                      value={description}
                      onChange={(t) => {
                        setdescription(t.target.value);
                      }}
                      type="text"
                      style={{height: 80}}
                      className="form-control admininput"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Page Url</label>
                    <input
                      value={pageurl}
                      onChange={(t) => {
                        setpageurl(t.target.value);
                      }}
                      type="url"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Canonical</label>
                    <input
                      value={canonical}
                      onChange={(t) => {
                        setcanonical(t.target.value);
                      }}
                      type="url"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Meta Tag</label>
                    <input
                      value={metatag}
                      onChange={(t) => {
                        setmetatag(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="">
                    <label className="admin-label">Script</label>
                    <textarea
                      value={pagescript}
                      onChange={(t) => {
                        setpagescript(t.target.value);
                      }}
                      type="text"
                      style={{height: 80}}
                      className="form-control admininput"
                    ></textarea>
                  </div>
                </div>
                
              </div>

              <div
                className="webadmin-modalbtn"
                onClick={() => {
                  edithandle();
                }}
              >
                <p>
                  {showloding ? (
                    <Spinner size={15} className="spinnerCenter" />
                  ) : (
                    "Submit"
                  )}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
}

export default PageSeo