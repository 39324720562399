import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AdminNavbar = () => {
    const dispatch = useDispatch(); 
    let navigate = useNavigate();
    const handlesignout = () => {
        dispatch({ type: "userdata", payload: {} })
        navigate('/admin/login');
    }

    return (
        <div className="adminmain-navbar" >
            <div className="" style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <p className="signouttext" onClick={handlesignout}>Sign Out</p>
            </div>
        </div>
    )
}

export default AdminNavbar