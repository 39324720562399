
import '../App.css';
import { Outlet } from 'react-router-dom';
import AdminSidebar from './common/Sidebar';
import { useSelector, useDispatch } from "react-redux";
import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import AdminNavbar from './common/Navbar';
import AdminFooter from './common/Footer';

const AdminLayout = () => {
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  console.log('AdminLayout userdata >>', userdata);

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      //   fetchdata();
      // fetchallcourses();
    } else {
      navigate("/admin/login");
    }
  }, []);

  return (
    <div className="HomeMain">
        <div className="sidebar-div">
          <AdminSidebar name={userdata?.first_name + ' ' + userdata?.last_name} />
        </div>
        <div className="main-layout">
            <AdminNavbar />
            <Outlet />
            <AdminFooter />
        </div>
      </div>
  )
}

export default AdminLayout