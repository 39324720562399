import React, {useEffect, useState} from "react";
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams, useNavigate } from 'react-router-dom';
import Overlay from '../components/Overlay';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";

const Deals = () => {
  const [todaydata, settodaydata] = useState([]);
  const [weekdata, setweekdata] = useState([]);
  const [alldealsdata, setalldealsdata] = useState([]);
  const [showloding, setshowloding] = useState(false);

  const navigate = useNavigate();

  const handlenavigation = (data) => {
    console.log('handlenavigation data >>', data)
    // navigate('/productdetails', {state: {data: data}});
    navigate('/entertainment/'+data?.slug)
  }


  const fetchdealsdata = async() => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
          .get(apiUrl + "getdealsdata", headers)
          .then((res) => {
            // console.log('getdashboarddata res >>', res.data)
            if(res?.data?.status == 'success') {
              if(res?.data?.daydata?.length > 0) {
                settodaydata(res?.data?.daydata)
              } else {
                settodaydata([])
              }

              if(res?.data?.weekdata?.length > 0) {
                setweekdata(res?.data?.weekdata)
              } else {
                setweekdata([])
              }
            } else {

            }
              
            
          })
          .catch((err) => {
            alert(err.message);
          });
    setshowloding(false);
  }

  useEffect(() => {
    fetchdealsdata();
  }, [])
  return (
    <section className="pt-5 pb-5">
      <div className="container">
        {todaydata?.length > 0 || weekdata?.length > 0 ? (
          <>
            {todaydata?.length > 0 ? (
              <>
                <h3 className="weight600 mt20" style={{ color: "#000" }}>
                  Best Deals of the day
                </h3>
                <div className="mt-3">
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={20}
                    freeMode={true}
                    loop={true}
                    navigation={true}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Autoplay, Navigation]}
                    className="mySwiper"
                  >
                    {todaydata?.length > 0
                      ? todaydata?.map((item, index) => {
                          var img = null;
                          if (item?.image1) {
                            img = item?.image1;
                          } else if (item?.image2) {
                            img = item?.image2;
                          } else if (item?.image3) {
                            img = item?.image3;
                          } else if (item?.image4) {
                            img = item?.image4;
                          } else {
                            img = null;
                          }
                          // console.log('img >>', img)
                          return (
                            <SwiperSlide
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handlenavigation(item);
                              }}
                            >
                              <div>
                                <img src={assetsUrl + "products/" + img} />
                              </div>
                            </SwiperSlide>
                          );
                        })
                      : null}
                  </Swiper>
                </div>
              </>
            ) : null}

            {weekdata?.length > 0 ? (
              <div className="mt-5">
                <h3 className="weight600 mt20" style={{ color: "#000" }}>
                  Deal of the week
                </h3>
                <div className="mt-3">
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={20}
                    freeMode={true}
                    loop={true}
                    navigation={true}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Autoplay, Navigation]}
                    className="mySwiper"
                  >
                    {weekdata?.length > 0
                      ? weekdata?.map((item, index) => {
                          var img = null;
                          if (item?.image1) {
                            img = item?.image1;
                          } else if (item?.image2) {
                            img = item?.image2;
                          } else if (item?.image3) {
                            img = item?.image3;
                          } else if (item?.image4) {
                            img = item?.image4;
                          } else {
                            img = null;
                          }
                          // console.log('img >>', img)
                          return (
                            <SwiperSlide
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handlenavigation(item);
                              }}
                            >
                              <div>
                                <img src={assetsUrl + "products/" + img} />
                              </div>
                            </SwiperSlide>
                          );
                        })
                      : null}
                  </Swiper>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <h3 className="weight600 mt20" style={{ color: "#000" }}>
            No deals added.
          </h3>
        )}
      </div>
    </section>
  );
}

export default Deals