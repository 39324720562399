import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { Link } from "react-router-dom";
import Overlay from '../components/Overlay';
import Spinner from "react-activity/dist/Spinner";

const SiteMap = () => {

    const [musicdropdown, setmusicdropdown] = useState([]);
  const [moviedropdown, setmoviedropdown] = useState([]);

    const fetchdropdowns = async() => {
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let resp = await axios
          .get(apiUrl + "navbardropdowns", headers)
          .then((res) => {
            if (res.data.status == "success") {
              console.log('res data >>>', res.data);
              if(res?.data?.musicdata?.length > 0) {
                setmusicdropdown(res?.data?.musicdata);
              } else {
                setmusicdropdown([]);
              }
              if(res?.data?.moviedata?.length > 0) {
                setmoviedropdown(res?.data?.moviedata);
              } else {
                setmoviedropdown([]);
              }
              
          } else {
    
          }
      })
          .catch((err) => {
            alert(err.message);
          });
    }


    useEffect(() => {
        fetchdropdowns();
      }, [])
    
    return (
      <section className="pd50">
        <div className="container main-container-sitemap" style={{marginTop: 15}}>
    <div className="row">
        <div className="col-md-2">
            <p className="sitemap_title">Home</p>
                <ul className="sitemap">
             <li><a href="/">HOME</a></li>
                 </ul>
        </div>
        
        <div className="col-md-2">
            <p className="sitemap_title">About</p>
                <ul className="sitemap">
             <li><a href="/about-us">ABOUT</a></li>
                 </ul>
             
        </div>
        
        <div className="col-md-2">
            <p className="sitemap_title">Services</p>
                <ul className="sitemap">
            <li><a href="/services">SERVICES</a></li>
                </ul>
        </div>
        
        <div className="col-md-2">
            <p className="sitemap_title">Music</p>
            
              <ul className="sitemap">

              {
                      musicdropdown?.length > 0 ?
                      musicdropdown?.map((item, index) => {
                        return (
                          <li><Link to={"/sub-category/"+item?.slug+"/Music"}>{item?.name}</Link></li>
                        )
                      }) : null
                    }
                
                </ul>
        </div>
        
        <div className="col-md-2">
            <p className="sitemap_title">Movies</p>
            
             <ul className="sitemap">
             {
                      moviedropdown?.length > 0 ?
                      moviedropdown?.map((item, index) => {
                        return (
                          <li><Link to={"/sub-category/"+item?.slug+"/Movie"}>{item?.name}</Link></li>
                        )
                      }) : null
                    }
                </ul>
        </div>
         <div className="col-md-2">
            <p className="sitemap_title">Contact</p>
            <ul className="sitemap">
            <li><a href="/contact-us">CONTACT</a></li>
            </ul>
        </div>
    </div>
</div>
      </section>
    );
}

export default SiteMap