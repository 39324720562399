import React, {useEffect, useState, useMemo, useRef} from "react";
import box from '../images/box.png';
import lock from '../images/lock.png';
import location from '../images/location.png';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../config/config";
import Overlay from '../components/Overlay';

const Myorder = () => {
    let navigate = useNavigate();
    const customerdata = useSelector((store) => store.userReducer.customerdata);

    const [feedback, setfeedback] = useState("");
    const [feedbacksdata, setfeedbacksdata] = useState([]);

    const dispatch = useDispatch(); 

    const logoutfunc = async() => {
      var data = {
        password: ""
      }
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + customerdata?.token,
        },
      };
      let resp = await axios
            .post(apiUrl + "customerlogout",data, headers)
            .then((res) => {
              // console.log('getdashboarddata res >>', res.data)
              if(res?.data?.status == 'success') {
                dispatch({ type: "customerdata", payload: {} });
              } else {
                
              }
            })
            .catch((err) => {
              alert(err.message);
  
            });
      console.log('data >>', data);
    }

    const fetchcustomerfeedbacks = async() => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + customerdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "customerfeedbackslist", headers)
        .then((res) => {
          if (res.data.status == "success") {
            if(res?.data?.data?.length > 0) {
              setfeedbacksdata(res?.data?.data);
            } else {
              setfeedbacksdata([]);
            }
            
        } else {
  
        }
    })
        .catch((err) => {
          alert(err.message);
        });
    }

    const handlesubmitfeedback = async() => {
      var data = {
        content: feedback
      }
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + customerdata?.token,
        },
      };
      let resp = await axios
            .post(apiUrl + "submitfeedback",data, headers)
            .then((res) => {
              // console.log('getdashboarddata res >>', res.data)
              if(res?.data?.status == 'success') {
                fetchcustomerfeedbacks();
                setfeedback("");
              } else {
                
              }
            })
            .catch((err) => {
              alert(err.message);
  
            });
    }

    const handlelogout = () => {
      dispatch({ type: "customerdata", payload: {} });
      logoutfunc();
      navigate("/login");
    }
    useEffect(() => {
        if (customerdata && Object.keys(customerdata).length > 0) { 
            // console.log('customerdata >>', customerdata);
            fetchcustomerfeedbacks();
          //   fetchdata();
        //   fetchallcourses();
        } else {
          navigate("/login");
        }
      }, []);

  return (
    <>
      <section className="mt-5 mb-3">
        <div className="container">
          <div className="d-flex">
            <h2 className="weight600">Your Account</h2>
            <button
              type="submit"
              className="btn btn-primary my-color logoutbtn"
              // onclick="return confirm('Are you sure you want to logout?');"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                handlelogout();
              }}
            >
              Logout
            </button>
          </div>

          <div className="row mt-3">
            <div className="col-md-4">
              <Link to="/orderhistory">
                <div className="myorderlistingdetails">
                  <div>
                    <img src={box} />
                  </div>
                  <div>
                    <h4 className="text-black weight600 yourordertext">Your Orders</h4>
                    <p className="fs20 clrgray">Track,return or buy anything</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4">
              <Link to="/">
                <div className="myorderlistingdetails">
                  <div>
                    <img src={lock} />
                  </div>
                  <div>
                    <h4 className="text-black weight600 yourordertext">Login & Security</h4>
                    <p className="fs20 clrgray">
                      Edit login,name and mobile number
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4">
              <Link to="/billing-address">
                <div className="myorderlistingdetails">
                  <div>
                    <img src={location} />
                  </div>
                  <div>
                    <h4 className="text-black weight600 yourordertext">Your Addresses</h4>
                    <p className="fs20 clrgray">Edit Addresses for order</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="row mt-3 myorderfeedbackdiv">
            <div className="col-md-12">
              <label for="address">Feedback</label>
              <textarea
                className="form-control"
                placeholder="Enter Your Feedback"
                value={feedback}
                onChange={(t) => {
                  setfeedback(t.target.value);
                }}
              ></textarea>
              <div className="mt-3">
                <input
                  class="btn btn-success btn-lg float-right"
                  type="submit"
                  name="submit"
                  onClick={() => {handlesubmitfeedback()}}
                />
              </div>
            </div>
          </div>

          <div className="row mt-3 myorderfeedbackdiv">
            <h3 style={{ color: "#000" }}>Feedback List</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Feedback</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  feedbacksdata?.length > 0 ?
                  feedbacksdata?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{width: '20%'}}>{index + 1}</td>
                        <td style={{width: '60%'}}>{item?.content}</td>
                        <td style={{width: '20%'}}>{item?.created_at}</td>
                      </tr>
                    )
                  }) : null
                }
              </tbody>
            </table>
          </div>


        </div>
      </section>
    </>
  );
}

export default Myorder