import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { Link } from "react-router-dom";
import Overlay from '../components/Overlay';
import Spinner from "react-activity/dist/Spinner";

const UpcomingRelease = () => {
    const { link } = useParams();
    const [showloding, setshowloding] = useState(false);
    const [offersdata, setoffersdata] = useState([]);
    // console.log('offer link >>', link);

    const navigate = useNavigate();

    const fetchdashboarddata = async(id) => {
        setshowloding(true);
        var data = {
            name: 'Upcoming'
        };
        const headers = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          let resp = await axios
          .post(apiUrl + "hometabproducts",data, headers)
          .then((res) => {
            // console.log('res >>', res.data)
            setoffersdata(res.data.data);
           })
           .catch((err) => {
            alert(err.message);
          });
        setshowloding(false);
    }

    const handlenavigation = (data) => {
      console.log('data >>', data)
      // navigate('/productdetails', {state: {data: data}});
      navigate('/entertainment/'+data?.slug)
    }

    useEffect(() => {
        fetchdashboarddata(link);
      }, [link])
    
    return (
      <section className="pd50">
        <div className="container">
          <div className="row">
            {
              offersdata?.length > 0 ?
              offersdata?.map((item, index) => {
                // console.log('item >>', item)
                var proimage = null;
                if(item?.image1) {
                  proimage = item?.image1;
                } else if(item?.image2) {
                  proimage = item?.image2;
                } else if(item?.image3) {
                  proimage = item?.image3;
                } else if(item?.image4) {
                  proimage = item?.image4;
                } else {
                  proimage = null;
                }

                //percent
                var discountpercent = item?.discount_percent;
                var price = item?.price;
                if(discountpercent > 0) {
                 var discountprice = price * discountpercent / 100;
                } else {
                  var discountprice = 0;
                }
                return (
                  <div className="col-md-3" key={index}>
                  <div style={{cursor: 'pointer'}} onClick={() => {handlenavigation(item)}}>
                    <div className="card-img">
                      <div className="align-items-center d-flex justify-content-center img-border">
                        <img class="img-fluid" src={assetsUrl + 'products/'+proimage} />
                      </div>
                      <div className="card-img-text img-text">
                        <p class="price listing_title" style={{color: '#000'}}>
                          <span class="artist_name">{item?.composer}</span><br />
                          <span class="product_name">{item.name}</span><br />
                          <span class="formate_name">{item?.formate} </span>&nbsp;
                          <span class="product-price">₹{item?.price - parseInt(discountprice)}</span> 
                          {
                            discountpercent > 0 ? 
                            <span class="clr">
                            <s>₹{item?.price}</s>
                          </span> : null
                          }
                         
                          {
                            discountpercent > 0 ?
                            <span className="offer"><br />{discountpercent}% OFF</span> : null
                          }
                          
                        </p>
                        <div class="price-detail price-box">
                  {
                    discountprice > 0 ?
                    <span class="dicount-amount">Save ₹{parseInt(discountprice)}</span> :
                    null
                  }
                                

                                  {
                                    item?.stock < 1 ?
                                    <span style={{backgroundColor: '#e61513', color: '#fff', padding: 5,
                                      position: 'absolute', bottom: -212, right: -216, fontSize: 15
                                    }} >SOLD OUT</span> : null
                                  }
                                  
                                  </div>
                      </div>
                    </div>
                  </div>
                </div>
                  
                )
              })
              : 
            
                showloding ?
                <Spinner color='#f8a41a' size={30} className='spinnerCenteroffer' /> : 
                <p class="price" style={{color: '#000'}}>
                <span class="artist_name">No Products added in this offer</span>
              </p>
            
            }
            
          </div>
        </div>
      </section>
    );
}

export default UpcomingRelease