import Navbar  from './components/Navbar';
import './App.css';
import Footer from './components/Footer';
import Social from "./components/Social";
import { Outlet } from 'react-router-dom';
function App() {
  return (
     <>
   
       <Navbar />
       <Outlet />
       <Social />
       <Footer />
     </>
  );
}

export default App;
