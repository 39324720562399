import React from "react";

const AdminFooter = () => {
    return (
        <footer className="adminmain-footer">
            <p className="footertext">Copyright © 2024 Blisstainment. All rights reserved.</p>
        </footer>
    )
}

export default AdminFooter