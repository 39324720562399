import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <section className="text-center brand-color pd50">
        <div className="container">
          <div className="about-page">
            <h2 className="uppercase text-black">Vinyl Manufacturer</h2>
            <p className="weight600">
              Our strategic tie ups with various manufacturers nationally and
              internationally enables us to provide the best manufacturing
              services for CDs and Vinyls at the
            </p>
            <p className="weight600">
              best possible rates. We also provide end to end solutions in terms
              of designing and packaging. Just get your audio masters to us and
              we will ensure you leave with a great product.
            </p>
          </div>
        </div>
      </section>

      <section className="text-center black-color text-white pd50">
        <div className="container">
          <div className="about-page">
            <h2 className="uppercase text-white">VINYL DISTRIBUTOR </h2>
            <p className="weight600">
              With our distribution set up and tie ups with various logistics
              partners in India and abroad, we ensure your product reaches every
              destination possible.
            </p>
            <p className="weight600">
              This includes e-commerce partners like Amazon India and Flipkart
              who have a fantastic coverage within India.
            </p>
          </div>
        </div>
      </section>

      <section className="text-center brand-color pd50">
        <div className="container">
          <div className="about-page">
            <h2 className="uppercase text-black">CREATIVE SOLUTIONS</h2>
            <p className="weight600">
              At Blisstainment we offer creative solutions for brands and
              services. We have the experience and the resources to deliver
              marketing communication
            </p>
            <p className="weight600">
              across mediums - traditional and new-age. Call us to know more.
            </p>
          </div>
        </div>
      </section>

      <section className="text-center black-color text-white pd50 relative">
        <div className="container">
          <div className="about-page">
            <h2 className="uppercase text-white">EVENTS</h2>
            <p className="weight600">
              If your company, brand or service requires an outreach program in
              terms of a sales promotion or an exhibition, Blisstainment is
              completely equipped
            </p>
            <p className="weight600">
              to provide you with the required strategic and operational inputs
              to make it a success. We will be happy to share more details.
            </p>
          </div>
        </div>
        <div className="home_btn_div">
          <Link to="contact-us" className="home_btn">
            Contact
          </Link>
        </div>
      </section>
    </>
  );
};

export default Services;
