import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faBagShopping, faCircleArrowRight, faChartSimple, faCartShopping, faCartPlus, faIndianRupeeSign
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [showloding, setshowloding] = useState(false);
  const [productcount, setproductcount] = useState(0);
  const [allordercount, setallorderscount] = useState(0);
  const [neworders, setneworders] = useState(0);
  const [amount, setamount] = useState(0);
  const [users, setusers] = useState(0);

  const fetchdashboardcounts = async() => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "getdashboardcounts", headers)
      .then((res) => {
        if (res.data.status == "success") {
          console.log('getdashboardcounts >>', res.data);
          setallorderscount(res?.data?.allorders);
          setproductcount(res?.data?.productcount);
          setamount(res?.data?.totalamount);
          setusers(res?.data?.userscount);
          setneworders(res?.data?.neworders);
          // var prevdata = res.data.data;
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
      setshowloding(false);
  }
    useEffect(() => {
      fetchdashboardcounts()
    }, [])
    return (
      <div className="adminmainpagelayout">
        <div className="row mt-4">
          <div className="col-md-3">
            <div className="dashboardbox">
              <div className="d-flex" style={{ flexDirection: "row", paddingLeft: 10, paddingRight: 10 }}>
                <div className="">
                  <p className="dashboardbox-count">{productcount}</p>
                  <p className="dashboardbox-products">Products</p>
                </div>
                <FontAwesomeIcon
                  icon={faBagShopping}
                  className="dashboardbox-icon"
                />
              </div>
              <div className="dashboardbox-darkbg">
                <Link to={'/admin/product'} className="dashboardbox-moreinfo">More Info</Link>
                <FontAwesomeIcon
                  icon={faCircleArrowRight}
                  className="dashboardbox-icon1"
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboardbox" style={{backgroundColor: '#28a745'}}>
              <div className="d-flex" style={{ flexDirection: "row", paddingLeft: 10, paddingRight: 10 }}>
                <div className="">
                  <p className="dashboardbox-count">{users}</p>
                  <p className="dashboardbox-products">Users</p>
                </div>
                <FontAwesomeIcon
                  icon={faChartSimple}
                  className="dashboardbox-icon"
                />
              </div>
              <div className="dashboardbox-darkbg">
              <Link to={'/admin/users'} className="dashboardbox-moreinfo">More Info</Link>
                <FontAwesomeIcon
                  icon={faCircleArrowRight}
                  className="dashboardbox-icon1"
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboardbox" style={{backgroundColor: '#ffc107'}}>
              <div className="d-flex" style={{ flexDirection: "row", paddingLeft: 10, paddingRight: 10 }}>
                <div className="">
                  <p className="dashboardbox-count" style={{color: 'rgb(31, 45, 61)'}}>{neworders}</p>
                  <p className="dashboardbox-products" style={{color: 'rgb(31, 45, 61)'}}>New Orders</p>
                </div>
                <FontAwesomeIcon
                  icon={faCartPlus}
                  className="dashboardbox-icon"
                />
              </div>
              <div className="dashboardbox-darkbg">
                <Link to={'/admin/all-orders'} className="dashboardbox-moreinfo" style={{color: 'rgb(31, 45, 61)'}}>More Info</Link>
                <FontAwesomeIcon
                  icon={faCircleArrowRight}
                  className="dashboardbox-icon1"
                  style={{color: 'rgb(31, 45, 61)'}}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboardbox" style={{backgroundColor: '#dc3545'}}>
              <div className="d-flex" style={{ flexDirection: "row", paddingLeft: 10, paddingRight: 10 }}>
                <div className="">
                  <p className="dashboardbox-count">{allordercount}</p>
                  <p className="dashboardbox-products">Orders</p>
                </div>
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className="dashboardbox-icon"
                />
              </div>
              <div className="dashboardbox-darkbg">
                <Link to={'/admin/all-orders'} className="dashboardbox-moreinfo">More Info</Link>
                <FontAwesomeIcon
                  icon={faCircleArrowRight}
                  className="dashboardbox-icon1"
                />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="dashboardbox" style={{backgroundColor: '#ffc107'}}>
              <div className="d-flex" style={{ flexDirection: "row", paddingLeft: 10, paddingRight: 10 }}>
                <div className="">
                  <p className="dashboardbox-count" style={{color: 'rgb(31, 45, 61)'}}>{amount}</p>
                  <p className="dashboardbox-products" style={{color: 'rgb(31, 45, 61)'}}>Order Value</p>
                </div>
                <FontAwesomeIcon
                  icon={faIndianRupeeSign}
                  className="dashboardbox-icon"
                />
              </div>
              <div className="dashboardbox-darkbg">
              <Link to={'/admin/all-orders'} className="dashboardbox-moreinfo" style={{color: 'rgb(31, 45, 61)'}}>More Info</Link>
                <FontAwesomeIcon
                  icon={faCircleArrowRight}
                  className="dashboardbox-icon1"
                  style={{color: 'rgb(31, 45, 61)'}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default AdminDashboard