import { Link } from "react-router-dom"
import { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { useParams, useNavigate } from 'react-router-dom';
import Overlay from '../components/Overlay';
import Spinner from "react-activity/dist/Spinner";

const Movies = () => {
  const [subcategorydata, setsubcategorydata] = useState([]);
  const [showloding, setshowloding] = useState(false);

  const navigate = useNavigate();

  const fetchdashboarddata = async() => {
    setshowloding(true);
    const data = {
      subcategory: 3
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
          .post(apiUrl + "getsubcategorybycat",data, headers)
          .then((res) => {
            // console.log('getdashboarddata res >>', res.data)
            if(res?.data?.status == 'success') {
              console.log('data >>', res?.data);
              setsubcategorydata(res?.data?.data);
            }
              
            
          })
          .catch((err) => {
            alert(err.message);
          });
    setshowloding(false);
    
  }
  useEffect(() => {
    fetchdashboarddata();
  }, [])
  return (
    <section className="pd50">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          {subcategorydata?.length > 0 ? (
            subcategorydata?.map((item, index) => {
              return (
                <div className="col-md-3">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/sub-category/" + item?.slug + "/Movie");
                    }}
                  >
                    <div className="card-img">
                      <div className="imgborder">
                        <img
                          src={assetsUrl + "sub-categories/" + item?.image}
                        />
                      </div>
                      <div className="card-img-text">
                        <h2 className="img-text music_title">{item.name}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : 
          showloding ?
          <Spinner color='#f8a41a' size={30} className='spinnerCenteroffer' /> :
            <h2 className="img-text">No Subcategories for this category</h2>
            }
        </div>
      </div>
    </section>
  );
}

export default Movies