import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { assetsUrl, apiUrl } from "../config/config";
import Overlay from '../components/Overlay';
import { Button, Modal } from "react-bootstrap";
import axios from "axios";

const Cart = () => {
  const customerdata = useSelector((store) => store.userReducer.customerdata);
  const cartdata = useSelector((store) => store.userReducer.cartdata);
  console.log('cart cartdata >>', cartdata);
  const [cart, setcart] = useState([]);
  const [totalprice, settotalprice] = useState(0);
  const [allcouponsdata, setallcouponsdata] = useState([]);
  const [couponcode, setcouponcode] = useState("");
  const [coupondiscountamount, setcoupondiscountamount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const [showmodal, setshowmodal] = useState(false);

  const handlechangequantity = (index, quantity) => {
    if(quantity < 1) {

    } else {
      console.log('index >>', index);
    console.log('quantity >>', quantity);
    var prevdata = cart;
    prevdata[index].quantity = quantity;
    dispatch({ type: "cartdata", payload: prevdata });
    }
    
  }

  

  const handleremovecartitem = (index) => {
    var prevdata = cart;
    prevdata.splice(index, 1);
    dispatch({ type: "cartdata", payload: prevdata });
  }

  const handleplaceorder = () => {
    // console.log('customerdata >>', customerdata);
    if(customerdata && Object.keys(customerdata)?.length > 0) {
      let passdata = { coupondiscountamount: coupondiscountamount };
      navigate('/checkout', {state: passdata});
    } else {
      // console.log('not logged in');
      let passdata = { fromscreen: 'cart' };
      navigate('/login', {state: passdata});
    }
  }

  const applycoupon = async() => {
    var data = {
      code: couponcode
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "applycoupon",data, headers)
      .then((res) => {
          console.log('res >>', res?.data)
          if(res?.data?.data?.length > 0) {
            var percent = res?.data?.data[0]?.discountValue;
            var currenttotal = totalprice;
            var discountamount = parseInt((percent * currenttotal) / 100);
            setcoupondiscountamount(discountamount)
          } else {
            setcoupondiscountamount(0)
          }
          
      }).catch((err) => {
        alert(err.message);
      });
  }

  const fetchallgeneralcoupons = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "getallcartpromotion", headers)
      .then((res) => {
          console.log('res >>', res?.data)
          if(res?.data?.allcoupons?.length > 0) {
            setallcouponsdata(res?.data?.allcoupons);
          } else {
            setallcouponsdata([]);
          }
      }).catch((err) => {
        alert(err.message);
      });
  }

  useEffect(() => {
    fetchallgeneralcoupons();
  }, [])

  useEffect(() => {
    // setcart(cartdata);
    var newarrobj = [];
    for(var i = 0; i < cartdata?.length > 0; i++) {
      var cartobj = {
        id: cartdata[i]?.id,
        name: cartdata[i]?.name,
        cartimage: cartdata[i]?.cartimage,
        price: cartdata[i]?.price,
        quantity: cartdata[i]?.quantity
      }
      newarrobj.push(cartobj)
    }
    setcart(newarrobj);
    // Calculate Total price
    var totalPrice = newarrobj.reduce((acc, item) => {
      return acc + item.price * parseInt(item.quantity);
    }, 0);
    settotalprice(totalPrice);
  }, [cartdata])
  return (
    <>
      <section className="mt-5 mb-3">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
            <h5 className="mb-4 carthead">Cart ({cart?.length} item)</h5>
            {
              cart?.length > 0 ?
              cart?.map((item, index) => {
                return (
                  <div className="productcartdetails">

                <div className="card-body dflex dblock">
                  <div className="viewimg">
                    <img
                      src={assetsUrl+"products/"+item?.cartimage}
                      className="img-fluid w-100"
                    />
                  </div>

                  <div className="dflex space-between w100">
                    <div className="cartcontent">
                      <h5 className="cart_title">{item?.name}</h5>
                      <p className="small uppercase">PRICE - ₹{item?.price}</p>
                      <p className="small uppercase">SHIPPING - 0</p>

                      <div style={{cursor: 'pointer'}} onClick={() => {handleremovecartitem(index)}}>
                        <p className="small mt-3" style={{color: 'red'}}>
                          <i className="fa fa-trash mr-1" style={{fontSize: 20}}></i>
                         <span style={{marginLeft: 7, fontSize: 17}}>Remove Item</span>
                        </p>
                      </div>
                    </div>
                    <div className="text-right">
                      <input
                      type="number"
                        value={item?.quantity}
                        defaultValue={item?.quantity}
                        onChange={(t) => {handlechangequantity(index, t.target.value)}}
                        className="quantitynumber"
                      />
                      <p className="small mb-2">(Note, 1 piece)</p>
                      <p className="">Total ₹{item?.price * item?.quantity} </p>
                    </div>
                  </div>
                </div>
              </div>
                )
              }) : null
            }
              
            </div>

            <div className="col-md-4">
            <h5 className="mb-4">&nbsp; </h5>
              <div className="productcartdetails">
                <h5 className="mb-4">Price Details</h5>
                <div className="dflex space-between mb-3">
                  <p className="fs16 weight600">Price ({cart?.length} items)</p>
                  <p className="fs16 weight600">₹{totalprice}</p>
                </div>

                <div className="dflex space-between">
                  <p className="fs16 weight600">Shipping Charges</p>
                  <p className="fs16 weight600">₹0</p>
                </div>

                <div className="space-between mt-3">
                  <div className="d-flex" style={{justifyContent: 'space-between'}}>
                    <p className="fs16 weight600 mb-2">Apply Promocode:</p>
                    {
                      allcouponsdata?.length > 0 ?
                      <div className="" style={{cursor: 'pointer'}} onClick={() => {setshowmodal(true)}}>
                      <p className="fs16 weight600 mb-2" style={{color: '#17a2b8'}}>All Offers</p>
                    </div> : 
                    null
                    }
                    
                  </div>
                  <div className="d-flex">
                  <input
                    type="text"
                    value={couponcode}
                    onChange={(t) => {
                      setcouponcode(t.target.value)
                    }}
                    className="form-control cartinput"
                    placeholder="Apply Promocode"
                  />
                  {
                    couponcode?.length > 0 ?
                    <button className="cta-apply cta-applycoupon ajustwidthcart" onClick={() => {
                      applycoupon()
                     }}>Apply</button> : 
                     <button className="cta-apply ajustwidthcart">Apply</button>
                  }
                  </div>
                  
                  
                  {
                    coupondiscountamount > 0 ?
                    <span className="text-green">Coupon Code Not Applied</span> : null
                  }
                  {/* <span className="text-green">Coupon Code Not Applied</span> */}
                </div>

                {/* <div className="mt-3">
                  {
                    couponcode?.length > 0 ?
                    <button className="cta-apply cta-applycoupon" onClick={() => {
                      applycoupon()
                     }}>Apply</button> : 
                     <button className="cta-apply">Apply</button>
                  }
                   
                </div> */}
                
                {
                  coupondiscountamount > 0 ?
                  <div className="dflex space-between mt-3">
                  <p className="fs16 weight600">Coupon Discount</p>
                  <p className="fs16 weight600">- ₹{coupondiscountamount}</p>

                </div> : null
                }
               

                <div className="dflex space-between mt-2">
                  <p className="fs16 weight600">Total Amount</p>
                  <p className="fs16 weight600">₹{totalprice - coupondiscountamount}</p>

                </div>

                <div className="mt-3">
                   <button className="cta-danger" onClick={() => {
                    handleplaceorder();
                   }}>Place Order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Coupons modal */}
    <Modal
              show={showmodal}
              onHide={() => setshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 19}}>Coupons</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                  {
                    allcouponsdata?.length > 0 ?
                    allcouponsdata?.map((item, index) => {
                      console.log('item >>', item)
                      return (
                        <div className="couponsrow">
                          <p className="coupontitle">{item?.promotionName}</p>
                          <p className="couponcode">Use Coupon code : <b>{item?.promocode}</b></p>
                          <p className="coupondescription">
                          {item?.description}
                          </p>
                        </div>
                      );
                    })
                    
                     :
                    <p className="fs16 weight600">No Offers</p>
                  }
                </div>
              </Modal.Body>
            </Modal>
    </>
  );
};

export default Cart;
