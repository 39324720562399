import React, { useEffect, useState } from "react";
import box from '../images/box.png';
import lock from '../images/lock.png';
import location from '../images/location.png';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { assetsUrl, apiUrl } from "../config/config";
import axios from "axios";
import Overlay from '../components/Overlay';
import Swal from 'sweetalert2';

const BillingAddress = () => {
  const customerdata = useSelector((store) => store.userReducer.customerdata);
//   console.log('Checkout cartdata >>', cartdata);
  const navigate = useNavigate();

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [statesdata, setstatesdata] = useState([]);
  const [citiesdata, setcitiesdata] = useState([]);

  const dispatch = useDispatch();

  // const fetchcustomeraddress = () => {
  //   console.log('fetchcustomeraddress >>');
  // }

  const fetchcustomestates = async() => {
    var data = {
      countrycode : 101,
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customerdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "getallstates",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          if(res?.data?.data?.length > 0) {
            setstatesdata(res?.data?.data);
          } else {
            setstatesdata([]);
          }
          
      } else {

      }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  const fetchcustomecities = async(id) => {   
    var data = {
      statecode : id,
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customerdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "getallcities",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          if(res?.data?.data?.length > 0) {
            setcitiesdata(res?.data?.data);
          } else {
            setcitiesdata([]);
          }
          
      } else {

      }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  const removeaddressfunc = async() => {
    const result = window.confirm("Are you sure you want to remove address");
    if(result) {
      var data = {
        name: name,
        mobile: mobile,
        email: email,
        address: null,
        state: null,
        city: null,
        pincode: null,
      }
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + customerdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "updatebillingaddress",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
          alert('Updated Successfully!!');
            dispatch({ type: "customerdata", payload: res.data.data });
            fetchdatafromredux();
            
        } else {
  
        }
    })
        .catch((err) => {
          alert(err.message);
        });
    }
  
  }

  const submitaddaddress = async() => {
    if(address) {
      var data = {
        name: name,
        mobile: mobile,
        email: email,
        address: address,
        state: state,
        city: city,
        pincode: pincode,
      }
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + customerdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "updatebillingaddress",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
          // alert('Updated Successfully!!');
          Swal.fire({
            // title: 'Updated Successfully!',
            text: "Updated Successfully!",
            icon: 'success',
            customClass: {
              icon: 'custom-swal-icon'
            },
            width: '400px',
            confirmButtonText: 'Ok'
          });
            dispatch({ type: "customerdata", payload: res.data.data });
            fetchdatafromredux();
            
        } else {
  
        }
    })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      Swal.fire({
        // title: 'Ordered Successfully!',
        text: "Address is mandatory",
        icon: 'warning',
        customClass: {
          icon: 'custom-swal-icon'
        },
        width: '400px',
        confirmButtonText: 'Ok'
      });
    }
    
  }

  useEffect(() => {
    if(state) {
      console.log('state >>', state);
      fetchcustomecities(state);
    }
  }, [state])

  const fetchdatafromredux = () => {
    console.log('customerdata >>', customerdata);
      //   fetchdata();
      setname(customerdata?.name);
      setmobile(customerdata?.mobile);
      setemail(customerdata?.email);
      setaddress("");
      setstate("");
      setcity("");
      setpincode("");
      // setaddress(customerdata?.address);
      // setstate(customerdata?.state );
      // setcity(customerdata?.city);
      // setpincode(customerdata?.pincode);
  }

  const editclickfunc = () => {
  setname(customerdata?.name);
      setmobile(customerdata?.mobile);
      setemail(customerdata?.email);
      setaddress(customerdata?.address);
      setstate(customerdata?.state );
      setcity(customerdata?.city);
      setpincode(customerdata?.pincode);
  }



  useEffect(() => {
    if (customerdata && Object.keys(customerdata).length > 0) { 
    fetchdatafromredux();
      
      fetchcustomestates();
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <section className="mt-5 mb-3">
        <div className="container billingaddressformsection">
          {/* <h2 className="weight600">Billing Address</h2> */}
          <div className="row">
            <div className="col-md-4">
              {customerdata?.address && customerdata?.state ?
                <div className="address-store-panel" id="remove-address">
                <h4>{customerdata?.name}</h4>
                <p>{customerdata?.address}</p>
                <p>{customerdata?.state} , {customerdata?.city} , {customerdata?.pincode}</p>
                {/* <a href="#address">Edit</a> |{" "} */}
                <div className="d-flex">
                 <div  id="rem" style={{cursor: 'pointer', color: 'rgb(13,110,253)'}} onClick={() => {editclickfunc()}} >
                  Edit 
                </div>
                <p style={{marginLeft: 8, marginRight: 8, fontSize: 15}}>|</p>
                
                <div  id="rem" style={{cursor: 'pointer', color: 'red'}} onClick={() => {removeaddressfunc()}} >
                  Remove
                </div>
                </div>
               
              </div> : 
              <div className="address-store-panel" id="remove-address">
                <p>No address added.</p>
              </div>
            }
              
            </div>

            <div className="col-md-8 mx-auto">
              <div className="card border-secondary">
                <div className="card-header">
                  <h3 className="mb-0 my-2" style={{ color: "#000" }}>
                    Your Details
                  </h3>
                </div>
                <div className="card-body">
                  <div className="col-md-12 order-md-1">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label for="name">Name</label>
                          <input
                            value={name}
                            onChange={(t) => {
                              setname(t.target.value);
                            }}
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label for="mobile">Mobile</label>
                          <input
                            type="number"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            value={mobile}
                            onChange={(t) => {
                              setmobile(t.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label for="email">Email </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={email}
                            onChange={(t) => {
                              setemail(t.target.value);
                            }}
                        />
                      </div>

                      <div className="mb-3">
                        <label for="address">Address</label>
                        <textarea
                          className="form-control"
                          id="address"
                          name="address"
                          value={address}
                            onChange={(t) => {
                              setaddress(t.target.value);
                            }}
                        >
                        </textarea>
                      </div>
                      <div className="row">
                        <div className="col-md-5 mb-3">
                          <label for="country">State</label>
                          <select
                            className="custom-select d-block"
                            id="state"
                            name="state"
                            value={state}
                            onChange={(t) => {
                              setstate(t.target.value);
                            }}
                          >
                            <option value="" selected disabled>Choose...</option>
                           {
                            statesdata?.length > 0 ?
                            statesdata?.map((item, index) => {
                              return (
                                <option key={index} value={item?.name}>{item.name}</option>
                              )
                            }) : null
                           }
                          </select>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label for="state">City</label>
                          <select
                            className="custom-select d-block "
                            id="city"
                            name="city"
                            value={city}
                            onChange={(t) => {setcity(t.target.value)}}
                          >
                            <option value="" selected disabled>Choose...</option>
                            {
                              citiesdata?.length > 0 ?
                              citiesdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item?.name}>{item.name}</option>
                                )
                              }) : null
                            }
                          </select>
                        </div>

                        <div className="col-md-3 mb-3">
                          <label for="city">Pin Code</label>
                          <input
                            type="number"
                            className="form-control"
                            id="pincode"
                            name="pincode"
                            value={pincode}
                            onChange={(t) => {setpincode(t.target.value)}}
                            style={{ padding: 10 }}
                          />
                        </div>

                        <div className="col-md-3 mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary my-color"
                            // onclick="return confirm('Are you sure you want to update?');"
                            onClick={() => {submitaddaddress()}}
                          >
                            Add Address
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BillingAddress;
