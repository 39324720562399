import React, {useState} from "react";
import './../admin.css';
import { apiUrl } from "../../config/config";
import axios from "axios";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";

const AdminLogin = () => {
  const [emailaddress, setemailaddress] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [password, setpassword] = useState("");
  const [isFocused1, setIsFocused1] = useState(false);
  const [isClicked1, setIsClicked1] = useState(false);

  const [showloding, setshowloding] = useState(false);

  const userdata = useSelector((store) => store.userReducer);

  let navigate = useNavigate();
  const dispatch = useDispatch(); 

  const handleInputChange = (event) => {
    setemailaddress(event.target.value);
  };

  const handleInputChange1 = (event) => {
    setpassword(event.target.value);
  };

  const handleInputBlur = () => {
    setIsClicked(true);
  };

  const handleInputBlur1 = () => {
    setIsClicked1(true);
  };

  const handlesubmit = async() => {
    setshowloding(true);
    var data = {
      'email' : emailaddress,
      'password': password
  }
  const headers = {
      headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
      }
  }
  let resp = await axios.post(apiUrl + 'adminlogin',data, headers).then((res) => {
      if(res.data.status == 'success') {
        dispatch({ type: "userdata", payload: res.data.data })
        navigate('/admin/home');
        setshowloding(false);
      } else {
        Swal.fire({
          title: 'Failed',
          text: res.data.message,
          icon: 'error',
          customClass: {
            icon: 'custom-swal-icon' // Apply the custom class
          },
          width: '400px',
          confirmButtonText: 'Ok'
        });
        setshowloding(false);
      }
  } ).catch((err) => {
      alert(err.message)
      setshowloding(false);
  })
  setshowloding(false);
  }

    return (
      <div className="adminloginbg">
        <div className="logincontentdiv">
          <p className="blisstainment-head">Blisstainment</p>
          <div className="loginbox">
            <p className="signintext">Sign in to Blisstainment</p>
            <div className="loginformdiv">
              <input
                type="email"
                className="form-control admininput"
                value={emailaddress}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                placeholder="Enter Email Address"
              />
              {isClicked && emailaddress.trim() === "" && (
                <div className="errormsg">Email cannot be empty</div>
              )}
              <input
                type="password"
                className="form-control admininput"
                value={password}
                onChange={handleInputChange1}
                onBlur={handleInputBlur1}
                style={{marginTop: 20}}
                placeholder="Enter Password"
              />
              {isClicked1 && password.trim() === "" && (
                <div className="errormsg">Password cannot be empty</div>
              )}
              <div className="btn adminloginbtn" onClick={emailaddress && password ? handlesubmit : null} style={emailaddress && password ? {opacity: 1} : { opacity: 0.5}}>
                <p>{showloding ?
                <Spinner color='#fff' size={15} className='spinnerCenter' /> : 'Sign In'
            }</p>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
}

export default AdminLogin