
//Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import {Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { assetsUrl } from '../config/config';
import Overlay from '../components/Overlay';

export default function Carousel({data}) {
  const pagination = {
    clickable: true,
  };

  return (
    <>
      <div className="home">
        {data?.length > 0 ? (
          <Swiper
            loop={true}
            pagination={pagination}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {
              data?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Link to={'/offers/'+item?.link}>
                      <img src={assetsUrl+'sliders/'+item?.image} />
                    </Link>
                  </SwiperSlide>
                );
              })
            }
          </Swiper>
        ) : null}
      </div>
    </>
  );
}
