import {Link} from 'react-router-dom';

function About() {
  return (
    <>
        <section className="text-center brand-color pd50 relative">
        <div className="container">
          <div className='about-page'>
          <h2 className="uppercase text-black">ABOUT BLISSTAINMENT</h2>
          <p className="weight600">
          The Indian music market went digital with the rest of the world. But not surprisingly, even today, 
          there is a dearth of good quality physical music in India.</p>
          <p className="weight600">
          The ever-rising demand for CDs and Vinyls is a reality in the Indian market, the demand-supply equation 
          is lopsided in favour of demand and the real fans and connoisseurs are feeling the pinch. 
          At Blisstainment - a brand owned by BASKING SHARK PRIVATE LIMITED, we understand this ne
          </p>

          <p className="weight600">
          Blisstainment was formed out of the love for music. With over four decades of combined experience 
          in the physical music and movie distribution industry;
          </p>


          <p className="weight600">
          we offer you the ease of doing business in India and access to music
           lovers across the breadth and width of the Indian subcontinent and world over.
          </p>


          <p className="weight600">
          Over the last few years we have fulfilled the demands of various music
           lovers looking for their favourite music, artist or preferred format.
          </p>

          <p className="weight600">
          For any query kindly get in touch at <a href="mailto:info@blisttainment.com" style={{color:'#000'}}>info@blisttainment.com</a>
          </p>
          </div>
        </div>
        <div className="home_btn_div">
           <Link to='contact-us' className="home_btn">Contact</Link>
          
        </div>
      </section>

    </>
  )
}

export default About