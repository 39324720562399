
import '../App.css';
import { Outlet } from 'react-router-dom';
const Layouts = () => {
  return (
    <div className='adminportal'>
      <Outlet />
    </div>
  )
}

export default Layouts