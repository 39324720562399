import React, {useEffect, useState, useMemo, useRef} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from 'moment';
import Spinner from "react-activity/dist/Spinner";
import Select from 'react-select';
import "react-activity/dist/Spinner.css";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Users = () => {
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [selectedobj, setselectedobj] = useState({})


    const selectedobjRef = useRef({});
    selectedobjRef.current = selectedobj;



    const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);


    const clickhandle = () =>  {
        // console.log('Mission Launched')
      }

      const editlocation = async(obj) => {
        console.log('obj >>', obj);
        setselectedobj(obj)
        seteditshowmodal(true);
      }

      const defaultColDef = {
        // flex: 1,
        resizable: true,
        sortable: true 
      };


    const fetchallcourses = async () => {
        setshowloding(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .get(apiUrl + "getallusers", headers)
          .then((res) => {
            if (res.data.status == "success") {
              // console.log('getallcategories >>', res.data);
              var prevdata = res.data.data;
              if(prevdata?.length > 0) {
                var columnsarr = [];
                var hidecolumns = ['id', 'created_at', 'updated_at', 'sort', 'image', 'category_id', 'startDate', 'endDate', 'address', 'state' , 'pincode', 'date'];
                var hidefilters = [];
                var columns = Object.keys(prevdata[0]);
                console.log('columns  >>>', columns ); 
                for(var i = 0; i < columns?.length;i++) {
                  if(!hidecolumns.includes(columns[i])) {
                    var obj = {};
                    obj.field = columns[i];
                    if (!hidefilters.includes(columns[i])) {
                        obj.filter = true;
                        obj.filterParams = {
                          filterOptions: ["contains"],
                          maxNumConditions: 1,
                        };
                      }
                    console.log('obj >>', obj)
                    columnsarr.push(obj);
                  }
                }

                //Image
                if(columns.includes('image')) {
                  var imageobj = 
              {
                field: "Image",
                headerName: "Image",
                cellRenderer: (props) => {
                  // console.log('image props >>', props);
                  return (
                    <Link
                    to={'https://www.blisstainment.com/assets/users/pictures/'+props?.data?.image}
                    target="_blank"
                    style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </Link>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(imageobj);
                }

                //created_at
            //     if(columns.includes('created_at')) {
            //       var craetddatobj = 
            //   {
            //     field: "Date",
            //     headerName: "Date",
            //     cellRenderer: (props) => {
            //       // console.log('date props >>', props);
            //       return (
            //              <div>
            //               <p className="created_at_value">{moment(props?.data?.created_at).format('DD MMMM YYYY HH:mm')}</p>
            //             </div>
            //       )
            //     },
            //     cellRendererParams: {
            //       onClick: clickhandle,
            //     },
            //   }
            //   columnsarr.push(craetddatobj);
            //     }
              
                //edit
              var editobj = 
              {
                field: "Details",
                headerName: "Details",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
                //delete
                // var deleteobj = 
                // {
                //   field: "delete",
                //   headerName: "Delete",
                //   cellRenderer: (props) => {
                    
                //     return (
                //       <div
                //             onClick={() => {
                //               deletecourse(props?.data?.id, props?.data?.name);
                //               // console.log('props >>', props?.data);
                //             }}
                //             style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                //           >
                //             <FontAwesomeIcon
                //               icon={faTrash}
                //               className="sidebaricons"
                //               style={{ color: "red" }}
                //             />
                //           </div>
                //     )
                //   },
                //   cellRendererParams: {
                //     onClick: clickhandle,
                //   },
                // }
                // columnsarr.push(deleteobj);
                setColDefs(columnsarr);
                setRowData(prevdata);
              }
              
              // setcoursesdata(res.data.data);
            } else {
              // alert(res.data.message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
          setshowloding(false);
      };

    useEffect(() => {
        if (userdata && Object.keys(userdata).length > 0) {
          //   fetchdata();
          fetchallcourses();
        //   fetchallproducts();
        } else {
          navigate("/newadminlogin");
        }
      }, []);
    return (
      <div className="adminmainpagelayout">
        <div className="admin-categorymaindiv">
          <p className="admin-page-title">All Users</p>
        </div>

        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: 500, marginTop: 15 }}
        >
          <AgGridReact
            // modules={AllModules}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            rowSelection={"multiple"}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={paginationPageSizeSelector}
            // onGridReady={onGridReady}
          />
        </div>

        {/*View modal */}
        <Modal
          show={editshowmodal}
          onHide={() => seteditshowmodal(false)}
          size="md"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
              {Object.keys(selectedobjRef?.current).length > 0 ? (
                <div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">Name :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.name}</p>
                  </div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">Username :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.username}</p>
                  </div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">Email :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.email}</p>
                  </div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">Mobile :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.mobile}</p>
                  </div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">Address :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.address}</p>
                  </div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">State :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.state}</p>
                  </div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">City :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.city}</p>
                  </div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">Pincode :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.pincode}</p>
                  </div>
                  <div className="orderdetailsrow">
                    <p className="orderdetailskey">Date :</p>
                    <p className="orderdetailsvalue">{selectedobjRef?.current?.date}</p>
                  </div>
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
}

export default Users