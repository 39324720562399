import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { assetsUrl, apiUrl } from "../config/config";
import axios from "axios";
import Swal from 'sweetalert2';
import Overlay from '../components/Overlay';

const Checkout = () => {
  const customerdata = useSelector((store) => store.userReducer.customerdata);
  const cartdata = useSelector((store) => store.userReducer.cartdata);
  console.log('Checkout cartdata >>', cartdata);

  const location = useLocation();

  const { state } = location;
  console.log('state1 >>', state);


  const [cart, setcart] = useState([]);
  const [totalprice, settotalprice] = useState(0);
  const [issameasbilling, setissameasbilling] = useState(true);

  const [totalamount, settotalamount] = useState(0);
  const [subtotal, setsubtotal] = useState(0);
  const [totalgstamount, settotalgstamount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [state1, setstate1] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [statesdata, setstatesdata] = useState([]);
  const [citiesdata, setcitiesdata] = useState([]);

  const handlechangequantity = (index, quantity) => {
    if(quantity < 1) {

    } else {
      console.log('index >>', index);
    console.log('quantity >>', quantity);
    var prevdata = cart;
    prevdata[index].quantity = quantity;
    dispatch({ type: "cartdata", payload: prevdata });
    }
    
  }

  

  const handleremovecartitem = (index) => {
    var prevdata = cart;
    prevdata.splice(index, 1);
    dispatch({ type: "cartdata", payload: prevdata });
  }

  const handleplaceorder = () => {
    // console.log('customerdata >>', customerdata);
    if(customerdata && Object.keys(customerdata)?.length > 0) {
      let passdata = { coupondiscountamount: 0 };
      navigate('/checkout', {state: passdata});
    } else {
      // console.log('not logged in');
      let passdata = { fromscreen: 'cart' };
      navigate('/login', {state: passdata});
    }
  }

  const handlecheckout = () => {
    if(!issameasbilling) {
      if(!name || !email || !mobile || !address || !state1 || !city || pincode) {
        alert("Please fill shipping details");
        return
      }
    }
    displayRazorpay(1);
    // paymentsuccessfunction(1, 'pay_PCYV5yC136u8DN');
  }

  const generateRandom22DigitNumber = (maxnum) => {
    let randomNumber = '';
    
    // Generate random digits until we reach 22 digits
    while (randomNumber.length < maxnum) {
        randomNumber += Math.floor(Math.random() * 10); // Random digit from 0 to 9
    }

    return randomNumber;
}

  const paymentsuccessfunction = async(amount, paymentid) => {
    var random22DigitNumber = generateRandom22DigitNumber(22);
    var random8DigitNumber = generateRandom22DigitNumber(8);
    var data = {
      user_id: customerdata?.id,
      order_id: 'Blist-'+random22DigitNumber,
      invoice: random8DigitNumber+'-Blist',
      order_status: 'Success',
      deliveryStatus: 'Not Delivered',
      order_cost: totalamount,
      billing_cost: amount,
      transaction_id: paymentid,
      payment_order_id: paymentid,
      signature_hash: paymentid,
      purchase_date: null,
      user_name: customerdata?.name,
      user_email: customerdata?.email,
      user_mobile: customerdata?.mobile,
      user_address: customerdata?.address,
      user_state: customerdata?.state,
      user_city: customerdata?.city,
      user_pincode: customerdata?.pincode,
      price: totalamount,
      price_with_gst: amount,
      product_tax: totalgstamount,
      shipping_name: issameasbilling ? customerdata?.name : name,
      shipping_email: issameasbilling ? customerdata?.email : email,
      shipping_mobile: issameasbilling ? customerdata?.mobile : mobile,
      shipping_gst: null,
      shipping_address: issameasbilling ? customerdata?.address : address,
      shipping_state: issameasbilling ? customerdata?.state : state1,
      shipping_city: issameasbilling ? customerdata?.city : city,
      shipping_pincode: issameasbilling ? customerdata?.pincode : pincode,
      trackin_url: null,
      preBooking: null,
      cartdata: cartdata
    }

    console.log('data >>>', data)
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customerdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "createorder",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          Swal.fire({
            title: 'Ordered Successfully!',
            text: "We'll notify you once it's shipped",
            icon: 'success',
            customClass: {
              icon: 'custom-swal-icon'
            },
            width: '400px',
            confirmButtonText: 'Ok'
          });
          dispatch({ type: "cartdata", payload: {} });
          navigate('/orderhistory');

        } else {

        }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

  const displayRazorpay = async (amount) => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
    }

    const options = {
        key: 'rzp_live_DAyD5EuV7mxduw', // Enter the Key ID generated from the Dashboard
        amount: amount * 100, // Amount is in paise (e.g., 50000 paise = 500 INR)
        currency: 'INR',
        name: 'Blisstainment',
        description: '',
        image: 'https://your-logo-url.com', // Optional
        handler: function (response) {
            // alert('Payment successful! >>>');
            console.log('Payment successful! >>>', response);
            console.log(response.razorpay_payment_id);
            paymentsuccessfunction(amount, response.razorpay_payment_id);
        },
        prefill: {
            name: name,
            email: email,
            contact: mobile,
        },
        notes: {
            address: 'Your Company Address',
        },
        theme: {
            color: '#fff',
        },
        modal: {
          // Event handler when the user closes the payment modal
          ondismiss: function() {
              // console.log('Payment process cancelled by the user.');
              // Handle the cancellation
          }
      },
      payment_failed: function(response) {
        console.error(response.error);
        // Handle payment failure
    }

    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', function(response) {
      // Handle payment failure
  });

  paymentObject.on('modal.close', function() {
      // Handle the cancellation
  });
};

  console.log('customerdata >>', customerdata);

  const fetchcustomecities = async(id) => {   
    var data = {
      statecode : id,
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customerdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "getallcities",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          if(res?.data?.data?.length > 0) {
            setcitiesdata(res?.data?.data);
          } else {
            setcitiesdata([]);
          }
          
      } else {

      }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  useEffect(() => {
    if(state1) {
      console.log('state1 >>', state1);
      fetchcustomecities(state1);
    }
  }, [state1])


  useEffect(() => {
    // setcart(cartdata);
    var newarrobj = [];
    for(var i = 0; i < cartdata?.length > 0; i++) {
      var cartobj = {
        id: cartdata[i]?.id,
        name: cartdata[i]?.name,
        cartimage: cartdata[i]?.cartimage,
        price: cartdata[i]?.price,
        quantity: cartdata[i]?.quantity
      }
      newarrobj.push(cartobj)
    }
    setcart(newarrobj);
    // Calculate Total price
    var totalPrice = newarrobj.reduce((acc, item) => {
      return acc + item.price * parseInt(item.quantity);
    }, 0);
    settotalprice(totalPrice);
  }, [cartdata])

  const fetchcustomestates = async() => {
    var data = {
      countrycode : 101,
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customerdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "getallstates",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          if(res?.data?.data?.length > 0) {
            setstatesdata(res?.data?.data);
          } else {
            setstatesdata([]);
          }
          
      } else {

      }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  useEffect(() => {
    if (customerdata && Object.keys(customerdata).length > 0) { 
      if(cartdata?.length > 0) {
        fetchcustomestates();
        var gstamountarr = [];
        var totalarr = [];
        var subtotalarr = [];
        for(var i = 0; i < cartdata?.length; i++) {
          //total amount
          var amount = cartdata[i]?.price * parseInt(cartdata[i]?.quantity);
          totalarr.push(amount);

          //gst calculation
          var singleproducttax = (parseInt(cartdata[i]?.price) * 18) / 100;
          var singleProductTaxWithQty = parseFloat(singleproducttax * parseInt(cartdata[i]?.quantity)).toFixed(2); 
          gstamountarr.push(singleProductTaxWithQty);

          // subtotal calulation
          var singleProductAfterTax = parseInt(cartdata[i]?.price) - singleproducttax;
          var singleProductAfterTaxWithQty = parseFloat(singleProductAfterTax * parseInt(cartdata[i]?.quantity)).toFixed(2);
          subtotalarr.push(singleProductAfterTaxWithQty);
        }
        console.log('totalarr >>', totalarr);
        console.log('gstamountarr >>', gstamountarr);
        // var sumofgst = gstamountarr.reduce((a, b) => a+b, 0);
        // var subtotalsum = totalarr.reduce((a, b) => a+b, 0);
        // var grandtotal = subtotalsum + sumofgst;
        // settotalgstamount(parseFloat(sumofgst).toFixed(2) );
        // setsubtotal(subtotalsum);
        // settotalamount(parseFloat(grandtotal).toFixed(2));

        var totalsum = totalarr.reduce((a, b) => a+b, 0);
        var calculatesubtotal = parseFloat(subtotalarr.reduce((a,b) => {return parseFloat(a) + parseFloat(b)}, 0)).toFixed(2);
        var calculategsttotal = parseFloat(gstamountarr.reduce((a,b) => {return parseFloat(a) + parseFloat(b)}, 0)).toFixed(2);
        
        settotalamount(totalsum);
        settotalgstamount(calculategsttotal);
        setsubtotal(calculatesubtotal);
      } else {
        navigate("/cart");
      }
      
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <section className="mt-5 mb-3">
        <div className="container">
          <div className="row">
            <div className="col-md-4 order-md-2 mb-4">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your cart</span>
                <span
                  className="badge badge-secondary badge-pill"
                  style={{ backgroundColor: "#e62e8c", color: "#fff" }}
                >
                  {cartdata?.length}
                </span>
              </h4>
              <ul className="list-group mb-3">
                {cartdata?.length > 0
                  ? cartdata?.map((item, index) => {
                      var amount = item.price * parseInt(item?.quantity);
                      return (
                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                          <div>
                            <h6 className="my-0">
                              {item?.name} (x {item?.quantity})
                            </h6>
                            <p className="mb-0">
                              <span>GST 18 </span>
                            </p>
                            {/* <p
                              style={{ color: "#5bb9cc", cursor: "pointer" }}
                              className="card-link-secondary small text-uppercase mr-3 mt-2"
                              onclick="remove_product('andhadhun-coloured-66fe35b2d78c9')"
                            >
                              <i className="fa fa-trash mr-2"></i> Remove item{" "}
                            </p> */}
                          </div>

                          <span className="text-muted">₹ {amount}</span>
                        </li>
                      );
                    })
                  : null}
                <li className="list-group-item d-flex justify-content-between">
                  <span>Sub Total</span>
                  <strong>₹ {subtotal}</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>GST</span>
                  <strong>₹ {totalgstamount}</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Shipping Charges</span>
                  <strong>₹ 0</strong>
                </li>
                {
                  state?.coupondiscountamount > 0 ?
                  <li className="list-group-item d-flex justify-content-between">
                  <span>Coupon Discount</span>
                  <strong>- ₹ {state?.coupondiscountamount}</strong>
                </li> : null
                }
               
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total Cost</span>
                  <strong>₹ {totalamount - state?.coupondiscountamount}</strong>
                </li>
              </ul>
              <button
                className="btn btn-primary btn-lg btn-block"
                id="checkoutButton"
                type="submit"
                style={{width: '100%'}}
                onClick={() => {handlecheckout()}}
              >
                Continue to checkout
              </button>
            </div>

            <div className="col-md-8 mx-auto">
              <div className="card border-secondary">
                <div className="card-header">
                  <h3 className="mb-0 my-2" style={{ color: "#000" }}>
                    Checkout form
                  </h3>
                </div>
                {customerdata?.address && customerdata?.state ? (
                  <div className="card-body over-all-address">
                    <div className="billing-address">
                      <h4 className="mb-3">Billing address</h4>
                      <h5>{customerdata?.name}</h5>
                      <p>{customerdata?.address} </p>
                      <p>
                        {customerdata?.state} , {customerdata?.city} ,{" "}
                        {customerdata?.pincode}
                      </p>

                      {/* <a href="/place-order" className="btn btn-primary">DELIVER HERE</a>								         */}
                    </div>
                    <div className="col-md-12 order-md-1 billing-address">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <h4 className="mb-3">Shipping address</h4>
                        <div className="d-flex">
                          <p className="sameasbillingtext">
                            Same as Billing Address
                          </p>
                          <input
                            type="checkbox"
                            checked={issameasbilling}
                            onChange={() => {
                              setissameasbilling(!issameasbilling);
                            }}
                            className="sameasbillingtext_checkbox"
                          />
                        </div>
                      </div>

                      {!issameasbilling ? (
                        <div className="checkoutform">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label for="name">Name</label>
                              <input
                                value={name}
                                onChange={(t) => {
                                  setname(t.target.value);
                                }}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label for="mobile">Mobile</label>
                              <input
                                value={mobile}
                                onChange={(t) => {
                                  setmobile(t.target.value);
                                }}
                                type="number"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="mb-3">
                            <label for="email">Email </label>
                            <input
                              value={email}
                              onChange={(t) => {
                                setemail(t.target.value);
                              }}
                              type="email"
                              className="form-control"
                            />
                          </div>

                          <div className="mb-3">
                            <label for="address">Address</label>
                            <textarea
                              value={address}
                              onChange={(t) => {
                                setaddress(t.target.value);
                              }}
                              className="form-control"
                              id="address"
                              name="address"
                            ></textarea>
                          </div>

                          <div className="row">
                            <div className="col-md-5 mb-3">
                              <label for="country">State</label>
                              <select
                                className="custom-select d-block"
                                id="state"
                                name="state"
                                value={state1}
                                onChange={(t) => {
                                  setstate1(t.target.value);
                                }}
                              >
                                <option value="" selected disabled>
                                  Choose...
                                </option>
                                {statesdata?.length > 0
                                  ? statesdata?.map((item, index) => {
                                      return (
                                        <option key={index} value={item?.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </select>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="state">City</label>
                              <select
                                className="custom-select d-block "
                                id="city"
                                name="city"
                                value={city}
                                onChange={(t) => {
                                  setcity(t.target.value);
                                }}
                              >
                                <option value="" selected disabled>
                                  Choose...
                                </option>
                                {citiesdata?.length > 0
                                  ? citiesdata?.map((item, index) => {
                                      return (
                                        <option key={index} value={item?.name}>
                                          {item.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </select>
                            </div>

                            <div className="col-md-3 mb-3">
                              <label for="city">Pin Code</label>
                              <input
                                type="number"
                                className="form-control"
                                value={pincode}
                                onChange={(t) => {
                                  setpincode(t.target.value);
                                }}
                              />
                            </div>
                          </div>
                          {/* <hr className="mb-4" />

                      <button
                        className="btn btn-primary btn-lg btn-block"
                        id="checkoutButton"
                        type="submit"
                      >
                        Continue to checkout
                      </button> */}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      id="checkoutButton"
                      type="submit"
                    >
                      Add Billing Address
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
