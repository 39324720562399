import React, {useEffect, useState, useMemo, useRef} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from 'moment';
import Spinner from "react-activity/dist/Spinner";
import Select from 'react-select';
import "react-activity/dist/Spinner.css";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Sliders = () => {
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [selectedid, setselectedid] = useState(0);

    const today = new Date().toISOString().split('T')[0];

    
    const [startdate, setstartdate] = useState("");
    const [enddate, setenddate] = useState("");
    const [count, setcount] = useState("");
    const [url, seturl] = useState("");
    const [sliderimage, setsliderimage] = useState("");

    const selectedidRef = useRef(0);
    selectedidRef.current = selectedid;



    const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleinputchange = async (file) => {
    var obj = {};
    await getBase64(file).then((result) => {
      obj["name"] = file.name;
      obj["image"] = result;
    });
    // console.log('obj >>', obj);
    setsliderimage(obj);
  };

  const submithandle = async () => {
      setshowloding(true);
      var data = {
        startdate: startdate,
        enddate: enddate,
        count: count,
        link: url,
        image: sliderimage
    };
    console.log('data >>', data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewslider", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setstartdate("");
            setenddate("");
            setcount("");
            seturl("")
            setsliderimage("");
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    
  };

  const edithandle = async () => {
        setshowloding(true);
        var data = {
            id: selectedid,
            startdate: startdate,
            enddate: enddate,
            count: count,
            link: url,
            image: sliderimage
        };
        console.log('data >>', data);
          const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userdata?.token,
            },
          };
          let resp = await axios
            .post(apiUrl + "updateslider", data, headers)
            .then((res) => {
              // console.log('res >>>', res.data)
              if (res.data.status == "success") {
                seteditshowmodal(false);
                setselectedid("");
                setstartdate("");
                setenddate("");
                setcount("");
                seturl("")
                setsliderimage("");
                fetchallcourses();
                seteditshowmodal(false);
              } else {
                alert(res.data.message);
                setshowloding(false);
              }
            })
            .catch((err) => {
              setshowloding(false);
              alert(err.message);
            });
        setshowloding(false);
    };

    const clickhandle = () =>  {
        // console.log('Mission Launched')
      }

      const editlocation = async(obj) => {
        console.log('obj >>', obj);
        setselectedid(obj.id);
        setstartdate(obj.startDate);
        setenddate(obj.endDate);
        setcount(obj.order);
        seturl(obj.link);
        seteditshowmodal(true);
      }

      const deletecourse = async (id) => {
        console.log('id >>', id);
        const result = window.confirm("Are you sure you want to delete this slider");
        if (result) {
          var data = JSON.stringify({
            id: id,
          });
          const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userdata?.token,
            },
          };
          let resp = await axios
            .post(apiUrl + "deleteslider", data, headers)
            .then((res) => {
              if (res.data.status == "success") {
                fetchallcourses();
              } else {
              }
            })
            .catch((err) => {
              alert(err.message);
            });
        } else {
          // User clicked Cancel or closed the dialog
          // console.log("User clicked Cancel");
        }
      };

      const defaultColDef = {
        // flex: 1,
        resizable: true,
        sortable: true 
      };


    const fetchallcourses = async () => {
        setshowloding(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .get(apiUrl + "getallsliders", headers)
          .then((res) => {
            if (res.data.status == "success") {
              // console.log('getallcategories >>', res.data);
              var prevdata = res.data.data;
              if(prevdata?.length > 0) {
                var columnsarr = [];
                var hidecolumns = ['id', 'created_at', 'updated_at', 'sort', 'image', 'category_id', 'startDate', 'endDate', 'date'];
                var hidefilters = [];
                var columns = Object.keys(prevdata[0]);
                console.log('columns  >>>', columns ); 
                for(var i = 0; i < columns?.length;i++) {
                  if(!hidecolumns.includes(columns[i])) {
                    var obj = {};
                    obj.field = columns[i];
                    if (!hidefilters.includes(columns[i])) {
                        obj.filter = true;
                        obj.filterParams = {
                          filterOptions: ["contains"],
                          maxNumConditions: 1,
                        };
                      }
                    console.log('obj >>', obj)
                    columnsarr.push(obj);
                  }
                }

                //Image
                if(columns.includes('image')) {
                  var imageobj = 
              {
                field: "Image",
                headerName: "Image",
                cellRenderer: (props) => {
                  // console.log('image props >>', props);
                  return (
                    <Link
                    to={assetsUrl+'sliders/'+props?.data?.image}
                    target="_blank"
                    style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </Link>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(imageobj);
                }

                //created_at
            //     if(columns.includes('created_at')) {
            //       var craetddatobj = 
            //   {
            //     field: "Date",
            //     headerName: "Date",
            //     cellRenderer: (props) => {
            //       // console.log('date props >>', props);
            //       return (
            //              <div>
            //               <p className="created_at_value">{moment(props?.data?.created_at).format('DD MMMM YYYY HH:mm')}</p>
            //             </div>
            //       )
            //     },
            //     cellRendererParams: {
            //       onClick: clickhandle,
            //     },
            //   }
            //   columnsarr.push(craetddatobj);
            //     }
              
                //edit
              var editobj = 
              {
                field: "edit",
                headerName: "Edit",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
                //delete
                var deleteobj = 
                {
                  field: "delete",
                  headerName: "Delete",
                  cellRenderer: (props) => {
                    
                    return (
                      <div
                            onClick={() => {
                              deletecourse(props?.data?.id, props?.data?.name);
                              // console.log('props >>', props?.data);
                            }}
                            style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="sidebaricons"
                              style={{ color: "red" }}
                            />
                          </div>
                    )
                  },
                  cellRendererParams: {
                    onClick: clickhandle,
                  },
                }
                columnsarr.push(deleteobj);
                setColDefs(columnsarr);
                setRowData(prevdata);
              }
              
              // setcoursesdata(res.data.data);
            } else {
              // alert(res.data.message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
          setshowloding(false);
      };

    useEffect(() => {
        if (userdata && Object.keys(userdata).length > 0) {
          //   fetchdata();
          fetchallcourses();
        //   fetchallproducts();
        } else {
          navigate("/newadminlogin");
        }
      }, []);
    return (
        <div className="adminmainpagelayout">
          <div className="admin-categorymaindiv">
            <p className="admin-page-title">View All Sliders</p>
            <div className="category-add-cat-btn" onClick={() => {
                  setshowmodal(true);
                  setselectedid("");
                  setstartdate("");
                setenddate("");
                setcount("");
                seturl("");
                setsliderimage("");
                }}><p>Add Slider</p></div>
          </div>
            

            <div
      className={
        "ag-theme-quartz"
      }
      style={{ width: "100%", height: 500, marginTop: 15 }}
    >
      <AgGridReact
      // modules={AllModules}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        suppressRowClickSelection={true}
        groupSelectsChildren={true}
        rowSelection={"multiple"}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={paginationPageSizeSelector}
        // onGridReady={onGridReady}
      />
    </div>

    {/*Add modal */}
    <Modal
              show={showmodal}
              onHide={() => setshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 19}}>Add Slider</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                  <div className="row ">
                    <div className="col-md-6">
                    <div className="">
                    <label className="admin-label">Start Date</label>
                        <input
                              value={startdate}
                              onChange={(t) => {
                                setstartdate(t.target.value);
                              }}
                              type="date"
                              min={today}
                              className="form-control admininput"
                            />
                  </div>
                    </div>
                    <div className="col-md-6">
                    <div className="">
                    <label className="admin-label">End Date</label>
                        <input
                              value={enddate}
                              onChange={(t) => {
                                setenddate(t.target.value);
                              }}
                              type="date"
                              min={today}
                              className="form-control admininput"
                            />
                  </div>
                    </div>
                    <div className="col-md-4 mt-3">
                    <div className="">
                    <label className="admin-label">Slider Order</label>
                        <input
                              value={count}
                              onChange={(t) => {
                                setcount(t.target.value);
                              }}
                              type="number"
                              className="form-control admininput"
                               placeholder="Url"
                            />
                  </div>
                    </div>
                    {/* <div className="col-md-9 mt-3">
                    <div className="">
                    <label className="admin-label">Slider Url</label>
                    <input
                    value={link}
                    onChange={(t) => {
                      setlink(t.target.value);
                    }}
                      type="url"
                      className="form-control admininput"
                      placeholder="Slider Url"
                    />
                  </div>
                    </div> */}
                    <div className="col-md-8 mt-3">
                    <label className="admin-label">Slider Image</label>
                    <input
                    onChange={(t) => {
                      handleinputchange(t.target.files[0]);
                    }}
                      accept="image/*"
                      type="file"
                      className="form-control admininput"
                      placeholder="Slider Image"
                    />
                    </div>

                    <div className="col-md-12 mt-3">
                    <label className="admin-label">Slider Url</label>
                      <input
                              value={url}
                              onChange={(t) => {
                                seturl(t.target.value);
                              }}
                              type="text"
                              className="form-control admininput"
                               placeholder="Order"
                            />
                    </div>
                  </div>
                  
                  <div
                    className="webadmin-modalbtn"
                    onClick={() => {
                      submithandle();
                    }}
                  >
                    <p>
                    {
                      showloding ?
                      <Spinner size={15} className='spinnerCenter' /> : 'Submit'
                    }
                    </p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

    
     {/*Edit modal */}
    <Modal
              show={editshowmodal}
              onHide={() => seteditshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize: 19}}>Edit Slider</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                <div className="row ">
                    <div className="col-md-6">
                    <div className="">
                    <label className="admin-label">Start Date</label>
                        <input
                              value={startdate}
                              onChange={(t) => {
                                setstartdate(t.target.value);
                              }}
                              type="date"
                              min={today}
                              className="form-control admininput"
                            />
                  </div>
                    </div>
                    <div className="col-md-6">
                    <div className="">
                    <label className="admin-label">End Date</label>
                        <input
                              value={enddate}
                              onChange={(t) => {
                                setenddate(t.target.value);
                              }}
                              type="date"
                              min={today}
                              className="form-control admininput"
                            />
                  </div>
                    </div>
                    <div className="col-md-4 mt-3">
                    <div className="">
                    <label className="admin-label">Slider Order</label>
                        <input
                              value={count}
                              onChange={(t) => {
                                setcount(t.target.value);
                              }}
                              type="number"
                              className="form-control admininput"
                               placeholder="Url"
                            />
                  </div>
                    </div>
                    {/* <div className="col-md-9 mt-3">
                    <div className="">
                    <label className="admin-label">Slider Url</label>
                    <input
                    value={link}
                    onChange={(t) => {
                      setlink(t.target.value);
                    }}
                      type="url"
                      className="form-control admininput"
                      placeholder="Slider Url"
                    />
                  </div>
                    </div> */}
                    <div className="col-md-8 mt-3">
                    <label className="admin-label">Slider Image</label>
                    <input
                    onChange={(t) => {
                      handleinputchange(t.target.files[0]);
                    }}
                      accept="image/*"
                      type="file"
                      className="form-control admininput"
                      placeholder="Slider Image"
                    />
                    </div>

                    <div className="col-md-12 mt-3">
                    <label className="admin-label">Slider Url</label>
                      <input
                              value={url}
                              onChange={(t) => {
                                seturl(t.target.value);
                              }}
                              type="text"
                              className="form-control admininput"
                               placeholder="Order"
                            />
                    </div>
                  </div>
                  
                  <div
                    className="webadmin-modalbtn"
                    onClick={() => {
                      edithandle();
                    }}
                  >
                    <p>
                    {
                      showloding ?
                      <Spinner size={15} className='spinnerCenter' /> : 'Submit'
                    }
                    </p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>


        </div>
    )
}

export default Sliders