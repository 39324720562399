import React, {useEffect, useState, useMemo, useRef} from "react";
import { NavLink } from "react-router-dom";
import logo from '../images/logo-header.png';
import search1 from '../images/search.png';
import cart from '../images/cart.png';
import bag from '../images/bag.png';
import user from '../images/user.png';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faEye, faArrowRightFromBracket, faArrowRightToBracket, faBars, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import {faSquareCaretDown, faSquareCaretUp} from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { apiUrl } from "../config/config";
import More from "../images/more.png";
import { Drawer, ButtonToolbar, Button, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const Navbar = () => {
  const customerdata = useSelector((store) => store.userReducer.customerdata);
  const cartdata = useSelector((store) => store.userReducer.cartdata);
  // console.log('navbar customerdata >>', customerdata)
  console.log('navbar cartdata >>', cartdata);

  const [search, setsearch] = useState("");
  const [showsearchdropdown, setshowsearchdropdown] = useState(false);
  const [resultsarr, setresultsarr] = useState([]);
  const [musicdropdown, setmusicdropdown] = useState([]);
  const [moviedropdown, setmoviedropdown] = useState([]);
  const [open, setOpen] = useState(false);
  const [showmusicdropdown, setshowmusicdropdown] = useState(false);
  const [showmoviedropdown, setshowmoviedropdown] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch(); 

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(false)
  }, [location]);

  const logoutfunc = async() => {
    var data = {
      password: ""
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customerdata?.token,
      },
    };
    let resp = await axios
          .post(apiUrl + "customerlogout",data, headers)
          .then((res) => {
            // console.log('getdashboarddata res >>', res.data)
            if(res?.data?.status == 'success') {
              dispatch({ type: "customerdata", payload: {} });
            } else {
              
            }
          })
          .catch((err) => {
            alert(err.message);

          });
    console.log('data >>', data);
  }

  const handlenavbarsearch = async(text) => {
    var data = {
      text: text,
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "searchalbum",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          if(res?.data?.data?.length > 0) {
            setresultsarr(res?.data?.data);
          } else {
            setresultsarr([]);
          }
          
      } else {

      }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  const handlenavigation = (data) => {
    // navigate('/productdetails', {state: {data: data}});
    navigate('/entertainment/'+data?.slug)
    setsearch("")
    setshowsearchdropdown(false);
  }

  useEffect(() => {
    if(search?.length > 0) {
      handlenavbarsearch(search);
      setshowsearchdropdown(true);
    } else {
      setshowsearchdropdown(false);
    }
  }, [search])

  const handlelogout = () => {
    // dispatch({ type: "customerdata", payload: {} });
    // logoutfunc();
    // navigate("/login");
    navigate('/myorder');
  }

  const handlenavbarclick = () => {
    let passdata = { fromscreen: 'navbar' };
      navigate('/login', {state: passdata});
  }

  const fetchdropdowns = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "navbardropdowns", headers)
      .then((res) => {
        if (res.data.status == "success") {
          console.log('res data >>>', res.data);
          if(res?.data?.musicdata?.length > 0) {
            setmusicdropdown(res?.data?.musicdata);
          } else {
            setmusicdropdown([]);
          }
          if(res?.data?.moviedata?.length > 0) {
            setmoviedropdown(res?.data?.moviedata);
          } else {
            setmoviedropdown([]);
          }
          
      } else {

      }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  useEffect(() => {
    console.log('call navbar api');
    fetchdropdowns();
  }, [])
  return (
    <>
      <nav className="navbar navbar-expand-lg desknavbar mobhide">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="main-header dflex space-between align-items">
            <div className="logo">
              <NavLink className="navbar-logo" to="home">
                <img src={logo} />
              </NavLink>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0 uppercase">
                <li className="nav-item">
                  <NavLink className="nav-link" to="home">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="about-us">
                    About Us
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="services">
                    Services
                  </NavLink>
                </li>

                <li className="nav-item musicnavitem">
                  <NavLink className="nav-link" to="music">
                    Music
                  </NavLink>
                  <div className="musicdropdown">
                    {
                      musicdropdown?.length > 0 ?
                      musicdropdown?.map((item, index) => {
                        return (
                          <NavLink className="nav-link" to={"/sub-category/"+item?.slug+"/Music"} >
                            {item?.name}
                          </NavLink>
                        )
                      }) : null
                    }
                   
                  </div>

                  {/* <ul className="">
                  <li>
                    <NavLink className="" href="#">
                      Action
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="" href="#">
                      Another action
                    </NavLink>
                  </li>
                </ul> */}
                </li>

                <li className="nav-item musicnavitem">
                  <NavLink className="nav-link" to="movies">
                    Movies
                  </NavLink>
                  <div className="musicdropdown">
                    {
                      moviedropdown?.length > 0 ?
                      moviedropdown?.map((item, index) => {
                        return (
                          <NavLink className="nav-link" to={"/sub-category/"+item?.slug+"/Movie"} >
                            {item?.name}
                          </NavLink>
                        )
                      }) : null
                    }
                   
                  </div>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="deals">
                    Deals
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="contact-us">
                    Contact us
                  </NavLink>
                </li>
                {/* <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" href="#">
                      Action
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" href="#">
                      Another action
                    </NavLink>
                  </li>
        
                </ul>
              </li> */}
              </ul>
            </div>
            <div className="serchbox">
              <div className="d-flex align-items">
                <div className="relative">
                  <input
                    value={search}
                    onChange={(t) => {
                      setsearch(t.target.value);
                    }}
                    className="form-control"
                    type="search"
                    placeholder="Album, Artist, Movies, Genre, etc"
                    aria-label="Search"
                  />
                  <img src={search1} className="searchimg" />
                  {showsearchdropdown ? (
                    <div className="nav_searchresultdiv">
                      {resultsarr?.length > 0 ? (
                        resultsarr?.map((item, index) => {
                          return (
                            <div
                              className="searchresultrowdiv"
                              key={index}
                              onClick={() => {
                                handlenavigation(item);
                              }}
                            >
                              <p>{item?.name}</p>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          className="searchresultrowdiv"
                          style={{ cursor: "initial" }}
                        >
                          <p>Not Found</p>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>

                <NavLink
                  to="/cart"
                  className="btn"
                  type="submit"
                  style={{ position: "relative" }}
                >
                  <div className="navbar-cartcountdiv">
                    <span className="cart-badge badge" id="cart-badge">
                      {cartdata?.length}
                    </span>
                  </div>
                  <img src={cart} className="w28" />
                </NavLink>
                <NavLink to="/login" className="btn" type="submit">
                  {" "}
                  <img src={bag} className="w28" />
                </NavLink>
                {customerdata && Object.keys(customerdata).length > 0 ? (
                  <div
                    className="btn"
                    onClick={() => {
                      handlelogout();
                    }}
                  >
                    {" "}
                    <img src={user} className="w28" />
                  </div>
                ) : (
                  <div
                    className=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handlenavbarclick();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRightToBracket}
                      className="customernavbaricons"
                      style={{ color: "white" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="mobilenavbardiv deskhide">
        <div className="mobilenavbarrow">
          <div className="mobilemenudiv" onClick={() => {setOpen(true)}}>
            <FontAwesomeIcon
                      icon={faBars}
                      className="menubaricon"
                    />
          </div>
          <div className="mobilemenulogodiv">
            <img src={logo} />
          </div>
        </div>
        <Drawer
          open={open}
          placement="left"
          onClick={() => setOpen(false)}
          onClose={() => setOpen(false)}
          backdrop={true}
          size={'xs'}
        >
          <Drawer.Header >
            {/* <p className="unreadhead">unread message</p> */}
          </Drawer.Header>
          <Drawer.Body>
          {/* <Placeholder.Paragraph rows={8} /> */}
          <div className="mobmenu-slider-maindiv">
            <ul class="navbar-nav">
            <li className="nav-item">
                  <NavLink className="nav-link" to="home">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="about-us">
                    About Us
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="services">
                    Services
                  </NavLink>
                </li>

                <li className="nav-item musicnavitem">
                  <div className="mobilemenumusicrow">
                  <NavLink className="nav-link" to="music" style={{width: '75%'}} >
                    Music
                  </NavLink>
                  <div className="" onClick={() => {setshowmusicdropdown(!showmusicdropdown)}}>
                    {
                      !showmusicdropdown ?
                      <FontAwesomeIcon
                      icon={faCaretDown}
                      className="mobilemenudropdown"
                      style={{ color: "white" }}
                    /> : 
                    <FontAwesomeIcon
                    icon={faCaretUp}
                    className="mobilemenudropdown"
                    style={{ color: "white" }}
                  />
                    }
                   
                  </div>
                  </div>
                 
                  <div className="musicdropdown" style={showmusicdropdown ? {display: 'block'} : {display: 'none'}}>
                    {
                      musicdropdown?.length > 0 ?
                      musicdropdown?.map((item, index) => {
                        return (
                          <NavLink className="nav-link" to={"/sub-category/"+item?.slug+"/Music"} >
                            {item?.name}
                          </NavLink>
                        )
                      }) : null
                    }
                   
                  </div>

                  {/* <ul className="">
                  <li>
                    <NavLink className="" href="#">
                      Action
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="" href="#">
                      Another action
                    </NavLink>
                  </li>
                </ul> */}
                </li>

                <li className="nav-item musicnavitem">
                <div className="mobilemenumusicrow">
                  <NavLink className="nav-link" to="movies" style={{width: '75%'}} >
                    Movies
                  </NavLink>
                  <div className="" onClick={() => {setshowmoviedropdown(!showmoviedropdown)}}>
                  {
                      !showmoviedropdown ?
                      <FontAwesomeIcon
                      icon={faCaretDown}
                      className="mobilemenudropdown"
                      style={{ color: "white" }}
                    /> : 
                    <FontAwesomeIcon
                    icon={faCaretUp}
                    className="mobilemenudropdown"
                    style={{ color: "white" }}
                  />
                    }
                  </div>
                </div>
                  
                  <div className="musicdropdown" style={showmoviedropdown ? {display: 'block'} : {display: 'none'}}>
                    {
                      moviedropdown?.length > 0 ?
                      moviedropdown?.map((item, index) => {
                        return (
                          <NavLink className="nav-link" to={"/sub-category/"+item?.slug+"/Movie"} >
                            {item?.name}
                          </NavLink>
                        )
                      }) : null
                    }
                   
                  </div>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="deals">
                    Deals
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="contact-us">
                    Contact us
                  </NavLink>
                </li>
                  </ul>
          </div>
        </Drawer.Body>
        
        </Drawer>
      </div>
      <div className="navbarheight" />
    </>
  );
};

export default Navbar;
