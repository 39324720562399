import { Link } from "react-router-dom"

const Social = () => {
  return (
    <>
    <section className="bgclor">
      <div className="container">
       <h3 className="uppercase text-golden social-text text-center">STAY CONNECTED WITH US</h3>

          <ul className="dflex content-center middle-social">
              <li><Link to="https://www.facebook.com/blisstainment/" target="_blank"><i className="fa fa-facebook new-fa"></i></Link></li>
              <li><Link to="https://instagram.com/blisstainment1?utm_medium=copy_link" target="_blank"><i className="fa fa-instagram new-fa"></i></Link></li>
              <li><Link to="https://www.linkedin.com/in/blisstainment-a1b403209/" target="_blank"><i className="fa fa-linkedin new-fa"></i></Link></li>
              <li><Link to="https://twitter.com/Blisstainment_1" target="_blank"><i className="fa fa-twitter new-fa"></i></Link></li>
          </ul>

      </div>
      </section>
    
    </>
  )
}

export default Social