import React from "react";
import logoheader from '../images/logo-header.png';
import brandlogo from '../images/BrandLogo2.png';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { assetsUrl, apiUrl } from "../config/config";
import axios from "axios";
import { useState, useEffect } from "react";
import Overlay from '../components/Overlay';

const Invoice = () => {
    const { orderid } = useParams();
    const customerdata = useSelector((store) => store.userReducer.customerdata);
  const navigate = useNavigate();

  const [allorders, setallorders] = useState([]);
  const [order, setorder] = useState({});

  const [subtotal, setsubtotal] = useState(0);
  const [gsttotal, setgsttotal] = useState(0);

  const numberToWords = (num) => {
    const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", 
                  "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", 
                  "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const scales = ["", "Thousand", "Lakh", "Crore"]; // Indian number system
    let words = "";

    if (num === 0) return "Zero Rupees Only";

    function getBelowThousand(n) {
        let str = "";
        if (n > 99) {
            str += ones[Math.floor(n / 100)] + " Hundred ";
            n %= 100;
        }
        if (n > 19) {
            str += tens[Math.floor(n / 10)] + " ";
            n %= 10;
        }
        if (n > 0) {
            str += ones[n] + " ";
        }
        return str.trim();
    }

    let scaleIndex = 0;
    while (num > 0) {
        let part = num % 1000;
        if (part > 0) {
            const partInWords = getBelowThousand(part);
            words = partInWords + (scales[scaleIndex] ? " " + scales[scaleIndex] : "") + " " + words;
        }
        num = Math.floor(num / 1000);
        scaleIndex++;
    }

    return words.trim() + " Rupees Only";
};

  const fetchordershistory = async() => {
    var data = {
        orderid: orderid
    }
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customerdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "invoicedetails",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          if(res?.data?.allorders?.length > 0) {
            setallorders(res?.data?.allorders);
            var subtotalarr = [];
            var gstamountarr = [];
            var prevdata =res?.data?.allorders;
            for(var i = 0; i < prevdata?.length; i++) {
                var singleproducttax = (parseInt(prevdata[i]?.single_product_price) * parseInt(prevdata[i]?.gst_rate)) / 100;
                var singleProductTaxWithQty = parseFloat(singleproducttax * parseInt(prevdata[i]?.qty)).toFixed(2); 
                gstamountarr.push(singleProductTaxWithQty);

                var singleProductAfterTax = parseInt(prevdata[i]?.single_product_price) - singleproducttax;
                var singleProductAfterTaxWithQty = parseFloat(singleProductAfterTax * parseInt(prevdata[i]?.qty)).toFixed(2);
                subtotalarr.push(singleProductAfterTaxWithQty);
            }

            console.log('subtotalarr >>', subtotalarr);

            var calculatesubtotal = parseFloat(subtotalarr.reduce((a,b) => {return parseFloat(a) + parseFloat(b)}, 0)).toFixed(2);
            var calculategsttotal = gstamountarr.reduce((a,b) => {return parseFloat(a) + parseFloat(b)}, 0);

            setsubtotal(calculatesubtotal);
            setgsttotal(calculategsttotal);

            // var totalamount = parseInt(item?.single_product_price) * parseInt(item?.qty);
            // var singleproducttax = (parseInt(item?.single_product_price) * parseInt(item?.gst_rate)) / 100;
            // var singleproductaftertax = parseInt(item?.single_product_price) - singleproducttax;
          } else {
            setallorders([]);
            setsubtotal(0);
            setgsttotal(0);
          }
          if(res?.data?.order?.length > 0) {
            setorder(res?.data?.order[0]);
          } else {
            setorder({});
          }
          
      } else {

      }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  const FormatDate = (isoDate) => {
    // Convert the ISO date string to a Date object
    const date = new Date(isoDate);
  
    // Format the date to '24 October 2024'
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  
    return formattedDate;
  };

  useEffect(() => {
    fetchordershistory();
  }, [])

//   useEffect(() => {
//     if (customerdata && Object.keys(customerdata).length > 0) { 
//     fetchordershistory();
//     } else {
//       navigate("/login");
//     }
//   }, []);

    return (
      <div class="main invoicemain">
        <div class="container logoDiv">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 logoLeftDiv">
              <img
                src={logoheader}
                class="logoLeft"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 logoRightDiv">
              <img
                src={brandlogo}
                class="logoRight"
              />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row allAddress">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 ">
              <label for="" class="">
                Sold By:
              </label>
              <p class="vendorAdd">
                BASKING SHARK PVT. LTD.
                <br />
                GODOWN NO. 458, JAI BHAWANI INDUSTRIAL ESTATE,
                <br />
                NEAR ST. XAVIER’S SCHOOL GATE, NO 4, GHODBUNDER ROAD,
                <br />
                CHITALSAR VILLAGE, MANPADA
                <br />
                THANE (WEST) – 400 607, INDIA.
              </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <label for="" class="">
                Billing Address:
              </label>
              <p class="billingAdd">
                {order?.user_name}
                <br />
                {order?.user_mobile}
                <br />
                {order?.user_address}
                <br />
                {order?.user_state}, {order?.user_city} {order?.user_pincode}
              </p>
            </div>
          </div>
          <div class="row allAddress detail">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
              <label>PAN No:</label>
              <span class="panNo"> AAICB4117E</span>
              <br />
              <label>GST Registration No:</label>
              <span class="panNo"> 27AAICB4117E1ZZ</span>
              <br />
              <label>Order Number:</label>
              <span class="orderNo"> {order?.order_id}</span>
              <br />
              <label>Order Date:</label>
              <span class="orderDate"> {FormatDate(order?.created_at ? order?.created_at : order?.create_at)}</span>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <label>GST Number:</label>
              <span class="invoiceNo"> {order?.user_gst}</span>
              <br />
              <label>Invoice Number:</label>
              <span class="invoiceNo"> {order?.invoice}</span>
              <br />
              <label>Invoice Date:</label>
              <span class="invoiceDate"> {FormatDate(order?.created_at ? order?.created_at : order?.create_at)}</span>
            </div>
          </div>

          <table class="product table">
            <thead>
              <tr>
                <th scope="col">
                  Sr.
                  <br />
                  NO
                </th>
                <th scope="col" class="colDiscription">
                  Name
                </th>
                <th scope="col" class="colDiscription">
                  HSN No.
                </th>
                <th scope="col" class="colDiscription">
                  SKU
                </th>
                <th scope="col">Unit price </th>
                <th scope="col">Qty</th>
                <th scope="col">
                  Net <br />
                  Amount
                </th>
                <th scope="col">
                  Tax <br />
                  Rate
                </th>

                <th scope="col">
                  Tax <br />
                  Amount
                </th>
                <th scope="col">
                  Total <br />
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
                {
                    allorders?.length > 0 ?
                    allorders?.map((item, index) => {
                        var totalamount = parseInt(item?.single_product_price) * parseInt(item?.qty);
                        var singleproducttax = (parseInt(item?.single_product_price) * parseInt(item?.gst_rate)) / 100;
                        var singleproductaftertax = parseInt(item?.single_product_price) - singleproducttax;
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td>{item?.hsn_code}</td>
                            <td>{item?.sku}</td>
                            <td>{item?.single_product_price}</td>
                            <td>{item?.qty}</td>
                            <td>{parseFloat(singleproductaftertax * parseInt(item?.qty)).toFixed(2)}</td>
                            <td>{parseInt(item?.gst_rate)}</td>
                            <td>{singleproducttax * parseInt(item?.qty)}</td>
                            <td>{totalamount}</td>
                          </tr>
                        );
                    }) : null
                }
              


              <tr>
                <td colspan="9" style={{ textAlign: "right" }}>
                  Shipping Charge :<br />
                  Shipping Charge Net Amt :<br />
                  Shipping Charge Tax Amt: <br />
                  Total Amount Before Tax: <br />
                  {
                    order?.user_state == 'Maharashtra' ?
                    <>
                    CGST (9%) <br />
                    SGST (9%)
                    </> : 
                    <>
                        IGST (18%)
                    </>
                    
                  }
                  <br />
                  Tax Amount GST
                  <br />
                  Total Amount After Tax:
                  <br />
                </td>
                <td class="gray">
                  0<br />
                  0<br />
                  0<br />
                  {subtotal ? parseFloat(subtotal).toFixed(2) : 0}<br />
                  {
                    order?.user_state == 'Maharashtra' ?
                    <>
                    {gsttotal ? parseFloat(gsttotal / 2).toFixed(2) : 0} <br />
                    {gsttotal ? parseFloat(gsttotal / 2).toFixed(2) : 0}
                    </> : 
                    <>
                        {gsttotal ? parseFloat(gsttotal).toFixed(2) : 0}
                    </>
                    
                  }
                   <br />
                  {gsttotal ? parseFloat(gsttotal).toFixed(2) : 0}<br />
                  {order?.order_cost}
                  <br />
                </td>
              </tr>
              <tr>
                <td colspan="10">
                  Amount in Words: {numberToWords(order?.order_cost)}
                </td>
              </tr>

              <tr>
                <td colspan="10" class="rightTxt">
                  <br />
                  <br />
                  <span>Authorized Signatory</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
}

export default Invoice;