import React, { useState, useRef } from "react";
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from '../components/Overlay';

const ResetPassword = () => {
  const [name, setname] = useState("");
  const [password, setpassword] = useState("");
  const [repassword, setrepassword] = useState("");
  const [forcestateupdate, setforcestateupdate] = useState(false);
  const [isfocus, setisfocus] = useState({name: false, password: false});
  const [showloding, setshowloding] = useState(false);
  const [submittedemail, setsubmittedemail] = useState(false);

  const location = useLocation();

  const { state } = location;
  const { slug } = useParams();
  console.log('state >>', state);
  console.log('slug >>', slug);

  const isfocusRef = useRef({});
  isfocusRef.current = isfocus;

  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const handleblur = (name) => {
    var prevdata = isfocusRef.current;
    prevdata[name] = true;
    setisfocus(prevdata);
    setforcestateupdate(!forcestateupdate);
  }

  const handleregister = async() => {
    setshowloding(true);
    if(password && repassword) {
        if(password == repassword) {
            var data = {
                uniqueid: slug,
                password: password,
              }
              const headers = {
                headers: {
                  "Content-Type": "application/json",
                },
              };
              let resp = await axios
                    .post(apiUrl + "customerresetpassword",data, headers)
                    .then((res) => {
                      // console.log('getdashboarddata res >>', res.data)
                      if(res?.data?.status == 'success') {
                        Swal.fire({
                            // title: 'Failed',
                            text: res.data.message,
                            icon: 'success',
                            customClass: {
                              icon: 'custom-swal-icon' // Apply the custom class
                            },
                            width: '400px',
                            confirmButtonText: 'Ok'
                          });
                          handlelogin()
                        
                      } else {
                        Swal.fire({
                          // title: 'Failed',
                          text: res.data.message,
                          icon: 'error',
                          customClass: {
                            icon: 'custom-swal-icon' // Apply the custom class
                          },
                          width: '400px',
                          confirmButtonText: 'Ok'
                        });
                      }
                    })
                    .catch((err) => {
                      alert(err.message);
        
                    });
              console.log('data >>', data);
        } else {
            alert('Password and re-password does not match.');
        }
    } else {
      alert('Fill all details.');
    }
    setshowloding(false);
  }

  const handlecreateaccount = () => {
    let passdata = { fromscreen: state?.fromscreen };
      navigate('/register', {state: passdata});
  }

  const handlelogin = () => {
    let passdata = { fromscreen: state?.fromscreen };
    navigate('/login', {state: passdata});
  }

  
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="border-secondary">
              <div className="card-header">
                <h3 className="mb-0 my-2 text-black login_title">Reset Password</h3>
              </div>
              <div className="login-card-body">
                {
                    !submittedemail ?
                    <>
                    <div className="form-group mb-2">
                    <label for="email_username" className="loginlabel">
                     New Password
                    </label>
                    <input
                    value={password}
                    onChange={(t) => {setpassword(t.target.value)}}
                    onBlur={() => {handleblur('password')}}
                      type="password"
                      className="form-control valid fs22 logininput"
                     
                    />
                    {
                        isfocusRef.current['password'] && !password ?
                        <p style={{color: 'red'}}>Enter Password</p> : null
                    }
                  </div>
                  <div className="form-group mb-2">
                    <label for="email_username" className="loginlabel">
                    Re-enter New Password
                    </label>
                    <input
                    value={repassword}
                    onChange={(t) => {setrepassword(t.target.value)}}
                    onBlur={() => {handleblur('repassword')}}
                      type="password"
                      className="form-control valid fs22 logininput"
                     
                    />
                    {
                        isfocusRef.current['repassword'] && !repassword ?
                        <p style={{color: 'red'}}>Enter Re-Password</p> : null
                    }
                  </div>
    
                      <button className="loginbtn mt-3" onClick={() => {handleregister()}}>Submit</button>

                      <p className="mt-3">
                    New to Blisstainment?{" "}
                    <span className="loginpage_a" style={{cursor: 'pointer', color: 'rgb(13,110,253)'}} onClick={() => {handlecreateaccount()}}>Create Your Account</span>
                  </p>
                    </> : 
                    <div>
                        <p className="mb-2">Check your email</p>
                        <label for="email_username" className="loginlabel">
                        Please check your email inbox for a message containing a link to reset your password. If you don’t see it, be sure to check your spam or junk folder as well.
                        </label>
                        <p className="mt-3">
                        Already reset password?{" "}
                    <span className="loginpage_a" style={{cursor: 'pointer', color: 'rgb(13,110,253)'}} onClick={() => {handlelogin()}}>Login</span>
                  </p>
                    </div>
                }
               


                  

                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
