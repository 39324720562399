import React, {useEffect, useState, useMemo, useRef} from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faEye, faXmark, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from 'moment';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Select from 'react-select';
import Editor from "../common/Editor";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Products = () => {
  const languages = [
    {label: 'HINDI', value: 'HINDI'},
    {label: 'MARATHI', value: 'MARATHI'},
    {label: 'ENGLISH', value: 'ENGLISH'},
    {label: 'GUJRATI', value: 'GUJRATI'},
    {label: 'MALAYALAM', value: 'MALAYALAM'},
    {label: 'TAMIL', value: 'TAMIL'},
    {label: 'KANNADA', value: 'KANNADA'},
    {label: 'BENGALI', value: 'BENGALI'},
    {label: 'PUNJABI', value: 'PUNJABI'},
    {label: 'BHOJPURI', value: 'BHOJPURI'},
    {label: 'TELUGU', value: 'TELUGU'},
    {label: 'SANSKRIT', value: 'SANSKRIT'}
  ]
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [selectedid, setselectedid] = useState(0);
    const [categoriesdropdowndata, setcategoriesdropdowndata] = useState([]);

    const [showimportmodal, setshowimportmodal] = useState(false);
    const [selectedfile, setselectedfile] = useState(null);
    const [selectedimages, setselectedimages] = useState([]);
    const [bulkuploadloading, setbulkuploadloading] = useState(false);

    const [name, setname] = useState("");
    

    const [languagesdata, setlanguagesdata] = useState(languages);
    
    const [editorLoaded, setEditorLoaded] = useState(true);

    const [skuid, setskuid] = useState("");
    const [hsncode, sethsncode] = useState("");
    const [publisher, setpublisher] = useState("");
    const [selectedlanguage, setselectedlanguage] = useState("");
    const [title, settitle] = useState("");
    const [totaldisc, settotaldisc] = useState("");
    const [artist, setartist] = useState("");
    const [searchkeyword, setsearchkeyword] = useState("");
    const [price, setprice] = useState("");
    const [discountprice, setdiscountprice] = useState("");
    const [record, setrecord] = useState("");
    const [brand, setbrand] = useState("");
    const [ean, setean] = useState("");
    const [image1, setimage1] = useState({});
    const [image2, setimage2] = useState({});
    const [image3, setimage3] = useState({});
    const [image4, setimage4] = useState({});
    const [stock, setstock] = useState("");
    const [productdesc, setproductdesc] = useState("");
    const [countryorigin, setcountryorigin] = useState("");
    const [type, settype] = useState("");
    const [shippingcharge,setshippingcharge ] = useState("");

    const [moviecatdata, setmoviecatdata] = useState([]);
    const [musiccatdata, setmusiccatdata] = useState([]);
    const [healthcarecatdata, sethealthcarecatdata] = useState([]);

    const [maincategoryselect, setmaincategoryselect] = useState("");
    const [subcategorydata, setsubcategorydata] = useState([]);
    const [selectmainsubcategory, setselectmainsubcategory] = useState("");
    const [formatesdata, setformatesdata] = useState("");
    const [selectedformate, setselectedformate] = useState("");
    const [genredata, setgenredata] = useState([]);
    const [selectedgenre, setselectedgenre] = useState("");
    

    const maincategoryselectRef = useRef("");
    maincategoryselectRef.current =  maincategoryselect;

    console.log('maincategoryselectRef.current >>', maincategoryselectRef.current);

    // movies fields
    const [movieAspectRatio, setmovieAspectRatio] = useState("");
    const [movieyearofrelease, setmovieyearofrelease] = useState("");
    const [moviesubtitlelanguage, setmoviesubtitlelanguage] = useState("");
    const [moviedirector, setmoviedirector] = useState("");
    const [movievideoencoding, setmovievideoencoding] = useState("");
    const [moviemaincast, setmoviemaincast] = useState("");
    const [moviecategory, setmoviecategory] = useState("");
    const [movierating, setmovierating] = useState("");
    const [moviesubcategory, setmoviesubcategory] = useState("");
    const [movietotalrunningtime, setmovietotalrunningtime] = useState("");
    const [moviegenre, setmoviegenre] = useState("");
    const [movieformat, setmovieformat] = useState("");

    // music fields
    const [musicformat, setmusicformat] = useState("");
    const [musiccategory, setmusiccategory] = useState("");
    const [musicedition, setmusicedition] = useState("");
    const [musicsubcategory, setmusicsubcategory] = useState("");
    const [musiccomposer, setmusiccomposer] = useState("");
    const [musicgenre, setmusicgenre] = useState("");
    const [musictracklisting, setmusictracklisting] = useState("");

    // healthcare fields
    const [healthmodalname, sethealthmodalname] = useState("");
    const [healthprescription, sethealthprescription] = useState("");
    const [healthquantity, sethealthquantity] = useState("");
    const [healthadditives, sethealthadditives] = useState("");
    const [healthmeasuringunit, sethealthmeasuringunit] = useState("");
    const [healthnutrient, sethealthnutrient] = useState("");
    const [healthtype, sethealthtype] = useState("");
    const [healthusagetimmings, sethealthusagetimmings] = useState("");
    const [healthform, sethealthform] = useState("");
    const [healthkeyfeatures, sethealthkeyfeatures] = useState("");
    const [healthcomposition, sethealthcomposition] = useState("");
    const [healthintructions, sethealthintructions] = useState("");
    const [healthpackof, sethealthpackof] = useState("");
    const [healthcategory, sethealthcategory] = useState("");
    const [healthfoodpref, sethealthfoodpref] = useState("");
    const [healthsubcategory, sethealthsubcategory] = useState("");
    const [healthlicensenumber, sethealthlicensenumber] = useState("");
    const [healthgenre, sethealthgenre] = useState("");
    const [healthformat, sethealthformat] = useState("");

    


    const selectedidRef = useRef(0);
    selectedidRef.current = selectedid;



    const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const gridRef = useRef();


  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handlemultipleimages = async(text) => {
      var documentsarr1 = [];
      for(var i = 0; i < text.length; i++) {
        var obj = {};
        await getBase64(text[i]).then(result => {
            // console.log('result ...', result);
            // base64_decode($base64encodedstring)
            obj['name'] = text[i].name;
            obj['image'] = result;
        });
        documentsarr1.push(obj);
      }
      console.log('images >>', documentsarr1);
      setselectedimages(documentsarr1);
    
  }

  const handleinputchange = async (file, name) => {
    var obj = {};
    await getBase64(file).then((result) => {
      obj["name"] = file.name;
      obj["image"] = result;
    });
    // console.log('obj >>', obj);
    if(name == 'image1') {
      setimage1(obj);
    } else if(name == 'image2') {
      setimage2(obj);
    } else if(name == 'image3') {
      setimage3(obj);
    } else if(name == 'image4') {
      setimage4(obj);
    }
  };

  const handleuploadexcel = async() => {
    setbulkuploadloading(true);
    const formData = new FormData();
    formData.append('file', selectedfile);
  //   for (let i = 0; i < selectedimages.length; i++) {
  //     formData.append('images[]', selectedimages[i]); // 'images[]' so the backend handles it as an array
  // }
  formData.append('images',JSON.stringify(selectedimages));

    console.log('formData >>',formData);

    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "uploadbulkproducts", formData, headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          setshowimportmodal(false);
         fetchallcourses();
          // setshowloding(false);
          setbulkuploadloading(false);
        } else {
          alert(res.data.message);
          // setshowloding(false);
          setbulkuploadloading(false);
        }
      })
      .catch((err) => {
        // setshowloding(false);
        setbulkuploadloading(false);
        alert(err.message);
      });
      setbulkuploadloading(false);
  }

  const submithandle = async () => {
    var prevselectedlanguage = selectedlanguage;
    var prevlanarr = [];
    for(var i = 0; i < prevselectedlanguage?.length; i++) {
      prevlanarr.push(prevselectedlanguage[i]?.value);
    }
    // console.log('prevlanarr >>', prevlanarr.toString());
      setshowloding(true);
      var data = {
        skuid: skuid,
        hsncode: hsncode,
        publisher: publisher,
        languages: prevlanarr.toString(),
        title: title,
        totaldisc: totaldisc,
        artist: artist,
        searchkeyword: searchkeyword,
        price: price,
        discountprice: discountprice,
        record: record,
        brand: brand,
        ean: ean,
        image1: image1,
        image2: image2,
        image3: image3,
        image4: image4,
        stock: stock,
        productdesc: productdesc,
        countryorigin: countryorigin,
        type: type,
        shippingcharge: shippingcharge,
        aspect_ratio: movieAspectRatio,
        years_of_release: movieyearofrelease,
        subtitle_launguages: moviesubtitlelanguage,
        director: moviedirector,
        video_encoding: movievideoencoding,
        main_cast: moviemaincast,
        category: maincategoryselectRef.current,
        subcategory: selectmainsubcategory,
        running_time: movietotalrunningtime,
        rating: movierating,
        genre: selectedgenre,
        formate: selectedformate,
        edition: musicedition,
        composer: musiccomposer,
        tracks: musictracklisting,
        modal_name: healthmodalname,
        prescription_required: healthprescription,
        quantity: healthquantity,
        additives: healthadditives,
        quantity_measuring_nit: healthmeasuringunit,
        nutrition_content: healthnutrient,
        home_type: healthtype,
        usage_timings: healthusagetimmings,
        form: healthform,
        key_features: healthkeyfeatures,
        composition: healthcomposition,
        usage_instructions: healthintructions,
        pack_of: healthpackof,
        food_preference: healthfoodpref,
        license_number: healthlicensenumber
    };
    console.log('data >>', data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewproduct", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
           
            setskuid("");
            sethsncode("");
            setpublisher("");
            setselectedlanguage("");
            settitle("");
            settotaldisc("");
            setartist("");
            setsearchkeyword("");
            setprice("");
            setdiscountprice("");
            setrecord("");
            setbrand("");
            setean("");
            setimage1({});
            setimage2({});
            setimage3({});
            setimage4({});
            setstock("");
            setproductdesc("");
            setcountryorigin("");
            settype("");
            setshippingcharge("");
            setmovieAspectRatio("");
            setmovieyearofrelease("");
            setmoviesubtitlelanguage("");
            setmoviedirector("");
            setmovievideoencoding("");
            setmoviemaincast("");
            setmovierating("");
            setmaincategoryselect("");
            setselectmainsubcategory("");
        setmovietotalrunningtime("")
        setselectedgenre("")
        setselectedformate("")
        setmusicedition("")
        setmusiccomposer("")
        setmusictracklisting("")
        sethealthmodalname("")
        sethealthprescription("")
        sethealthquantity("")
        sethealthadditives("")
        sethealthmeasuringunit("")
        sethealthnutrient("")
        sethealthtype("")
        sethealthusagetimmings("")
        sethealthform("")
        sethealthkeyfeatures("")
        sethealthcomposition("")
        sethealthintructions("")
        sethealthpackof("")
        sethealthfoodpref("")
        sethealthlicensenumber("")

            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
  };

  const edithandle = async () => {
    var prevselectedlanguage = selectedlanguage;
    var prevlanarr = [];
    for(var i = 0; i < prevselectedlanguage?.length; i++) {
      prevlanarr.push(prevselectedlanguage[i]?.value);
    }
      setshowloding(true);
      var data = {
        id: selectedid,
        skuid: skuid,
        hsncode: hsncode,
        publisher: publisher,
        languages: prevlanarr.toString(),
        title: title,
        totaldisc: totaldisc,
        artist: artist,
        searchkeyword: searchkeyword,
        price: price,
        discountprice: discountprice,
        record: record,
        brand: brand,
        ean: ean,
        image1: image1,
        image2: image2,
        image3: image3,
        image4: image4,
        stock: stock,
        productdesc: productdesc,
        countryorigin: countryorigin,
        type: type,
        shippingcharge: shippingcharge,
        aspect_ratio: movieAspectRatio,
        years_of_release: movieyearofrelease,
        subtitle_launguages: moviesubtitlelanguage,
        director: moviedirector,
        video_encoding: movievideoencoding,
        main_cast: moviemaincast,
        category: maincategoryselectRef.current,
        subcategory: selectmainsubcategory,
        running_time: movietotalrunningtime,
        rating: movierating,
        genre: selectedgenre,
        formate: selectedformate,
        edition: musicedition,
        composer: musiccomposer,
        tracks: musictracklisting,
        modal_name: healthmodalname,
        prescription_required: healthprescription,
        quantity: healthquantity,
        additives: healthadditives,
        quantity_measuring_nit: healthmeasuringunit,
        nutrition_content: healthnutrient,
        home_type: healthtype,
        usage_timings: healthusagetimmings,
        form: healthform,
        key_features: healthkeyfeatures,
        composition: healthcomposition,
        usage_instructions: healthintructions,
        pack_of: healthpackof,
        food_preference: healthfoodpref,
        license_number: healthlicensenumber
    };
    console.log('data >>', data);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .post(apiUrl + "editproduct", data, headers)
      .then((res) => {
        // console.log('res >>>', res.data)
        if (res.data.status == "success") {
          seteditshowmodal(false);
          setskuid("");
          sethsncode("");
          setpublisher("");
          setselectedlanguage("");
          settitle("");
          settotaldisc("");
          setartist("");
          setsearchkeyword("");
          setprice("");
          setdiscountprice("");
          setrecord("");
          setbrand("");
          setean("");
          setimage1({});
          setimage2({});
          setimage3({});
          setimage4({});
          setstock("");
          setproductdesc("");
          setcountryorigin("");
          settype("");
          setshippingcharge("");
          setmovieAspectRatio("");
          setmovieyearofrelease("");
          setmoviesubtitlelanguage("");
          setmoviedirector("");
          setmovievideoencoding("");
          setmoviemaincast("");
          setmovierating("");
          setmaincategoryselect("");
          setselectmainsubcategory("");
      setmovietotalrunningtime("")
      setselectedgenre("")
      setselectedformate("")
      setmusicedition("")
      setmusiccomposer("")
      setmusictracklisting("")
      sethealthmodalname("")
      sethealthprescription("")
      sethealthquantity("")
      sethealthadditives("")
      sethealthmeasuringunit("")
      sethealthnutrient("")
      sethealthtype("")
      sethealthusagetimmings("")
      sethealthform("")
      sethealthkeyfeatures("")
      sethealthcomposition("")
      sethealthintructions("")
      sethealthpackof("")
      sethealthfoodpref("")
      sethealthlicensenumber("")

          fetchallcourses();
          setshowloding(false);
        } else {
          alert(res.data.message);
          setshowloding(false);
        }
      })
      .catch((err) => {
        setshowloding(false);
        alert(err.message);
      });
    setshowloding(false);
    };

    const clickhandle = () =>  {
        // console.log('Mission Launched')
      }

      const editlocation = async(id) => {
        // console.log('id >>>', id);
        setselectedid(id);
        fetchproductdetails(id);
        // seteditshowmodal(true);
      }

      const deletecourse = async (id, title) => {
        console.log('id >>', id);
        const result = window.confirm("Are you sure you want to delete " + title);
        if (result) {
          var data = JSON.stringify({
            id: id,
          });
          const headers = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + userdata?.token,
            },
          };
          let resp = await axios
            .post(apiUrl + "deleteproduct", data, headers)
            .then((res) => {
              if (res.data.status == "success") {
                fetchallcourses();
              } else {
              }
            })
            .catch((err) => {
              alert(err.message);
            });
        } else {
          // User clicked Cancel or closed the dialog
          // console.log("User clicked Cancel");
        }
      };

      const defaultColDef = {
        // flex: 1,
        resizable: true,
        sortable: true  
      };


    const fetchallcourses = async () => {
        setshowloding(true);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .get(apiUrl + "getallproducts", headers)
          .then((res) => {
            if (res.data.status == "success") {
              // console.log('getallcategories >>', res.data);
              var prevdata = res.data.data;
              if(prevdata?.length > 0) {
                var columnsarr = [];
                var hidecolumns = ['id', 
                  'created_at', 
                  'updated_at', 
                  'sort',
                  'slug'
                ];
                var hidefilters = [];
                var columns = Object.keys(prevdata[0]);
                // console.log('columns  >>>', columns );
                for(var i = 0; i < columns?.length;i++) {
                  if(!hidecolumns.includes(columns[i])) {
                    var obj = {};
                    obj.field = columns[i];
                    if (!hidefilters.includes(columns[i])) {
                      obj.filter = true;
                      obj.filterParams = {
                        filterOptions: ["contains"],
                        maxNumConditions: 1,
                      };
                    }
                    // console.log('obj >>', obj)
                    columnsarr.push(obj);
                  }
                }

                // for (var i = 0; i < columns?.length; i++) {
                //   if (!hidecolumns.includes(columns[i])) {
                //     var obj = {};
                //     obj.field = columns[i];
                //     if (!hidefilters.includes(columns[i])) {
                //       obj.filter = true;
                //       obj.filterParams = {
                //         filterOptions: ["contains"],
                //         maxNumConditions: 1,
                //       };
                //     }
                //     //   console.log('obj >>', obj)
                //     columnsarr.push(obj);
                //   }
                // }

                //Image
                if(columns.includes('image')) {
                  var imageobj = 
              {
                field: "Image",
                headerName: "Image",
                cellRenderer: (props) => {
                  // console.log('image props >>', props);
                  return (
                    <Link
                    to={assetsUrl+'sub-categories/'+props?.data?.image}
                    target="_blank"
                    style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </Link>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(imageobj);
                }

                //created_at
                if(columns.includes('created_at')) {
                  var craetddatobj = 
              {
                field: "Date",
                headerName: "Date",
                cellRenderer: (props) => {
                  // console.log('date props >>', props);
                  return (
                         <div>
                          <p className="created_at_value">{moment(props?.data?.created_at).format('DD MMMM YYYY HH:mm')}</p>
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(craetddatobj);
                }
              
                //edit
              var editobj = 
              {
                field: "edit",
                headerName: "Edit",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data?.id);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
                width: 80
              
              }
              columnsarr.push(editobj);
                //delete
                var deleteobj = 
                {
                  field: "delete",
                  headerName: "Delete",
                  cellRenderer: (props) => {
                    
                    return (
                      <div
                            onClick={() => {
                              deletecourse(props?.data?.id, props?.data?.name);
                              // console.log('props >>', props?.data);
                            }}
                            style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="sidebaricons"
                              style={{ color: "red" }}
                            />
                          </div>
                    )
                  },
                  cellRendererParams: {
                    onClick: clickhandle,
                  },
                  width: 80
                }
                columnsarr.push(deleteobj);
                setColDefs(columnsarr);
                setRowData(prevdata);
              }
              
              // setcoursesdata(res.data.data);
            } else {
              // alert(res.data.message);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
          setshowloding(false);
      };
    
    const fetchcategories = async() => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "getallcategories", headers)
        .then((res) => {
          if (res.data.status == "success") {
            var alldata = res.data.data;
            var seperateobj = {
              "Music" : [],
              "Movie" : [],
              "Health Care": []
            }
            // console.log('alldata >>', alldata);
            for(var i = 0; i < alldata?.length; i++) {
              var obj = {};
              obj['value'] = alldata[i]['id'];
              obj['label'] = alldata[i]['name'];
              var prevarr = seperateobj[alldata[i]['type']];
              prevarr.push(obj);
              seperateobj[alldata[i]['type']] = prevarr;
            }
            setmusiccatdata(seperateobj['Music']);
            setmoviecatdata(seperateobj['Movie']);
            sethealthcarecatdata(seperateobj['Health Care']);
            // console.log('seperateobj >>', seperateobj);
          } else {

          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    const fetchsubcategories = async(subid) => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      var data = {
        id: subid
      }
      let resp = await axios
        .post(apiUrl + "getfetchsubcategoriesbyid",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            var alldata = res.data.data;
            console.log('alldata >>', alldata);
            if(alldata?.length > 0) {
              var newarr = []
              for(var i = 0; i < alldata?.length; i++) {
                var obj = {};
                obj['value'] = alldata[i]['slug'];
                obj['label'] = alldata[i]['name'];
                newarr.push(obj)
              }
              setsubcategorydata(newarr);
            } else {
              setsubcategorydata([]);
            }
            
            
            // console.log('seperateobj >>', seperateobj);
          } else {

          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }
    const fetchallformates = async() => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "getallformates", headers)
        .then((res) => {
          if (res.data.status == "success") {
            var alldata = res.data.data;
            // console.log('getallformates alldata >>', alldata);

            if(alldata?.length > 0) {
              var newarr = []
              for(var i = 0; i < alldata?.length; i++) {
                var obj = {};
                obj['value'] = alldata[i]['name'];
                obj['label'] = alldata[i]['name'];
                newarr.push(obj)
              }
              setformatesdata(newarr);
            } else {
              setformatesdata([]);
            }
            
            
            // console.log('seperateobj >>', seperateobj);
          } else {

          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    const fetchgenrebytype = async(subid) => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      var data = {
        type: subid
      }
      let resp = await axios
        .post(apiUrl + "getfetchfiltersbyid",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            var alldata = res.data.data;
            console.log('alldata >>', alldata);
            if(alldata?.length > 0) {
              var newarr = []
              for(var i = 0; i < alldata?.length; i++) {
                var obj = {};
                obj['value'] = alldata[i]['name'];
                obj['label'] = alldata[i]['name'];
                newarr.push(obj)
              }
              setgenredata(newarr);
            } else {
              setgenredata([]);
            }
            
            
            // console.log('seperateobj >>', seperateobj);
          } else {

          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    const fetchproductdetails = async(id) => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      var data = {
        id: id
      }
      let resp = await axios
        .post(apiUrl + "fetchproductdetailsbyid",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            var alldata = res.data.data;
            console.log('alldata >>', alldata);
            if(alldata?.length > 0) {
           

            setselectedid(alldata[0]?.id)

            if(alldata[0]?.language) {
              var prevdata = alldata[0]?.language.split(',');
              console.log('prevdata language >>', prevdata);
              var newarr = [];
              for(var i = 0; i < prevdata.length; i++) {
                var obj = {}
                obj['value'] = prevdata[i];
                obj['label'] = prevdata[i];
                newarr.push(obj);
              }
              setselectedlanguage(newarr);
            } else {
              setselectedlanguage("");
            }
            
            setskuid(alldata[0]?.seller_sku_id);
            sethsncode(alldata[0]?.code);
            setpublisher(alldata[0]?.publisher);
            
            settitle(alldata[0]?.name);
            settotaldisc(alldata[0]?.no_of_disc);
            setartist(alldata[0]?.artist);
            setsearchkeyword(alldata[0]?.search_keywords);
            setprice(alldata[0]?.price);
            setdiscountprice(alldata[0]?.discountprice);
            setrecord(alldata[0]?.record_label_studio);
            setbrand(alldata[0]?.brand);
            setean(alldata[0]?.ean_upc);
            setimage1(alldata[0]?.image1);
            setimage2(alldata[0]?.image2);
            setimage3(alldata[0]?.image3);
            setimage4(alldata[0]?.image4);
            setstock(alldata[0]?.stock);
            setproductdesc(alldata[0]?.description);
            setcountryorigin(alldata[0]?.origin);
            settype(alldata[0]?.type);
            setshippingcharge(alldata[0]?.shipping_charges);
            setmovieAspectRatio(alldata[0]?.aspect_ratio);
            setmovieyearofrelease(alldata[0]?.years_of_release);
            setmoviesubtitlelanguage(alldata[0]?.subtitle_launguages);
            setmoviedirector(alldata[0]?.director);
            setmovievideoencoding(alldata[0]?.video_encoding);
            setmoviemaincast(alldata[0]?.main_cast);
            setmaincategoryselect(alldata[0]?.category);
            setselectmainsubcategory(alldata[0]?.subcategory);
        setmovietotalrunningtime(alldata[0]?.running_time)
        setmovierating(alldata[0]?.rating)
        setselectedgenre(alldata[0]?.genre)
        setselectedformate(alldata[0]?.formate)
        setmusicedition(alldata[0]?.edition)
        setmusiccomposer(alldata[0]?.composer)
        setmusictracklisting(alldata[0]?.tracks)
        sethealthmodalname(alldata[0]?.modal_name)
        sethealthprescription(alldata[0]?.prescription_required)
        sethealthquantity(alldata[0]?.quantity)
        sethealthadditives(alldata[0]?.additives)
        sethealthmeasuringunit(alldata[0]?.quantity_measuring_nit)
        sethealthnutrient(alldata[0]?.nutrition_content)
        sethealthtype(alldata[0]?.home_type)
        sethealthusagetimmings(alldata[0]?.usage_timings)
        sethealthform(alldata[0]?.form)
        sethealthkeyfeatures(alldata[0]?.key_features)
        sethealthcomposition(alldata[0]?.composition)
        sethealthintructions(alldata[0]?.usage_instructions)
        sethealthpackof(alldata[0]?.pack_of)
        sethealthfoodpref(alldata[0]?.food_preference)
        sethealthlicensenumber(alldata[0]?.license_number)

        fetchgenrebytype(alldata[0]?.type)

        seteditshowmodal(true);


            }
            // console.log('seperateobj >>', seperateobj);
          } else {

          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }

    const changefunctypeonadd = (val) => {
      setmaincategoryselect("");
      setsubcategorydata([]);
      setselectmainsubcategory("")
      setselectedformate("");
      if(val) {
        fetchgenrebytype(val)
      }
    }

    const getParams = () => {
      return {
        allColumns: true,
        // columnKeys: ['userName', 'userMobile', 'orderId', 'orderDate', 'trackingUrl', 'billingCost', 'deliveryStatus', 'status'],
        columnKeys: [
          "name",
          "sellerSkuId",
          "price",
          "category",
          "subcategory",
          "stock",
          "artist",
          "formats",
          "discountPercent",
          "slug",
          "Date",
      ],
        fileName: 'products.csv',
        // Process each cell's value before exporting, especially for order_details
      // processCellCallback: (params) => {
      //   if (params.column.getColId() === 'order_details') {
      //     // Format order_details as a string: 'Product: [name], Qty: [quantity]'
      //     if (Array.isArray(params.value)) {
      //       return params.value.map((detail, index) => `Product ${index + 1}: ${detail.name}, Qty: ${detail.qty}`).join('\n');
      //     }
      //     return '';  // Return empty string if no order_details
      //   }
      //   return params.value;  // For other fields, return the default value
      // }
      };
    };

    const onExportClick = () => {
      gridRef.current.api.exportDataAsCsv(getParams());
  
    };

    // useEffect(() => {
    //   // setmaincategoryselect("");
    //   setsubcategorydata([]);
    //   // setselectmainsubcategory("")
    //   // setselectedformate("");
    //   if(type) {
    //     fetchgenrebytype(type)
    //   }
      
    // }, [type])
  
  
    useEffect(() => {
      // console.log('maincategoryselectRef?.current >>>', maincategoryselectRef?.current);
      if(maincategoryselectRef?.current) {
        // setselectmainsubcategory("")
        fetchsubcategories(maincategoryselectRef?.current)
      }
    }, [maincategoryselectRef?.current]);


    useEffect(() => {
        if (userdata && Object.keys(userdata).length > 0) {
          //   fetchdata();
          fetchallcourses();
          fetchcategories();
          fetchallformates();
        } else {
          navigate("/newadminlogin");
        }
      }, []);
    return (
      <div className="adminmainpagelayout">
        <div className="admin-categorymaindiv">
          <p className="admin-page-title">View All Products</p>
          <div className="table-exportcsv-row">
          <div className="exportcsv-div" onClick={onExportClick}>
            <FontAwesomeIcon
              icon={faDownload}
              className="exporticon"
              style={{ color: "black" }}
            />
            <p>Export</p>
          </div>
          </div>
          <div
            className="category-add-cat-btn"
            style={{marginLeft: 20}}
            onClick={() => {
              setshowimportmodal(true);
            }}
          >
            <p>Bulk Upload</p>
          </div>
          <div
            className="category-add-cat-btn"
            style={{marginLeft: 20}}
            onClick={() => {
              setskuid("");
              sethsncode("");
              setpublisher("");
              setselectedlanguage("");
              settitle("");
              settotaldisc("");
              setartist("");
              setsearchkeyword("");
              setprice("");
              setdiscountprice("");
              setrecord("");
              setbrand("");
              setean("");
              setimage1({});
              setimage2({});
              setimage3({});
              setimage4({});
              setstock("");
              setproductdesc("");
              setcountryorigin("");
              settype("");
              setshippingcharge("");
              setmovieAspectRatio("");
              setmovieyearofrelease("");
              setmoviesubtitlelanguage("");
              setmoviedirector("");
              setmovievideoencoding("");
              setmoviemaincast("");
              setmovierating("");
              setmaincategoryselect("");
              setselectmainsubcategory("");
              setmovietotalrunningtime("");
              setselectedgenre("");
              setselectedformate("");
              setmusicedition("");
              setmusiccomposer("");
              setmusictracklisting("");
              sethealthmodalname("");
              sethealthprescription("");
              sethealthquantity("");
              sethealthadditives("");
              sethealthmeasuringunit("");
              sethealthnutrient("");
              sethealthtype("");
              sethealthusagetimmings("");
              sethealthform("");
              sethealthkeyfeatures("");
              sethealthcomposition("");
              sethealthintructions("");
              sethealthpackof("");
              sethealthfoodpref("");
              sethealthlicensenumber("");
              setshowmodal(true);
            }}
          >
            <p>Add Product</p>
          </div>
        </div>

        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: 500, marginTop: 15 }}
        >
          <AgGridReact
          ref={gridRef}
            // modules={AllModules}
            rowData={rowData}
            columnDefs={colDefs}
            // defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            rowSelection={"multiple"}
            pagination={true}
            paginationPageSize={10}
            paginationPageSizeSelector={paginationPageSizeSelector}
            // onGridReady={onGridReady}
          />
        </div>

        {/* Import Product Modal */}
         <Modal
          show={showimportmodal}
          onHide={() => setshowimportmodal(false)}
          size="md"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>Bulk Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
              <div className="row">
              <div className="col-md-12">
              <label className="admin-label">Upload Excel / CSV</label>
              <input type="file"
                onChange={(t) => {setselectedfile(t.target.files[0]);}}
                  accept=".xlsx, .xls" 
                  className="form-control admininput"
                />
                </div>
                <div className="col-md-12 mt-3">
                <label className="admin-label">Upload Images</label>
              <input type="file"
                onChange={(t) => {handlemultipleimages(
                  t.target.files
                )}}
                  accept=".png, .jpg, .jpeg" 
                  className="form-control admininput"
                  multiple
                />
                </div>
                <div className="col-md-12">
                 <div
                className="webadmin-modalbtn"
                onClick={() => {
                  handleuploadexcel();
                }}
              >
                <p>
                  {bulkuploadloading ? (
                    <Spinner size={15} className="spinnerCenter" />
                  ) : (
                    "Submit"
                  )}
                </p>
              </div>
              </div>
                
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*Add modal */}
        <Modal
          show={showmodal}
          onHide={() => setshowmodal(false)}
          size="lg"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
              <div className="row">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Seller SKU ID</label>
                    <input
                      value={skuid}
                      onChange={(t) => {
                        setskuid(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter SKU ID"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">HSN Code</label>
                    <input
                      value={hsncode}
                      onChange={(t) => {
                        sethsncode(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter HSN Code"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Publisher</label>
                    <input
                      value={publisher}
                      onChange={(t) => {
                        setpublisher(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Publisher"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Language</label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedlanguage}
                      onChange={setselectedlanguage}
                      options={languagesdata}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Title</label>
                    <input
                      value={title}
                      onChange={(t) => {
                        settitle(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Total Number of Discs</label>
                    <input
                      value={totaldisc}
                      onChange={(t) => {
                        settotaldisc(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter number of Discs"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Artist</label>
                    <input
                      value={artist}
                      onChange={(t) => {
                        setartist(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Artist"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Search Keywords</label>
                    <input
                      value={searchkeyword}
                      onChange={(t) => {
                        setsearchkeyword(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Keywords"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-3">
                  <div className="">
                    <label className="admin-label">Price</label>
                    <input
                      value={price}
                      onChange={(t) => {
                        setprice(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="">
                    <label className="admin-label">Discount Price (%)</label>
                    <input
                      value={discountprice}
                      onChange={(t) => {
                        // setdiscountprice(t.target.value);
                        let value = parseInt(t.target.value, 10);

                        // Ensure the value is between 0 and 100
                        if (value <= 100 && value >= 0) {
                          setdiscountprice(value);
                        } else if (isNaN(value)) {
                          setdiscountprice("");
                        }
                      }}
                      type="number"
                      maxLength={2}
                      max={100}
                      className="form-control admininput"
                      placeholder="Discount Price in %"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Record Label/Studio</label>
                    <input
                      value={record}
                      onChange={(t) => {
                        setrecord(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Record Label/Studio"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Brand</label>
                    <input
                      value={brand}
                      onChange={(t) => {
                        setbrand(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">EAN/UPC</label>
                    <input
                      value={ean}
                      onChange={(t) => {
                        setean(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter EAN/UPC"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Thumbnail Image</label>
                    <input
                      // value={image1}
                      onChange={(t) => {
                        handleinputchange(t.target.files[0], "image1");
                        // setname(t.target.value);
                      }}
                      type="file"
                      accept=""
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Second Image</label>
                    <input
                      // value={name}
                      onChange={(t) => {
                        // setname(t.target.value);
                        handleinputchange(t.target.files[0], "image2");
                      }}
                      type="file"
                      accept=""
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Third Image</label>
                    <input
                      // value={name}
                      onChange={(t) => {
                        // setname(t.target.value);
                        handleinputchange(t.target.files[0], "image3");
                      }}
                      type="file"
                      accept=""
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Fourth Image</label>
                    <input
                      // value={name}
                      onChange={(t) => {
                        // setname(t.target.value);
                        handleinputchange(t.target.files[0], "image4");
                      }}
                      type="file"
                      accept=""
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Stock</label>
                    <input
                      value={stock}
                      onChange={(t) => {
                        setstock(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Stock"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="admin-label">Country of origin</label>
                    <input
                      value={countryorigin}
                      onChange={(t) => {
                        setcountryorigin(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Country Origin"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="admin-label">Type</label>
                    <select
                      className="form-control admininput"
                      value={type}
                      onChange={(t) => {
                        settype(t.target.value);
                        changefunctypeonadd(t.target.value);
                      }}
                    >
                      <option value={""} disabled>
                        Select Type
                      </option>
                      <option value={"Movie"}>Movie</option>
                      <option value={"Music"}>Music</option>
                      {/* <option value={"Health Care"}>Health Care</option> */}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mt-0">
                    <label className="admin-label">Product Description</label>
                    <Editor
                      name="name"
                      value={productdesc}
                      onChange={(data) => {
                        setproductdesc(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="admin-label">Shipping Charges</label>
                    <input
                      value={shippingcharge}
                      onChange={(t) => {
                        setshippingcharge(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                      placeholder="Enter Shipping Charges"
                    />
                  </div>
                </div>
              </div>

              {/* extra fields */}

              <p className="addproductfieldsections">-- {type} Fields --</p>

              {type && type == "Movie" ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Usage Instruction</label>
                        <input
                          value={movieAspectRatio}
                          onChange={(t) => {
                            setmovieAspectRatio(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Usage Instruction"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Year of Release</label>
                        <input
                          value={movieyearofrelease}
                          onChange={(t) => {
                            setmovieyearofrelease(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Year of Release"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">
                          Subtitle Languages
                        </label>
                        <input
                          value={moviesubtitlelanguage}
                          onChange={(t) => {
                            setmoviesubtitlelanguage(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Subtitle Languages"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Director</label>
                        <input
                          value={moviedirector}
                          onChange={(t) => {
                            setmoviedirector(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Director"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Video Encoding</label>
                        <input
                          value={movievideoencoding}
                          onChange={(t) => {
                            setmovievideoencoding(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Video Encoding"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Main Cast</label>
                        <input
                          value={moviemaincast}
                          onChange={(t) => {
                            setmoviemaincast(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Main Cast"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Category</label>
                        <select
                          className="form-control admininput"
                          value={maincategoryselectRef?.current}
                          onChange={(t) => {
                            setmaincategoryselect(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select category
                          </option>
                          {moviecatdata?.length > 0
                            ? moviecatdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Rating</label>
                        <input
                          value={movierating}
                          onChange={(t) => {
                            setmovierating(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Rating"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Sub Category</label>
                        <select
                          className="form-control admininput"
                          value={selectmainsubcategory}
                          onChange={(t) => {
                            setselectmainsubcategory(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select sub category
                          </option>
                          {subcategorydata?.length > 0
                            ? subcategorydata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">
                          Total Running Time
                        </label>
                        <input
                          value={movietotalrunningtime}
                          onChange={(t) => {
                            setmovietotalrunningtime(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Total Running Time"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Genre</label>
                        <select
                          className="form-control admininput"
                          value={selectedgenre}
                          onChange={(t) => {
                            setselectedgenre(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select genre
                          </option>
                          {genredata?.length > 0
                            ? genredata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Format</label>
                        <select
                          className="form-control admininput"
                          value={selectedformate}
                          onChange={(t) => {
                            setselectedformate(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select Format
                          </option>
                          {formatesdata?.length > 0
                            ? formatesdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              ) : type == "Music" ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Format</label>
                        <select
                          className="form-control admininput"
                          value={selectedformate}
                          onChange={(t) => {
                            setselectedformate(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select Format
                          </option>
                          {formatesdata?.length > 0
                            ? formatesdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Category</label>
                        <select
                          className="form-control admininput"
                          value={maincategoryselectRef?.current}
                          onChange={(t) => {
                            setmaincategoryselect(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select category
                          </option>
                          {musiccatdata?.length > 0
                            ? musiccatdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Edition</label>
                        <input
                          value={musicedition}
                          onChange={(t) => {
                            setmusicedition(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Edition"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Sub Category</label>
                        <select
                          className="form-control admininput"
                          value={selectmainsubcategory}
                          onChange={(t) => {
                            setselectmainsubcategory(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select sub category
                          </option>
                          {subcategorydata?.length > 0
                            ? subcategorydata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Music Composer</label>
                        <input
                          value={musiccomposer}
                          onChange={(t) => {
                            setmusiccomposer(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Format"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Genre</label>
                        <select
                          className="form-control admininput"
                          value={selectedgenre}
                          onChange={(t) => {
                            setselectedgenre(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select genre
                          </option>
                          {genredata?.length > 0
                            ? genredata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="">
                      <label className="admin-label">Track listing</label>
                      <Editor
                        name="name"
                        value={musictracklisting}
                        onChange={(data) => {
                          setmusictracklisting(data);
                        }}
                        editorLoaded={editorLoaded}
                      />
                    </div>
                  </div>
                </>
              ) : type == "Health Care" ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Model Name</label>
                        <input
                          value={healthmodalname}
                          onChange={(t) => {
                            sethealthmodalname(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Model Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Prescription</label>
                        <input
                          value={healthprescription}
                          onChange={(t) => {
                            sethealthprescription(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Prescription"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Quantity</label>
                        <input
                          value={healthquantity}
                          onChange={(t) => {
                            sethealthquantity(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Quantity"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Additives</label>
                        <input
                          value={healthadditives}
                          onChange={(t) => {
                            sethealthadditives(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Additives"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">
                          Quantity - Measuring Unit
                        </label>
                        <input
                          value={healthmeasuringunit}
                          onChange={(t) => {
                            sethealthmeasuringunit(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Quantity - Measuring Unit"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Nutrient Content</label>
                        <input
                          value={healthnutrient}
                          onChange={(t) => {
                            sethealthnutrient(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Nutrient Content"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Type</label>
                        <input
                          value={healthtype}
                          onChange={(t) => {
                            sethealthtype(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Type"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Usage Timings</label>
                        <input
                          value={healthusagetimmings}
                          onChange={(t) => {
                            sethealthusagetimmings(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Usage Timings"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Form</label>
                        <input
                          value={healthform}
                          onChange={(t) => {
                            sethealthform(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Form"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Key Features</label>
                        <input
                          value={healthkeyfeatures}
                          onChange={(t) => {
                            sethealthkeyfeatures(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Key Features"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Composition</label>
                        <input
                          value={healthcomposition}
                          onChange={(t) => {
                            sethealthcomposition(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Composition"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">
                          Usage Instructions
                        </label>
                        <input
                          value={healthintructions}
                          onChange={(t) => {
                            sethealthintructions(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Usage Instructions"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Pack Of</label>
                        <input
                          value={healthpackof}
                          onChange={(t) => {
                            sethealthpackof(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Pack Of"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Category</label>
                        <select
                          className="form-control admininput"
                          value={maincategoryselectRef?.current}
                          onChange={(t) => {
                            setmaincategoryselect(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select category
                          </option>
                          {healthcarecatdata?.length > 0
                            ? healthcarecatdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Food Preference</label>
                        <input
                          value={healthfoodpref}
                          onChange={(t) => {
                            sethealthfoodpref(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Food Preference"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Sub Category</label>
                        <select
                          className="form-control admininput"
                          value={selectmainsubcategory}
                          onChange={(t) => {
                            setselectmainsubcategory(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select sub category
                          </option>
                          {subcategorydata?.length > 0
                            ? subcategorydata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">License Number</label>
                        <input
                          value={healthlicensenumber}
                          onChange={(t) => {
                            sethealthlicensenumber(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter License Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Genre</label>
                        <select
                          className="form-control admininput"
                          value={selectedgenre}
                          onChange={(t) => {
                            setselectedgenre(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select genre
                          </option>
                          {genredata?.length > 0
                            ? genredata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Format</label>
                        <select
                          className="form-control admininput"
                          value={selectedformate}
                          onChange={(t) => {
                            setselectedformate(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select Format
                          </option>
                          {formatesdata?.length > 0
                            ? formatesdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                    <div className="">
                      <label className="admin-label">Format</label>
                      <input
                        value={musiccategory}
                        onChange={(t) => {
                          setmusiccategory(t.target.value);
                        }}
                        type="text"
                        className="form-control admininput"
                        placeholder="Enter Format"
                      />
                    </div>
                  </div> */}
                  </div>
                </>
              ) : null}

              <div
                className="webadmin-modalbtn"
                onClick={() => {
                  submithandle();
                }}
              >
                <p>
                  {showloding ? (
                    <Spinner size={15} className="spinnerCenter" />
                  ) : (
                    "Submit"
                  )}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*Edit modal */}
        <Modal
          show={editshowmodal}
          onHide={() => seteditshowmodal(false)}
          size="lg"
          className="custom-width-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 19 }}>Edit Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="webadmin-modalmainbody">
              <div className="row">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Seller SKU ID</label>
                    <input
                      value={skuid}
                      onChange={(t) => {
                        setskuid(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter SKU ID"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">HSN Code</label>
                    <input
                      value={hsncode}
                      onChange={(t) => {
                        sethsncode(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter HSN Code"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Publisher</label>
                    <input
                      value={publisher}
                      onChange={(t) => {
                        setpublisher(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Publisher"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Language</label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedlanguage}
                      onChange={setselectedlanguage}
                      options={languagesdata}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Title</label>
                    <input
                      value={title}
                      onChange={(t) => {
                        settitle(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Total Number of Discs</label>
                    <input
                      value={totaldisc}
                      onChange={(t) => {
                        settotaldisc(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter number of Discs"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Artist</label>
                    <input
                      value={artist}
                      onChange={(t) => {
                        setartist(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Artist"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Search Keywords</label>
                    <input
                      value={searchkeyword}
                      onChange={(t) => {
                        setsearchkeyword(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Keywords"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-3">
                  <div className="">
                    <label className="admin-label">Price</label>
                    <input
                      value={price}
                      onChange={(t) => {
                        setprice(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="">
                    <label className="admin-label">Discount Price (%)</label>
                    <input
                      value={discountprice}
                      onChange={(t) => {
                        // setdiscountprice(t.target.value);
                        let value = parseInt(t.target.value, 10);

                        // Ensure the value is between 0 and 100
                        if (value <= 100 && value >= 0) {
                          setdiscountprice(value);
                        } else if (isNaN(value)) {
                          setdiscountprice("");
                        }
                      }}
                      type="number"
                      maxLength={2}
                      max={100}
                      className="form-control admininput"
                      placeholder="Discount Price in %"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Record Label/Studio</label>
                    <input
                      value={record}
                      onChange={(t) => {
                        setrecord(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Record Label/Studio"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Brand</label>
                    <input
                      value={brand}
                      onChange={(t) => {
                        setbrand(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">EAN/UPC</label>
                    <input
                      value={ean}
                      onChange={(t) => {
                        setean(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter EAN/UPC"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Thumbnail Image</label>
                    <input
                      // value={image1}
                      onChange={(t) => {
                        handleinputchange(t.target.files[0], "image1");
                        // setname(t.target.value);
                      }}
                      type="file"
                      accept=""
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                    {image1 && typeof image1 == "string" ? (
                      <div className="editimagediv">
                        <img src={assetsUrl + "products/" + image1} />
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="editimagecancelicon"
                          style={{ color: "red" }}
                          onClick={() => {
                            setimage1({});
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Second Image</label>
                    <input
                      // value={name}
                      onChange={(t) => {
                        // setname(t.target.value);
                        handleinputchange(t.target.files[0], "image2");
                      }}
                      type="file"
                      accept=""
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                    {image2 && typeof image2 == "string" ? (
                      <div className="editimagediv">
                        <img src={assetsUrl + "products/" + image2} />
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="editimagecancelicon"
                          style={{ color: "red" }}
                          onClick={() => {
                            setimage2({});
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Third Image</label>
                    <input
                      // value={name}
                      onChange={(t) => {
                        // setname(t.target.value);
                        handleinputchange(t.target.files[0], "image3");
                      }}
                      type="file"
                      accept=""
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                    {image3 && typeof image3 == "string" ? (
                      <div className="editimagediv">
                        <img src={assetsUrl + "products/" + image3} />
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="editimagecancelicon"
                          style={{ color: "red" }}
                          onClick={() => {
                            setimage3({});
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Fourth Image</label>
                    <input
                      // value={name}
                      onChange={(t) => {
                        // setname(t.target.value);
                        handleinputchange(t.target.files[0], "image4");
                      }}
                      type="file"
                      accept=""
                      className="form-control admininput"
                      placeholder="Enter Brand"
                    />
                    {image4 && typeof image4 == "string" ? (
                      <div className="editimagediv">
                        <img src={assetsUrl + "products/" + image4} />
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="editimagecancelicon"
                          style={{ color: "red" }}
                          onClick={() => {
                            setimage4({});
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="">
                    <label className="admin-label">Stock</label>
                    <input
                      value={stock}
                      onChange={(t) => {
                        setstock(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Stock"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="admin-label">Country of origin</label>
                    <input
                      value={countryorigin}
                      onChange={(t) => {
                        setcountryorigin(t.target.value);
                      }}
                      type="text"
                      className="form-control admininput"
                      placeholder="Enter Country Origin"
                    />
                  </div>
                  <div className="mt-3">
                    <label className="admin-label">Type</label>
                    <select
                      className="form-control admininput"
                      value={type}
                      onChange={(t) => {
                        settype(t.target.value);
                        changefunctypeonadd(t.target.value);
                      }}
                    >
                      <option value={""} disabled>
                        Select Type
                      </option>
                      <option value={"Movie"}>Movie</option>
                      <option value={"Music"}>Music</option>
                      {/* <option value={"Health Care"}>Health Care</option> */}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mt-0">
                    <label className="admin-label">Product Description</label>
                    <Editor
                      name="name"
                      value={productdesc}
                      onChange={(data) => {
                        setproductdesc(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <div className="mt-3">
                    <label className="admin-label">Shipping Charges</label>
                    <input
                      value={shippingcharge}
                      onChange={(t) => {
                        setshippingcharge(t.target.value);
                      }}
                      type="number"
                      className="form-control admininput"
                      placeholder="Enter Shipping Charges"
                    />
                  </div>
                </div>
              </div>

              {/* extra fields */}

              <p className="addproductfieldsections">-- {type} Fields --</p>

              {type && type == "Movie" ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Usage Instruction</label>
                        <input
                          value={movieAspectRatio}
                          onChange={(t) => {
                            setmovieAspectRatio(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Usage Instruction"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Year of Release</label>
                        <input
                          value={movieyearofrelease}
                          onChange={(t) => {
                            setmovieyearofrelease(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Year of Release"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">
                          Subtitle Languages
                        </label>
                        <input
                          value={moviesubtitlelanguage}
                          onChange={(t) => {
                            setmoviesubtitlelanguage(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Subtitle Languages"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Director</label>
                        <input
                          value={moviedirector}
                          onChange={(t) => {
                            setmoviedirector(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Director"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Video Encoding</label>
                        <input
                          value={movievideoencoding}
                          onChange={(t) => {
                            setmovievideoencoding(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Video Encoding"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Main Cast</label>
                        <input
                          value={moviemaincast}
                          onChange={(t) => {
                            setmoviemaincast(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Main Cast"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Category</label>
                        <select
                          className="form-control admininput"
                          value={maincategoryselectRef?.current}
                          onChange={(t) => {
                            setmaincategoryselect(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select category
                          </option>
                          {moviecatdata?.length > 0
                            ? moviecatdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Rating</label>
                        <input
                          value={movierating}
                          onChange={(t) => {
                            setmovierating(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Rating"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Sub Category</label>
                        <select
                          className="form-control admininput"
                          value={selectmainsubcategory}
                          onChange={(t) => {
                            setselectmainsubcategory(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select sub category
                          </option>
                          {subcategorydata?.length > 0
                            ? subcategorydata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">
                          Total Running Time
                        </label>
                        <input
                          value={movietotalrunningtime}
                          onChange={(t) => {
                            setmovietotalrunningtime(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Total Running Time"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Genre</label>
                        <select
                          className="form-control admininput"
                          value={selectedgenre}
                          onChange={(t) => {
                            setselectedgenre(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select genre
                          </option>
                          {genredata?.length > 0
                            ? genredata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Format</label>
                        <select
                          className="form-control admininput"
                          value={selectedformate}
                          onChange={(t) => {
                            setselectedformate(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select Format
                          </option>
                          {formatesdata?.length > 0
                            ? formatesdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              ) : type == "Music" ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Format</label>
                        <select
                          className="form-control admininput"
                          value={selectedformate}
                          onChange={(t) => {
                            setselectedformate(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select Format
                          </option>
                          {formatesdata?.length > 0
                            ? formatesdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Category</label>
                        <select
                          className="form-control admininput"
                          value={maincategoryselectRef?.current}
                          onChange={(t) => {
                            setmaincategoryselect(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select category
                          </option>
                          {musiccatdata?.length > 0
                            ? musiccatdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Edition</label>
                        <input
                          value={musicedition}
                          onChange={(t) => {
                            setmusicedition(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Edition"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Sub Category</label>
                        <select
                          className="form-control admininput"
                          value={selectmainsubcategory}
                          onChange={(t) => {
                            setselectmainsubcategory(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select sub category
                          </option>
                          {subcategorydata?.length > 0
                            ? subcategorydata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Music Composer</label>
                        <input
                          value={musiccomposer}
                          onChange={(t) => {
                            setmusiccomposer(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Format"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Genre</label>
                        <select
                          className="form-control admininput"
                          value={selectedgenre}
                          onChange={(t) => {
                            setselectedgenre(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select genre
                          </option>
                          {genredata?.length > 0
                            ? genredata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="">
                      <label className="admin-label">Track listing</label>
                      <Editor
                        name="name"
                        value={musictracklisting}
                        onChange={(data) => {
                          setmusictracklisting(data);
                        }}
                        editorLoaded={editorLoaded}
                      />
                    </div>
                  </div>
                </>
              ) : type == "Health Care" ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Model Name</label>
                        <input
                          value={healthmodalname}
                          onChange={(t) => {
                            sethealthmodalname(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Model Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Prescription</label>
                        <input
                          value={healthprescription}
                          onChange={(t) => {
                            sethealthprescription(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Prescription"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Quantity</label>
                        <input
                          value={healthquantity}
                          onChange={(t) => {
                            sethealthquantity(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Quantity"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Additives</label>
                        <input
                          value={healthadditives}
                          onChange={(t) => {
                            sethealthadditives(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Additives"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">
                          Quantity - Measuring Unit
                        </label>
                        <input
                          value={healthmeasuringunit}
                          onChange={(t) => {
                            sethealthmeasuringunit(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Quantity - Measuring Unit"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Nutrient Content</label>
                        <input
                          value={healthnutrient}
                          onChange={(t) => {
                            sethealthnutrient(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Nutrient Content"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Type</label>
                        <input
                          value={healthtype}
                          onChange={(t) => {
                            sethealthtype(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Type"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Usage Timings</label>
                        <input
                          value={healthusagetimmings}
                          onChange={(t) => {
                            sethealthusagetimmings(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Usage Timings"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Form</label>
                        <input
                          value={healthform}
                          onChange={(t) => {
                            sethealthform(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Form"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Key Features</label>
                        <input
                          value={healthkeyfeatures}
                          onChange={(t) => {
                            sethealthkeyfeatures(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Key Features"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Composition</label>
                        <input
                          value={healthcomposition}
                          onChange={(t) => {
                            sethealthcomposition(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Composition"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">
                          Usage Instructions
                        </label>
                        <input
                          value={healthintructions}
                          onChange={(t) => {
                            sethealthintructions(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Usage Instructions"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Pack Of</label>
                        <input
                          value={healthpackof}
                          onChange={(t) => {
                            sethealthpackof(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Pack Of"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Category</label>
                        <select
                          className="form-control admininput"
                          value={maincategoryselectRef?.current}
                          onChange={(t) => {
                            setmaincategoryselect(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select category
                          </option>
                          {healthcarecatdata?.length > 0
                            ? healthcarecatdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Food Preference</label>
                        <input
                          value={healthfoodpref}
                          onChange={(t) => {
                            sethealthfoodpref(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter Food Preference"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Sub Category</label>
                        <select
                          className="form-control admininput"
                          value={selectmainsubcategory}
                          onChange={(t) => {
                            setselectmainsubcategory(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select sub category
                          </option>
                          {subcategorydata?.length > 0
                            ? subcategorydata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">License Number</label>
                        <input
                          value={healthlicensenumber}
                          onChange={(t) => {
                            sethealthlicensenumber(t.target.value);
                          }}
                          type="text"
                          className="form-control admininput"
                          placeholder="Enter License Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Genre</label>
                        <select
                          className="form-control admininput"
                          value={selectedgenre}
                          onChange={(t) => {
                            setselectedgenre(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select genre
                          </option>
                          {genredata?.length > 0
                            ? genredata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div className="">
                        <label className="admin-label">Format</label>
                        <select
                          className="form-control admininput"
                          value={selectedformate}
                          onChange={(t) => {
                            setselectedformate(t.target.value);
                          }}
                        >
                          <option value="" disabled>
                            select Format
                          </option>
                          {formatesdata?.length > 0
                            ? formatesdata?.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                    <div className="">
                      <label className="admin-label">Format</label>
                      <input
                        value={musiccategory}
                        onChange={(t) => {
                          setmusiccategory(t.target.value);
                        }}
                        type="text"
                        className="form-control admininput"
                        placeholder="Enter Format"
                      />
                    </div>
                  </div> */}
                  </div>
                </>
              ) : null}

              <div
                className="webadmin-modalbtn"
                onClick={() => {
                  edithandle();
                }}
              >
                <p>
                  {showloding ? (
                    <Spinner size={15} className="spinnerCenter" />
                  ) : (
                    "Submit"
                  )}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
}

export default Products