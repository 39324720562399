import {Link} from 'react-router-dom';
import axios from "axios";
import { apiUrl, assetsUrl } from "../config/config";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Overlay from '../components/Overlay';

function BlogDetail() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [blogetail, setblogetail] = useState({});
    const [relatedblogs, setrelatedblogs] = useState([]);
    const [showloding, setshowloding] = useState(false);
    

    const DisplayOnlyText = (data) => {
        const parser = new DOMParser();
        var datas = data;
        const htmlString = data;
        return (
          <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
      };

    const fetchblogdetails = async(slug) => {
        setshowloding(true);
        var data = {
            slug: slug
        };
        const headers = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          let resp = await axios
    .post(apiUrl + "getblogdetails",data, headers)
    .then((res) => {
      // console.log('res >>', res.data)
      if(res?.data?.data?.length > 0) {
        setblogetail(res?.data?.data[0]);
      } else {
        setblogetail({});
      }

      if(res?.data?.allblogs?.length > 0) {
        setrelatedblogs(res?.data?.allblogs);
      } else {
        setrelatedblogs([]);
      }



     })
     .catch((err) => {
      alert(err.message);
    });
        setshowloding(false);
    }

    const handlenavigation = (data) => {
        navigate('/blog/'+data?.slug)
      }

    useEffect(() => {
        fetchblogdetails(slug);
    }, [slug])

    console.log('slug >>', slug);
  return (
    <>
    <Overlay showloader={showloding} />
      <section className="brand-color pd50 relative blogdetailsection">
      <div className="container">
        <div className="row ">
          <div className="col-md-8">
            {Object.keys(blogetail)?.length > 0 ? (
              
                <div className="">
                  <h2 className="uppercase text-black blocktitle">{blogetail?.title}</h2>
                  <img src={assetsUrl + "blogs/" + blogetail?.image} className='blogimage' />
                  <p className="weight600">
                    {
                      DisplayOnlyText(blogetail?.description)
                    }
                  </p>
                </div>
            ) : (
              <div className="container">
                <div className="about-page">
                  <h3 className="uppercase text-black">Not Found</h3>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <div className='blogrightdiv'>
                <h2 className="uppercase text-black relatedbloghead">Related Blogs</h2>
                {
                    relatedblogs?.length > 0 ?
                    relatedblogs?.map((item, index) => {
                        if(item?.slug !== slug) {
                        return (
                        <div className='relatedblogdiv' key={index} onClick={() => {handlenavigation(item)}}>
                            <img src={assetsUrl + "blogs/" + item?.image} className='relatedblogimg' />
                            <h5 className="uppercase text-black relatedblogtitle">{item?.title}</h5>
                         </div>
                        )
                    }
                    })
                    :
                    <h4 className="uppercase text-black mt-3">No Post</h4>
                }
            </div>
           
          </div>
        </div>
        <div className='' style={{position: 'relative'}}>
        <div className="home_btn_div" style={{bottom: -65}}>
              <Link to="contact-us" className="home_btn">
                Contact
              </Link>
            </div>
        </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail