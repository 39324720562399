import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Overlay from '../components/Overlay';

const Contact = () => {
  return (
    <>
      <section className="pt-5 pb-5">
        <div className="container">
          <h2 className="text-center weight600 mt20">Get In Touch With us</h2>

          <div className="row">
            <div className="col-md-6">
              <Form>
                <Form.Group className="mb-3" controlId="FormName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Full Name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="FormEmail">
                  <Form.Label>Email Id</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="Formphone">
                  <Form.Label>Phone No</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your Number" />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="1"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
