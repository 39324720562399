import React, { useEffect, useState, useMemo, useRef } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faEye,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/config";
import moment from "moment";
import Spinner from "react-activity/dist/Spinner";
import Select from "react-select";
import "react-activity/dist/Spinner.css";
import Papa from 'papaparse';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const ProductDeals = () => {
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const dealsdata = [
    { value: "1", label: "Best Deals of the day" },
    { value: "2", label: "Deal of the week" },
  ];

  const [showloding, setshowloding] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [editshowmodal, seteditshowmodal] = useState(false);
  const [selectedid, setselectedid] = useState(0);
  const [categoriesdropdowndata, setcategoriesdropdowndata] = useState([]);

  const today = new Date().toISOString().split("T")[0];

  const [productslistdata, setproductslistdata] = useState([]);
  const [selectedproducts, setselectedproducts] = useState([]);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [discountpercent, setdiscountpercent] = useState("");
  const [alldealsdata, setalldealsdata] = useState(dealsdata);
  const [selecteddeal, setselecteddeal] = useState("");

  const [name, setname] = useState("");
  const [type, settype] = useState("Movie");

  const [showimportinput, setshowimportinput] = useState(false);
  const [selectedfile, setselectedfile] = useState(null);

  const [exceldata, setexceldata] = useState([]);

  const selectedidRef = useRef(0);
  selectedidRef.current = selectedid;

  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

   // Sample CSV data
   const sampleCsvData = 
   [
     ["Name","Product Slug", "Price"],
     ["SWADESH - SFLP33A", "swadesh-667d1cd2d0762", "3199"],
     ["NAAMUMKIN - SFLP44A", "naamumkin-667d213cdc1d1", "3199"]
 ];

 const downloadSampleCsv = () => {
   const csv = Papa.unparse(sampleCsvData);
   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
   const url = URL.createObjectURL(blob);

   const link = document.createElement('a');
   link.href = url;
   link.setAttribute('download', 'productdeals.csv');
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
 };

 const handleimportdata = (event) => {
   const file = event.target.files[0];
   if (file) {
     Papa.parse(file, {
       header: true, // Include headers
       skipEmptyLines: true, // Skip empty lines
       complete: (result) => {
         console.log('result >>', result.data)
         var newarr = [];
         var datarr = result?.data;
         for(var i = 0; i < datarr?.length; i++) {
             var obj = {};
             obj["label"] = datarr[i]['Name'];
             obj["value"] = datarr[i]['Product Slug'];
             obj["price"] = datarr[i]['Price'];
             newarr.push(obj);
         }
         setexceldata(result.data); // Store parsed data in state
         setselectedproducts(newarr);
       },
     });
   }
 }

  const submithandle = async () => {
      setshowloding(true);
      var data = {
        products: selectedproducts,
        startdate: startdate,
        enddate: enddate,
        discountpercent: discountpercent,
        selecteddeal: selecteddeal
      };
      console.log("data >>", data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "addnewproductdeal", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setselectedproducts([]);
            setstartdate("");
            setenddate("");
            setdiscountpercent("");
            setselecteddeal("");
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    
  };

  const edithandle = async () => {
    if (name && type) {
      setshowloding(true);
      var data = {
        id: selectedidRef.current,
        name: name,
        type: type,
      };
      console.log("submithandle >>", data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "editnewfilters", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            seteditshowmodal(false);
            setname("");
            settype("Movie");
            setselectedid(0);
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const clickhandle = () => {
    // console.log('Mission Launched')
  };

  const editlocation = async (id, name, type) => {
    console.log("id >>", id);
    setselectedid(id);
    setname(name);
    settype(type);
    seteditshowmodal(true);
  };

  const deletecourse = async (id) => {
    console.log("id >>", id);
    const result = window.confirm("Are you sure you want to delete this deal");
    if (result) {
      var data = JSON.stringify({
        id: id,
      });
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "deleteproductdeal", data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            fetchallcourses();
          } else {
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      // User clicked Cancel or closed the dialog
      // console.log("User clicked Cancel");
    }
  };

  const defaultColDef = {
    // flex: 1,
    resizable: true,
    sortable: true,
  };

  const fetchallcourses = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "getallproductsdeals", headers)
      .then((res) => {
        if (res.data.status == "success") {
          // console.log('getallcategories >>', res.data);
          var prevdata = res.data.data;
          if (prevdata?.length > 0) {
            var columnsarr = [];
            var hidecolumns = [
              "id",
              "created_at",
              "updated_at",
              "sort",
              "image",
              "category_id",
              "dealTypeId",
              "slug"
            ];
            var hidefilters = [];
            var columns = Object.keys(prevdata[0]);
            // console.log('columns  >>>', columns );
            for (var i = 0; i < columns?.length; i++) {
              if (!hidecolumns.includes(columns[i])) {
                var obj = {};
                obj.field = columns[i];
                if (!hidefilters.includes(columns[i])) {
                  obj.filter = true;
                  obj.filterParams = {
                    filterOptions: ["contains"],
                    maxNumConditions: 1,
                  };
                }
                console.log("obj >>", obj);
                columnsarr.push(obj);
              }
            }

            //Image
            if (columns.includes("image")) {
              var imageobj = {
                field: "Image",
                headerName: "Image",
                cellRenderer: (props) => {
                  // console.log('image props >>', props);
                  return (
                    <Link
                      to={assetsUrl + "sub-categories/" + props?.data?.image}
                      target="_blank"
                      style={{
                        maxWidth: 35,
                        paddingLeft: 10,
                        paddingRight: 10,
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        className="sidebaricons"
                        style={{ color: "blue" }}
                      />
                    </Link>
                  );
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              };
              columnsarr.push(imageobj);
            }

            //created_at
            if (columns.includes("created_at")) {
              var craetddatobj = {
                field: "Date",
                headerName: "Date",
                cellRenderer: (props) => {
                  // console.log('date props >>', props);
                  return (
                    <div>
                      <p className="created_at_value">
                        {moment(props?.data?.created_at).format(
                          "DD MMMM YYYY HH:mm"
                        )}
                      </p>
                    </div>
                  );
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              };
              columnsarr.push(craetddatobj);
            }

            //edit
            // var editobj = {
            //   field: "edit",
            //   headerName: "Edit",
            //   cellRenderer: (props) => {
            //     return (
            //       <div
            //         onClick={() => {
            //           editlocation(
            //             props?.data?.id,
            //             props?.data?.name,
            //             props?.data?.type
            //           );
            //           // console.log('props >>', props?.data);
            //         }}
            //         style={{
            //           maxWidth: 35,
            //           paddingLeft: 10,
            //           paddingRight: 10,
            //           cursor: "pointer",
            //         }}
            //       >
            //         <FontAwesomeIcon
            //           icon={faPenToSquare}
            //           className="sidebaricons"
            //           style={{ color: "blue" }}
            //         />
            //       </div>
            //     );
            //   },
            //   cellRendererParams: {
            //     onClick: clickhandle,
            //   },
            // };
            // columnsarr.push(editobj);


            //delete
            var deleteobj = {
              field: "delete",
              headerName: "Delete",
              cellRenderer: (props) => {
                return (
                  <div
                    onClick={() => {
                      deletecourse(props?.data?.id);
                      // console.log('props >>', props?.data);
                    }}
                    style={{
                      maxWidth: 35,
                      paddingLeft: 10,
                      paddingRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="sidebaricons"
                      style={{ color: "red" }}
                    />
                  </div>
                );
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
            };
            columnsarr.push(deleteobj);
            setColDefs(columnsarr);
            setRowData(prevdata);
          }

          // setcoursesdata(res.data.data);
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  const fetchallproducts = async () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "getallproducts", headers)
      .then((res) => {
        if (res.data.status == "success") {
          console.log("getallproducts >>", res.data);
          if (res?.data?.data?.length > 0) {
            var prevdata = res?.data?.data;
            var newarr = [];
            for (var i = 0; i < prevdata?.length; i++) {
              var obj = {};
              obj["label"] = prevdata[i]?.name +' - '+prevdata[i]?.sellerSkuId;
              obj["value"] = prevdata[i]?.slug;
              obj["price"] = parseInt(prevdata[i]?.price)
              
              newarr.push(obj);
            }
            setproductslistdata(newarr);
          }

          // setcoursesdata(res.data.data);
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      //   fetchdata();
      fetchallcourses();
      fetchallproducts();
    } else {
      navigate("/newadminlogin");
    }
  }, []);
  return (
    <div className="adminmainpagelayout">
      <div className="admin-categorymaindiv">
        <p className="admin-page-title">View All Product Deals</p>
        <div
          className="category-add-cat-btn"
          onClick={() => {
            setshowmodal(true);
          }}
        >
          <p>Add Deal</p>
        </div>
      </div>

      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: 500, marginTop: 15 }}
      >
        <AgGridReact
          // modules={AllModules}
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={"multiple"}
          pagination={true}
          paginationPageSize={10}
          paginationPageSizeSelector={paginationPageSizeSelector}
          // onGridReady={onGridReady}
        />
      </div>

      {/*Add modal */}
      <Modal
        show={showmodal}
        onHide={() => setshowmodal(false)}
        size="md"
        className="custom-width-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 19 }}>Add Deal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="webadmin-modalmainbody">
            <div className="">
            <div className="d-flex" style={{}}>
                    <label className="admin-label">Select Product</label>
                    <div style={{marginLeft: 'auto', display: 'flex', cursor: 'pointer'}} onClick={() => {setshowimportinput(!showimportinput)}} >
                    <FontAwesomeIcon
              icon={faDownload}
              className="exporticon"
              style={{ color: "#17a2b8", marginRight: 8, position: 'relative', bottom: 2 }}
            />
                      <label className="admin-label" style={{color:'#17a2b8', cursor: 'pointer'}}>Bulk Upload</label>
                    </div>
                  </div>
              <Select
                isMulti
                closeMenuOnSelect={true}
                value={selectedproducts}
                onChange={setselectedproducts}
                options={productslistdata}
              />

{
                    showimportinput ?
                    <>
                    <div className="col-md-12 mt-3">
                       <input type="file"
                onChange={handleimportdata}
                  accept=".csv" 
                  className="form-control admininput"
                />
                  </div>
                  <div className="d-flex">
                  <label className="admin-label" style={{color:'#17a2b8', cursor: 'pointer', marginLeft: 'auto'}} onClick={() => {downloadSampleCsv()}}>Sample CSV download</label>
                  </div>
                    
                    </>
                     : null
                  }
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="">
                  <label className="admin-label">Start Date</label>
                  <input
                    value={startdate}
                    onChange={(t) => {
                      setstartdate(t.target.value);
                    }}
                    type="date"
                    min={today}
                    className="form-control admininput"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <label className="admin-label">End Date</label>
                  <input
                    value={enddate}
                    onChange={(t) => {
                      setenddate(t.target.value);
                    }}
                    type="date"
                    min={startdate ? startdate : today}
                    className="form-control admininput"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="">
                  <label className="admin-label">Discount(%)</label>
                  <input
                    value={discountpercent}
                    onChange={(t) => {
                      setdiscountpercent(t.target.value);
                    }}
                    type="number"
                    className="form-control admininput"
                    placeholder="Enter Discount"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <label className="admin-label">Deals Type</label>
                  <select className="form-control admininput" value={selecteddeal} onChange={(t) => {setselecteddeal(t.target.value)}}>
                    <option value={""} disabled>select type</option>
                    {
                      alldealsdata?.length > 0 ?
                      alldealsdata?.map((item, index) => {
                        return (
                          <option value={item.value} key={index}>{item.label}</option>
                        )
                      }) : null
                    }

                  </select>
                </div>
              </div>
            </div>

            <div
              className="webadmin-modalbtn"
              onClick={() => {
                submithandle();
              }}
            >
              <p>
                {showloding ? (
                  <Spinner size={15} className="spinnerCenter" />
                ) : (
                  "Submit"
                )}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*Edit modal */}
      <Modal
        show={editshowmodal}
        onHide={() => seteditshowmodal(false)}
        size="md"
        className="custom-width-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 19 }}>Edit Deal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="webadmin-modalmainbody">
            <div className="">
              <label className="admin-label">Name</label>
              <input
                value={name}
                onChange={(t) => {
                  setname(t.target.value);
                }}
                type="text"
                className="form-control admininput"
                placeholder="Enter Format Name"
              />
            </div>
            <div className="mt-3">
              <label className="admin-label">Type</label>
              <select
                className="form-control admininput"
                value={type}
                onChange={(t) => {
                  settype(t.target.value);
                }}
              >
                <option selected value={"Movie"}>
                  Movie
                </option>
                <option value={"Music"}>Music</option>
                {/* <option value={"Health Care"}>Health Care</option> */}
              </select>
            </div>

            <div
              className="webadmin-modalbtn"
              onClick={() => {
                edithandle();
              }}
            >
              <p>
                {showloding ? (
                  <Spinner size={15} className="spinnerCenter" />
                ) : (
                  "Submit"
                )}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductDeals;
