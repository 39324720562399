import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Pushpa from "../images/vinyl_record.jpg";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { apiUrl, assetsUrl } from '../config/config';
import { useParams, useNavigate } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper/modules";

function Cards({data}) {

  const navigate = useNavigate();

  const handlenavigation = (data) => {
    console.log('handlenavigation data >>', data)
    // navigate('/productdetails', {state: {data: data}});
    navigate('/entertainment/'+data?.slug)
  }

  const DisplayOnlyText = (data) => {
    const parser = new DOMParser();
    var datas = data;
    const htmlString = data;
    return (
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    );
  };


  return (
    <>
    {
      data?.length > 0 ?
      <section className="bgclor blogssection">
    <Container>
    <h3 className="uppercase text-white text-center mb-30">Blog</h3>
    <div className='heading-border'></div>
    <Swiper
                slidesPerView={3}
                spaceBetween={20}
                freeMode={true}
                loop={true}
                navigation={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[FreeMode, Autoplay, Navigation]}
                className="mySwiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1, // For mobile screens
                  },
                  768: {
                    slidesPerView: 2, // For tablets
                  },
                  1024: {
                    slidesPerView: 3, // For larger screens
                  },
                }}
              >
                {
                  data?.map((item, index) => {
                    return (
                      <SwiperSlide
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // handlenavigation(item);
                        }}
                      >
                        <Card style={{ width: "100%" }} className="card1">
                          <Card.Img
                            variant="top"
                            src={assetsUrl + "blogs/" + item?.image}
                            className="blogimagestyle"
                          />
                          <Card.Body>
                            <h3>
                              {item?.title}
                            </h3>
                            <Card.Text>
                              {
                              // DisplayOnlyText(item?.sdescription)
                              item?.sdescription
                              }
                            </Card.Text>
                            <br />
                            <Link className="blog-cta" to={"/blog/"+item?.slug}>
                              READ MORE
                            </Link>
                          </Card.Body>
                        </Card>
                      </SwiperSlide>
                    );
                  })
                }
              </Swiper>
    </Container>
    </section> : null
    }
    
    </>
  );
}

export default Cards;