import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    userdata : null,
    customerdata: null,
    cartdata: []
  }

  export const userReducer = createReducer(initialState, (builder) => {
    builder.addMatcher((action) => {return action.type === "userdata"}, (state, action) => {
        state.userdata = action.payload;
    })
    builder.addMatcher((action) => {return action.type === "customerdata"}, (state, action) => {
      state.customerdata = action.payload;
  })
  builder.addMatcher((action) => {return action.type === "cartdata"}, (state, action) => {
    state.cartdata = action.payload;
})
  })