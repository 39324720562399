
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { assetsUrl, apiUrl } from "../config/config";
import axios from "axios";
import { useState, useEffect } from "react";
import Overlay from '../components/Overlay';

const OrderHistory = () => {
  const customerdata = useSelector((store) => store.userReducer.customerdata);
  const navigate = useNavigate();

  const [allorders, setallorders] = useState([]);
  const [recentorders, setrecentorders] = useState([]);
  const [unshippedorders, setunshippedorders] = useState([]);

  const fetchordershistory = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customerdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "customerorderhistory", headers)
      .then((res) => {
        if (res.data.status == "success") {
          if(res?.data?.allorders?.length > 0) {
            setallorders(res?.data?.allorders);
          } else {
            setallorders([]);
          }

          if(res?.data?.recentorders?.length > 0) {
            setrecentorders(res?.data?.recentorders);
          } else {
            setrecentorders([]);
          }

          if(res?.data?.upshippedorders?.length > 0) {
            setunshippedorders(res?.data?.upshippedorders);
          } else {
            setunshippedorders([]);
          }
          
      } else {

      }
  })
      .catch((err) => {
        alert(err.message);
      });
  }

  const FormatDate = (isoDate) => {
    // Convert the ISO date string to a Date object
    const date = new Date(isoDate);
  
    // Format the date to '24 October 2024'
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  
    return formattedDate;
  };

  useEffect(() => {
    if (customerdata && Object.keys(customerdata).length > 0) { 
    fetchordershistory();
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <div className="container mt-4">
        <h2 className="mb-3">Your Orders</h2>
        <div className="orderhistorytab">
          <Tabs>
            <TabList className={"mytab dflex"}>
              <Tab className={"mytablist"}>All Orders</Tab>
              <Tab className={"mytablist"}>Recent Orders</Tab>
              <Tab className={"mytablist"}>Not Yet Shipped</Tab>
              {/* <Tab className={"mytablist"}>Cancelled Orders</Tab> */}
            </TabList>

            <TabPanel>
              {
                allorders?.length > 0 ?
                allorders?.map((item, index) => {
                  // console.log('item >>', item);
                  return (
                    <div className="wrapborder mb-3" key={index}>
                <div className="headerdetails">
                  <div className="orderdetailsname dflex space-between">
                    <div className="dflex">
                      <p className="padding-right-40 fs16">
                        ORDER PLACED
                        <br /> {FormatDate(item?.created_at)}
                      </p>
                      <p className="padding-right-40 fs16">
                        TOTAL <br />{parseFloat(parseInt(item?.price)).toFixed(2)}
                      </p>
                      <p className="fs16" data-tooltip-id="my-tooltip-2">
                        SHIP TO <br />
                        {item?.shippingname}
                      </p>
                      <ReactTooltip
                        style={{
                          backgroundColor: "black",
                          color: "White",
                          width: "150px",
                        }}
                        id="my-tooltip-2"
                        place="bottom"
                        variant=""
                        content={
                          item?.shippingname + ", " +
                          item?.shipping_address + ", " +
                          item?.shipping_city + ", " + 
                          item?.shipping_state + ", " +
                          item?.shipping_pincode + ", " 
                        }
                      />
                    </div>
                    <div className="">
                      <p className="fs16">ORDER # {item?.order_id}</p>
                      <div className="text-right d-flex">
                        <p className="fs16">Invoice</p> :
                        <Link to={'/invoice/'+item?.order_id} target="_blank" style={{marginLeft: 10}}>{item?.invoice}</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="productcartdetails1">
                  <div className="card-body dflex">
                    <div className="viewimg">
                      <img
                        src={assetsUrl+"products/"+item?.image}
                        className="img-fluid w-100"
                      />
                    </div>

                    <div className="dflex space-between w100">
                      <div className="cartcontent">
                        <h5>{item?.name}</h5>
                        <p className="small uppercase">PRICE - {item.single_product_price}</p>
                        <p className="small uppercase">QUANTITY - {item.qty}</p>
                        <p className="small uppercase">SUB TOTAL - {item.price}</p>
                        <p className="small uppercase">GST - {item.gst_rate}</p>
                        <p className="small uppercase">SHIPPING - 0</p>
                      </div>
                      <div className="text-right">
                        <p className="">Total ₹ {parseFloat(parseInt(item?.price)).toFixed(2)} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                  )
                }) : null
              }
              
            </TabPanel>

           <TabPanel>
              {
                recentorders?.length > 0 ?
                recentorders?.map((item, index) => {
                  return (
                    <div className="wrapborder mb-3">
                <div className="headerdetails">
                  <div className="orderdetailsname dflex space-between">
                    <div className="dflex">
                      <p className="padding-right-40 fs16">
                        ORDER PLACED
                        <br /> {FormatDate(item?.created_at)}
                      </p>
                      <p className="padding-right-40 fs16">
                        TOTAL <br />{parseFloat(parseInt(item?.price)).toFixed(2)}
                      </p>
                      <p className="fs16" data-tooltip-id="my-tooltip-2">
                        SHIP TO <br />
                        {item?.shippingname}
                      </p>
                      <ReactTooltip
                        style={{
                          backgroundColor: "black",
                          color: "White",
                          width: "150px",
                        }}
                        id="my-tooltip-2"
                        place="bottom"
                        variant=""
                        content={
                          item?.shippingname + ", " +
                          item?.shipping_address + ", " +
                          item?.shipping_city + ", " + 
                          item?.shipping_state + ", " +
                          item?.shipping_pincode + ", " 
                        }
                      />
                    </div>
                    <div className="">
                      <p className="fs16">ORDER # {item?.order_id}</p>
                      <div className="text-right d-flex">
                        <p className="fs16">Invoice</p> :
                        <Link to={'/invoice/'+item?.order_id} target="_blank" style={{marginLeft: 10}}>{item?.invoice}</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="productcartdetails1">
                  <div className="card-body dflex">
                    <div className="viewimg">
                      <img
                        src={assetsUrl+"products/"+item?.image}
                        className="img-fluid w-100"
                      />
                    </div>

                    <div className="dflex space-between w100">
                      <div className="cartcontent">
                        <h5>LIQUOR IN THE FRONT - COLOURED</h5>
                        <p className="small uppercase">PRICE - {item.single_product_price}</p>
                        <p className="small uppercase">QUANTITY - {item.qty}</p>
                        <p className="small uppercase">SUB TOTAL - {item.price}</p>
                        <p className="small uppercase">GST - {item.gst_rate}</p>
                        <p className="small uppercase">SHIPPING - 0</p>
                      </div>
                      <div className="text-right">
                        <p className="">Total ₹ {parseFloat(parseInt(item?.price)).toFixed(2)} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                  )
                }) : null
              }
              
            </TabPanel>

            <TabPanel>
              {
                unshippedorders?.length > 0 ?
                unshippedorders?.map((item, index) => {
                  return (
                    <div className="wrapborder mb-3">
                <div className="headerdetails">
                  <div className="orderdetailsname dflex space-between">
                    <div className="dflex">
                      <p className="padding-right-40 fs16">
                        ORDER PLACED
                        <br /> {FormatDate(item?.created_at)}
                      </p>
                      <p className="padding-right-40 fs16">
                        TOTAL <br />{parseFloat(parseInt(item?.price)).toFixed(2)}
                      </p>
                      <p className="fs16" data-tooltip-id="my-tooltip-2">
                        SHIP TO <br />
                        {item?.shippingname}
                      </p>
                      <ReactTooltip
                        style={{
                          backgroundColor: "black",
                          color: "White",
                          width: "150px",
                        }}
                        id="my-tooltip-2"
                        place="bottom"
                        variant=""
                        content={
                          item?.shippingname + ", " +
                          item?.shipping_address + ", " +
                          item?.shipping_city + ", " + 
                          item?.shipping_state + ", " +
                          item?.shipping_pincode + ", " 
                        }
                      />
                    </div>
                    <div className="">
                      <p className="fs16">ORDER # {item?.order_id}</p>
                      <div className="text-right d-flex">
                        <p className="fs16">Invoice</p> :
                        <Link to={'/invoice/'+item?.order_id} target="_blank" style={{marginLeft: 10}}>{item?.invoice}</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="productcartdetails1">
                  <div className="card-body dflex">
                    <div className="viewimg">
                      <img
                        src={assetsUrl+"products/"+item?.image}
                        className="img-fluid w-100"
                      />
                    </div>

                    <div className="dflex space-between w100">
                      <div className="cartcontent">
                        <h5>LIQUOR IN THE FRONT - COLOURED</h5>
                        <p className="small uppercase">PRICE - {item.single_product_price}</p>
                        <p className="small uppercase">QUANTITY - {item.qty}</p>
                        <p className="small uppercase">SUB TOTAL - {item.price}</p>
                        <p className="small uppercase">GST - {item.gst_rate}</p>
                        <p className="small uppercase">SHIPPING - 0</p>
                      </div>
                      <div className="text-right">
                        <p className="">Total ₹ {parseFloat(parseInt(item?.price)).toFixed(2)} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                  )
                }) : null
              }
              
            </TabPanel>

            {/* <TabPanel>
              <h2>Any content 4</h2>
            </TabPanel> */}
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default OrderHistory